export const BACKGROUND_THEME_IMAGE_MULTIPLE_PREFIX_ID = 'background_theme_imgs_multiple_';
export const OUTLINE_PREFIX_ID = 'outline_';
export const IMAGE_PREFIX_ID = 'image_';
export const TEXT_PREFIX_ID = 'text_';
export const MULTI_LINE_PREFIX_ID = 'multi_line_';
export const STATIC_TEXT_PREFIX_ID = 'static_txt_';
export const SHAPE_PREFIX_ID = 'shape_';
export const CIRCLE_PREFIX_ID = 'circle_';
export const RECTANGLE_PREFIX_ID = 'rectangle_';
export const STATIC_IMAGE_PREFIX_ID = 'st_img_';
export const ICON_PREFIX_ID = 'icon_';
export const BACKGROUND_THEME_IMAGE_PREFIX_ID = 'bk_theme_img_';
export const MASK_PREFIX_ID = 'mask';
export const UPLOAD_BACKGROUND_IMAGE_PREFIX_ID = 'upload_bk_img_';
export const IMAGE_FROM_BK_PREFIX_ID = 'img_from_bk_';
export const UPLOAD_IMAGE_PREFIX_ID = 'upload_img';
export const SAFE_ZONE_PREFIX_ID = 'safe_zone_';
export const ADDITIONAL_BACKGROUND_THEME_IMAGE_PREFIX_ID = 'add_bk_theme_img_';
export const ADDITIONAL_MULTI_LINE_TEXT_FIELD_PREFIX_ELEMENT_ID = 'add_multi_line_text_';
export const ADDITIONAL_MULTI_LINE_TEXT_FIELD_PREFIX_ID = 'additional_multi_line_text';
export const ADDITIONAL_TEXT_PREFIX_ELEMENT_ID = 'add_text_';
export const ADDITIONAL_TEXT_PREFIX_ID = 'additional_text';
export const ADDITIONAL_RECTANGLE_PREFIX_ELEMENT_ID = 'add_rectangle_';
export const ADDITIONAL_RECTANGLE_PREFIX_ID = 'additional_rectangle_';
export const ADDITIONAL_CIRCLE_PREFIX_ELEMENT_ID = 'add_circle_';
export const ADDITIONAL_CIRCLE_PREFIX_ID = 'additional_circle_';
export const ADDITIONAL_SAFE_ZONE_PREFIX_ELEMENT_ID = 'add_safe_zone_';
export const ADDITIONAL_SAFE_ZONE_PREFIX_ID = 'additional_safe_zone_';
export const KEY_UPLOAD_IMG = 'upload_img';
export const KEY_IMAGE = 'image';
export const KEY_IMG_FROM_BK = 'img_from_bk';
export const KEY_THEME_IMG = 'bk_theme_img';
export const KEY_GROUP = 'group_';
export const KEY_HTML = 'html_';
