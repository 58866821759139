import { render, staticRenderFns } from "./ExportAddPagesConfig.vue?vue&type=template&id=1bfc4083"
import script from "./ExportAddPagesConfig.vue?vue&type=script&lang=js"
export * from "./ExportAddPagesConfig.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/appdata/www/radzs/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1bfc4083')) {
      api.createRecord('1bfc4083', component.options)
    } else {
      api.reload('1bfc4083', component.options)
    }
    module.hot.accept("./ExportAddPagesConfig.vue?vue&type=template&id=1bfc4083", function () {
      api.rerender('1bfc4083', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/frontend/components/modules/layouts-page/ExportAddPagesConfig.vue"
export default component.exports