export default {
  create(payload) {
    return axios.post('/catalogs', payload)
  },
  list(page = 1) {
    return axios.get(`/catalogs?page=${page}`)
  },
  update(id, payload) {
    return axios.put(`/catalogs/${id}`, payload)
  },
  delete(id) {
    return axios.delete(`/catalogs/${id}`);
  },
  getLayouts(id, page = 1, sort = 'id_asc') {
    return axios.get(`/catalogs/${id}/layouts?page=${page}&sort=${sort}`)
  },
  unpinLayoutFromCatalog(catalogID, layoutID) {
    return axios.delete(`/catalogs/${catalogID}/layouts/${layoutID}`);
  }
}
