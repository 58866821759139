<template>
  <div class="c-top-bar">
    <div class="c-top-bar__search_container d-flex  flex-grow-1 align-items-center">
      <div class="c-top-bar__search">
        <svg
          width="13"
          height="13"
          class="c-top-bar__search--icon"
        >
          <use href="/img/icons/sprite.svg#search-big" />
        </svg>
        <input
          v-model="searchInput"
          type="text"
          placeholder="Search"
          class="c-top-bar__search--input"
          @keyup="debouncedSearchHandler"
        >
      </div>
    </div>
    <ToolBar />
  </div>
</template>

<script>
import {createNamespacedHelpers, mapGetters} from 'vuex';
import {MUTATIONS} from "../../../store/file-browser-product-selector";
import {debounce} from "lodash";
import ToolBar from "@frontend/components/modules/file-browser-product-selector/browser/toolbars/Toolbar.vue";
import {MODES} from "@frontend/components/modules/file-browser-product-selector/constants";

const { mapActions, mapMutations } = createNamespacedHelpers('fileBrowserProductSelector')

export default {
  name: "TopBar",
  components: {ToolBar},
  data: () => ({
    searchInput: '',
  }),
  computed: {
    ...mapGetters('fileBrowserProductSelector', [
      'isAnySelected',
      'isAnyPicked',
      'items',
      'selected',
      'isAnySelected',
      'selectedItems',
      'currentPage',
      'searchQuery',
      'currentFolderId',
      'currentFolderName',
      'isFolderViewEnabled',
      'isTopBarSwitchersEnabled',
      'mode',
    ]),
    ...mapGetters('layout', [
      'selectedInstance',
    ]),
  },
  methods: {
    ...mapActions([
      'fetchData',
      'selectAll',
      'setCurrentPage',
      'setSearchQuery',
      'enableBrowserMode',
      'goBackToView',
    ]),
    ...mapMutations([MUTATIONS.CLEAR_SELECTION]),
    searchHandler(event) {
      this.setSearchQuery(event.target.value);
      this.fetchData();
    },
    debouncedSearchHandler: debounce(function (event) {
      if (this.searchInput === '') {
        this.goBackToView()
      }
      else if (this.mode !== MODES.REPLACE) {
        this.enableBrowserMode();
      }
      this.searchHandler(event);
    }, 500)
  }
}
</script>

<style scoped lang="scss">
@media (max-width: 1265px) {
    .c-top-bar {
        flex-direction: column;

        &__search_container {
            width: 100%;
        }
    }
}

.c-top-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  padding: 0 50px 0 50px;
  margin-bottom: 20px;

  ::v-deep &__reset-button {
    padding: 0;
    border: unset !important;
    background-color: unset;

    &:hover, &:active {
      background-color: unset !important;
      color: #727272 !important;
    }

    &:focus, &:active:focus {
      box-shadow: unset !important;
    }
  }

  ::v-deep &__settings-button {
    width: 26px;
    height: 22px;
    color: #727272;

    .cil-arrow-thick-from-bottom {
      font-size: 22px;
    }

    .cil-input, .cil-cut {
      font-size: 23px;
    }
  }

  &__search {
    position: relative;
    width: 100%;

    &--icon {
      position: absolute;
      right: 15px;
      top: 11px;
      stroke: #1c7964;
    }

    &--input {
      font-family: "Nunito", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #0c3a2f;
      width: 100%;
      height: 35px;
      background: white;
      border: 1px solid #1c7964;
      border-radius: 12px;
      padding: 0 35px 0 8px;
      outline: 0;
      
      &::placeholder {
        color: #0c3a2f;
        font-weight: 500;
      }
    }

    &--button {
      height: 30px;
      width: 28px;
    }
  }

  &__clear--button {
    background: transparent;
    color: #727272;
    font-weight: 700;
    font-size: 15px;

    &:hover {
      opacity: 0.75;
    }
  }

  &__settings-container {
    width: 50px;
    height: 40px;
  }

  &__settings {
    color: #007964 !important;
    background-color: transparent !important;
    border-radius: 8px;
    margin: 0 5px;
    border: 1px solid #007964 !important;
    padding: 0.375rem 0.75rem;

    &:active, &:hover {
      color: #007964 !important;
    }

    i {
      width: unset;
    }
  }
}
</style>
