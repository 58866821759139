import { EditTemplateContext } from "@frontend/contexts/edit-template-context";
import { psdLayersEventBroker, EVENTS as PSD_LAYERS_EVENT } from '../psd-layers/event-broker';
import { importPsdEventBroker, EVENTS as IMPORT_PSD_EVENTS } from './event-broker';
import TemplateService from '@frontend/services/api/template';
import { resizeWithAspectRatio } from '@frontend/services/helpers';
import { MOCK_INSTANCE_ID, getUpdatedDataLayoutTemplatesGrid } from '../get-updated-data-layout-templates-grid';
import {multipleTemplateHandler} from "../psd-layers/helpers";
import LAYOUT_API from '@frontend/services/api/layout';
import Route from '@frontend/services/helpers/url';
import templateApi from '@frontend/services/api/template';
import vuexStore from '@frontend/store';
import { MUTATIONS } from '@frontend/store/psd-layers';
import FileBrowserApi from '@frontend/services/api/file-browser';
import Router from '@frontend/router';
import { NAV_FILES_TAB_ATTRIBUTES } from '@frontend/constants/nav-files-tab';
import {FileFormURL} from "@/includes/file-form-url";
import {initPsdPreviewer} from "@/includes/psd-preview";

export const IMPORT_AND_CREATE_STRATEGY = 'import-and-create';
export const IMPORT_INTO_EXISTS_TEMPLATE_STRATEGY = 'import-into-exists-template';
export const ACTION_IMPORT_FROM_PSD = 'import-from-psd';
export const ACTION_IMPORT_FROM_PRESELECTED_PSD = 'import-from-preselected-psd';

const LIMIT_IMPORT_FROM_PDS_WIDTH = 5000;
const LIMIT_IMPORT_FROM_PDS_HEIGHT = 5000;
const MOCK_CONTENT_FOR_GRID = () => $('<div></div>');

export const importPreselectedPsd = async () => {
    const importFromPsdDataJSON = localStorage.getItem(ACTION_IMPORT_FROM_PRESELECTED_PSD);

    if (!importFromPsdDataJSON) {
        console.error('No PSD data found!');
        return;
    }

    const importFromPsdData = JSON.parse(importFromPsdDataJSON);

    if (!importFromPsdData.files) {
        console.error('No files found!');
        return;
    }

    const files = importFromPsdData.files.map(psdFile => new FileFormURL(psdFile.url, psdFile.name, psdFile.id));
    const templatesIds = importFromPsdData.templatesIds;

    const postHandler = () => {
        localStorage.removeItem(ACTION_IMPORT_FROM_PRESELECTED_PSD);
        Route.deleteParam(ACTION_IMPORT_FROM_PRESELECTED_PSD);
    }

    if (templatesIds) {
        vuexStore.commit(`psdLayers/${MUTATIONS.SET_IS_IMPORT_INTO_TEMPLATE}`, true);
        const { data: templates } = await templateApi.getTemplatesByIds(templatesIds);
        initImportIntoExistsTemplateStrategy({ templates, postHandler });
    } else {
        initImportAndCreateStrategy(postHandler);
    }

    initPsdPreviewer(files, undefined);
}

export const getLayoutInfo = () => {
    const grid = $("#template-group-preview");
    const customerId = grid.attr("data-customer-id");
    const id = grid.attr("data-layout-id");
    const name = grid.attr("data-layout-name");
    const perPage = $('input[name="per_page"]').val()

    return { id, customerId, name, perPage };
}

function getDataGrid(newTemplates) {
    const previews = window.previews || [];
    const oldTemplates = [];

    previews.forEach(({ template, settings: { instance_id, x, y, w, h } }) => {
        oldTemplates.push({
            id: template.id,
            instanceId: instance_id,
            name: template.name,
            width: template.width,
            height: template.height,
            content: MOCK_CONTENT_FOR_GRID(),
            gridData: { x, y, w, h }
        });
    });

    return getUpdatedDataLayoutTemplatesGrid(oldTemplates, newTemplates);
}

export function getGridDataForImportAndCreateStrategy(gridData) {
    const { oldTemplates, newTemplates } = gridData;

    return {
        oldTemplates: oldTemplates.map(({ instance_id, w, h, y, x }) => ({ instance_id, w, h, y, x })),
        newTemplate: (({ w, h, y, x }) => ({ w, h, y, x }))(newTemplates[0]),
    }
}

function initImportAndCreateStrategy(postHandler) {
    const { id, customerId } = getLayoutInfo();

    psdLayersEventBroker.on(
        PSD_LAYERS_EVENT.FILL_TEMPLATE,
        multipleTemplateHandler(async (state) => {
            const width = state.template.width;
            const height = state.template.height;
            let transformSize;

            if (LIMIT_IMPORT_FROM_PDS_WIDTH < width || LIMIT_IMPORT_FROM_PDS_HEIGHT < height) {
                transformSize = resizeWithAspectRatio(width, height, LIMIT_IMPORT_FROM_PDS_WIDTH, LIMIT_IMPORT_FROM_PDS_HEIGHT);

                window.toastr.info(`PSD will be imported at ${transformSize.width} x ${transformSize.height}`);
            }

            const gridData = getDataGrid([{
                width: transformSize?.width || width,
                height: transformSize?.height || height
            }]);

            const name = `${state.originalFileName.split('/').pop()} ${state.template.name || ''}`.trim()

            await TemplateService.createTemplate(customerId, id, {
                width,
                height,
                name,
                layers: state.layers,
                isTreatMultipleSpaces: state.isTreatMultipleSpaces,
                gridData: getGridDataForImportAndCreateStrategy(gridData),
                isReplace: state.isReplace,
                isUseUniqueProductImages: state.isUseProductImagesFromPSD,
            });

        }, () => {
            if (postHandler) {
                postHandler();
            }

            psdLayersEventBroker.fire(PSD_LAYERS_EVENT.CLOSE_AND_RELOAD);
        }),
        true
    );
}
function cloneObjectsInArray(array, n) {
    return array.flatMap(obj => Array(n).fill().map(() => ({ ...obj })));
}

const groupByTemplateId = (templates) => {
    return Object.values(
      templates.reduce((acc, obj) => {
          const { template_id } = obj;
          if (!acc[template_id]) {
              acc[template_id] = [];
          }
          acc[template_id].push(obj);
          return acc;
      }, {})
    );
}

export const createFillTemplateHandlerForImportIntoExistingTemplate = (params, before, after) => async (state) => {
    const files = Array.isArray(state) ? state : [state];

    let _params = params

    if (typeof before === 'function') {
        const beforeParams = await before()
        if (beforeParams) {
            _params = {
                ..._params,
                ...beforeParams
            }
        }
    }

    const { customerId, name, id, perPage, templates } = _params
    const transformedTemplates = files.length === 1 ? templates : cloneObjectsInArray(templates, files.length);
    const { oldTemplates, newTemplates } = getDataGrid(transformedTemplates);
    const settings =  JSON.stringify([...oldTemplates, ...newTemplates]);

    const importFromPsdData = [];

    const groupedTemplatesById = groupByTemplateId(newTemplates)

    await LAYOUT_API.updateLayout(customerId, id, { name, settings });

    for (const groupedTemplates of groupedTemplatesById) {
        for (const [index, groupedTemplate] of groupedTemplates.entries()) {
            const fileState = files[index];

            const data = { instanceId: groupedTemplate.instance_id, state: fileState };

            const template = templates.find(_template => _template.id === groupedTemplate.template_id);
            const layers = fileState.layers.map(layer => {
                const mappedField = layer.field;

                if (!mappedField) return layer;

                const field = template.fields.find(field => field.name === mappedField.name);

                return { ...layer, field };
            });

            importFromPsdData.push({
                ...data,
                state: { ...fileState, layers },
            })
        }
    }

    localStorage.setItem(ACTION_IMPORT_FROM_PSD, JSON.stringify(importFromPsdData));

    if (typeof after === 'function') {
        await after()
    }
}

function initImportIntoExistsTemplateStrategy({ templates, postHandler }) {
    const firstTemplate = templates[0];
    window.template_data = firstTemplate;
    window.$context.get(EditTemplateContext).setFieldsToFill(firstTemplate.fields);
    const { id, customerId, name, perPage } = getLayoutInfo();

    psdLayersEventBroker.on(
      PSD_LAYERS_EVENT.FILL_TEMPLATE,
      createFillTemplateHandlerForImportIntoExistingTemplate(
        {customerId, name, id, perPage, templates},
        undefined,
        () => {
            if (postHandler) {
                postHandler();
            }

            const page = Math.floor(window.previews.length / +perPage);

            if (page) {
                Route.addParam('page', page + 1)
            }

            Route
              .addParam('action', ACTION_IMPORT_FROM_PSD)
              .reload();
        }
      ),
      true
    )
}

function openSelectImageModal() {
    const preview = window.previews[0];
    const $customerInput = $('input[name="customer"]');
    const $customerIdInput = $('input[name="customer_id"]');
    const $templateIdInput = $("input[name='template_id']");
    const $themeSelect = $("select[name='theme']");

    $templateIdInput.val(preview?.template?.id);
    $customerIdInput.val(preview?.template?.customer?.id);
    $customerInput.val(preview?.template?.customer?.value);
    $themeSelect.empty().append(
      `<option value="${preview?.data?.theme}"></option>`
    );
    $themeSelect.val(preview?.data?.theme);
    
    importPsdEventBroker.fire(IMPORT_PSD_EVENTS.SELECT_WAY_IMPORT_FROM_PSD.INIT);

    importPsdEventBroker.on(IMPORT_PSD_EVENTS.SELECT_WAY_IMPORT_FROM_PSD.SAVE, ({ selectedSratagy, selectedTemplates }) => {
        if (selectedSratagy === IMPORT_INTO_EXISTS_TEMPLATE_STRATEGY) {
            initImportIntoExistsTemplateStrategy(selectedTemplates);
        } else {
            initImportAndCreateStrategy();
        }
    
        setTimeout(() => {
            window.selectedBackground = MOCK_CONTENT_FOR_GRID();
            window.openSelectImageModalHandler({ isMultipleTemplateSelectionType: true });
        }, 250);

    }, true);
}

const getQueryParams = () => {
    let params = {};
    window.location.search.substring(1).split("&").forEach(function(param) {
        let pair = param.split("=");
        params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    });

    return params;
}

$(document).ready(function () {
    $('.import-from-psd').on('click', openSelectImageModal);
});
