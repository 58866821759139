<template>
  <div>
    <b-form-group
      label="Fill"
      label-cols="4"
    >
      <ColorPicker
        :value="target.fill"
        :themes="themes"
        copy
        @input="onChange('bgColor', $event)"
      />
    </b-form-group>
    
    
    
    <b-form-group
      label="Border"
      label-cols="4"
    >
      <div class="d-flex">
        <ColorPicker
          :value="target.stroke"
          :themes="themes"
          copy
          @input="onChange('strokeColor', $event)"
        />
        <b-button
          v-b-tooltip.hover
          variant="primary"
          size="sm"
          title="Use background color"
          @click="onChange('strokeColor', target.fill)"
        >
          <b-icon icon="back" />
        </b-button>
      </div>
    </b-form-group>
    
    
    
    <b-form-group
      label="Border width"
      :disabled="target.locked"
    >
      <b-input-group>
        <b-input
          :value="target.strokeWidth"
          :min="0"
          :max="maxStrokeWidth"
          type="range"
          @input="onChange('thickness', $event)"
        />
        <template #append>
          <b-input
            :value="target.strokeWidth"
            :min="0"
            :max="maxStrokeWidth"
            type="number"
            @input="onChange('thickness', $event)"
          />
        </template>
      </b-input-group>
    </b-form-group>
    <b-form-group
      v-if="target.fieldType !== FIELD_TYPE_SAFE_ZONE"
      label="Rounding"
    >
      <b-input-group>
        <b-input
          :value="target.rx"
          :min="0"
          :max="maxRounding"
          type="range"
          @input="onChange('radius', $event)"
        />
        <template #append>
          <b-input
            :value="target.rx"
            :min="0"
            :max="maxRounding"
            type="number"
            @input="onChange('radius', $event)"
          />
        </template>
      </b-input-group>
    </b-form-group>
    <b-form-group label="Opacity">
      <b-input-group>
        <b-input
          :value="target.opacity"
          :min="0"
          :max="maxOpacity"
          step="0.01"
          type="range"
          @input="onChange('opacity', $event)"
        />
        <template #append>
          <b-input
            :value="target.opacity"
            :min="0"
            :max="maxOpacity"
            step="0.01"
            type="number"
            @input="onChange('opacity', $event)"
          />
        </template>
      </b-input-group>
    </b-form-group>
    <b-form-group label="Fill opacity">
      <b-input-group>
        <b-input
          v-model="fillOpacity"
          :min="0"
          :max="1"
          step="0.01"
          type="range"
          @input="onChangeFillOpacity"
        />
        <template #append>
          <b-input
            v-model="fillOpacity"
            :min="0"
            :max="1"
            step="0.01"
            type="number"
            @input="onChangeFillOpacity"
          />
        </template>
      </b-input-group>
    </b-form-group>
    <b-form-group label="Border opacity">
      <b-input-group>
        <b-input
          v-model="strokeOpacity"
          :min="0"
          :max="maxOpacity"
          step="0.01"
          type="range"
          @input="onChangeStrokeOpacity"
        />
        <template #append>
          <b-input
            v-model="strokeOpacity"
            :min="0"
            :max="maxOpacity"
            step="0.01"
            type="number"
            @input="onChangeStrokeOpacity"
          />
        </template>
      </b-input-group>
    </b-form-group>
    <template
      v-if="target.fieldType === FIELD_TYPE_SAFE_ZONE"
    >
      <PositionAndSize
        :w="target.width * target.scaleX"
        :h="target.height * target.scaleY"
        :x="target.left"
        :y="target.top"
        @input="onChange"
      />
    </template>
    <b-form-group label="Apply to all">
      <b-form-checkbox-group
        v-model="selectedPropertyKeys"
        :options="applyToAllOptions"
        @change="applyToAll"
      />
    </b-form-group>

    <b-button
      v-if="!isNonDeletable"
      variant="danger"
      @click="onChange('delete')"
    >
      <b-icon icon="trash" />
      Delete
    </b-button>

    <LockToggle />
  </div>
</template>

<script>
import { editorControlEventBroker, EVENTS } from '@frontend/group/modules/editor-control-popup/event-broker'
import ColorPicker from './components/ColorPicker.vue'
import LockToggle from '@frontend/components/modules/layouts-page/EditorControl/components/LockToggle.vue'
import { FIELD_TYPE_SAFE_ZONE } from "@frontend/constants/type-fields-of-template";
import PositionAndSize from "@frontend/components/modules/layouts-page/EditorControl/components/PositionAndSize.vue";
import { SAFE_ZONE_PREFIX_ID } from "@frontend/constants/templates-field-prefixes";

const NON_DELETABLE_SHAPES_PREFIXES = [
    SAFE_ZONE_PREFIX_ID
];

export default {
  name: 'ShapeEditorControl',

  components: {
    PositionAndSize,
    LockToggle,
    ColorPicker,
  },

  props: {
    target: {
      type: Object,
      default: () => {},
    },
    themes: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    FIELD_TYPE_SAFE_ZONE,
    maxRounding: 500,
    maxStrokeWidth: 100,
    maxOpacity: 1,
    selectedPropertyKeys: [],
    applyToAllOptions: [
      { text: 'Background', value: 'bgColor' },
      { text: 'Stroke', value: 'strokeColor' },
    ],
    fillOpacity: 1,
    strokeOpacity: 1
  }),
  computed: {
    isNonDeletable() {
      return NON_DELETABLE_SHAPES_PREFIXES.some(prefix => this.target?.element_id.startsWith(prefix));
    }
  },
  watch: {
    target() {
      this.init();
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const prepareOpacity = (value) => {
        return value == 1.00 ? 1 : value;
      }
      if (this.target?.fill) {
        this.fillOpacity = prepareOpacity(this.hexToOpacity(this.target.fill));
      }
      if (this.target?.stroke) {
        this.strokeOpacity = prepareOpacity(this.hexToOpacity(this.target.stroke));
      }
    },
    onChange: _.debounce(function (action, value) {
      editorControlEventBroker.fire(EVENTS.SHAPE_CHANGED, {
        action,
        value,
        keys: [...this.selectedPropertyKeys],
      })
    }, 15),
    onChangeFillOpacity(value)  {
      this.onChange('bgColor', this.opacityToHex(this.target.fill, value))
    },
    onChangeStrokeOpacity(value){
      this.onChange('strokeColor', this.opacityToHex(this.target.stroke, value))
    },
    applyToAll([action]) {
      this.onChange(action, action === 'bgColor' ? this.target.fill : this.target.stroke);
    },
    hexToOpacity(hexColor) {
      // Check if the string has a valid length
      if (hexColor.length <= 7) { // Format #RRGGBB (no alpha channel)
        return 1; // Fully opaque
      } else if (hexColor.length === 9) { // Format #RRGGBBAA
        const alphaHex = hexColor.slice(-2); // Last two characters represent the alpha channel
        const decimalAlpha = parseInt(alphaHex, 16); // Convert HEX to decimal
        return (decimalAlpha / 255).toFixed(2); // Normalize to 0-1 range and round to 1 decimal place
      } else {
        throw new Error("HEX string must be in the format #RRGGBB or #RRGGBBAA");
      }
    },
    opacityToHex(hexColor, opacity) {
      // Validate opacity is in the range 0 to 1
      if (opacity < 0 || opacity > 1) {
        throw new Error("Opacity must be a decimal value between 0 and 1");
      }

      // Convert opacity to HEX
      const alphaHex = Math.round(opacity * 255).toString(16).padStart(2, '0').toUpperCase();

      // Handle HEX color without alpha channel (#RRGGBB)
      if (hexColor.length < 7) {
        return '#00000000'; // Append the alpha channel
      }
      if (hexColor.length === 7) {
        return hexColor + alphaHex; // Append the alpha channel
      }

      // Handle HEX color with alpha channel (#RRGGBBAA)
      if (hexColor.length === 9) {
        return hexColor.slice(0, 7) + alphaHex; // Replace the alpha channel
      }

      // Invalid HEX format
      throw new Error("HEX string must be in the format #RRGGBB or #RRGGBBAA");
    }
  },
}
</script>

<style scoped lang="scss">

</style>
