<template>
  <div>
    <b-dropdown
      size="sm"
      variant="primary"
      text="Properties"
      class="select-properties-dropdown"
      menu-class="p-0"
    >
      <b-list-group>
        <b-list-group-item
          v-for="(property, index) in getProperties"
          :key="index"
        >
          <b-form-checkbox
            v-model="property.value"
            size="sm"
            @change="onChangeProperties(property)"
          >
            {{ property.text }}
          </b-form-checkbox>
        </b-list-group-item>
      </b-list-group>
    </b-dropdown>
    <b-button
      size="sm"
      class="mr-1"
      variant="primary"
      @click="apply"
    >
      Apply
    </b-button>
  </div>
</template>

<script>
export default {
  props: {
    isColorSelector: {
      type: Boolean,
      default: true,
    },
    isFontSelector: {
      type: Boolean,
      default: true,
    },
    isAlignmentSelector: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      properties: [
        {
          text: 'Text',
          key: 'text',
          targetKey: 'isApplyToAllText',
          isVisible: () => true,
          value: false,
        },
        {
          text: 'Color',
          key: 'color',
          targetKey: 'isApplyToAllColor',
          applyToAllKey: 'fontColor',
          isVisible: () => this.isColorSelector,
          value: false,
        },
        {
          text: 'Font',
          key: 'font',
          targetKey: 'isApplyToAllFont',
          applyToAllKey: 'fontFamily',
          isVisible: () => this.isFontSelector,
          value: false,
        },
        {
          text: 'Size',
          key: 'size',
          targetKey: 'isApplyToAllSize',
          applyToAllKey: 'fontSize',
          isVisible: () => true,
          value: false,
        },
        {
          text: '% Size',
          key: 'sizePercent',
          targetKey: 'isApplyToAllSizePercent',
          applyToAllKey: 'fontSizePercent',
          isVisible: () => true,
          value: false,
        },
        {
          text: 'Alignment',
          key: 'alignment',
          targetKey: 'isApplyToAllAlignment',
          applyToAllKey: 'textAlignment',
          isVisible: () => this.isAlignmentSelector,
          value: false,
        },
        {
          text: 'Spacing',
          key: 'spacing',
          targetKey: 'isApplyToAllSpacing',
          applyToAllKey: 'textSpacing',
          isVisible: () => true,
          value: false,
        },
        {
          text: 'Line Height',
          key: 'leading',
          targetKey: 'isApplyToAllSpacing',
          applyToAllKey: 'lineHeight',
          isVisible: () => true,
          value: false,
        },
        {
          text: 'Position',
          key: 'position',
          targetKey: 'isApplyToAllPosition',
          isVisible: () => true,
          value: false,
        }
      ],
      selectedProperties: [],
    };
  },
  computed: {
    getProperties() {
      return this.properties.filter(property => property.isVisible());
    }
  },
  mounted() {
    this.setPropertiesValues();
  },
  methods: {
    setPropertiesValues() {
      this.properties = this.properties.map((property) => {
        return {
          ...property,
          value: !!window?.applyToPropertiesListValues?.[property.targetKey],
        }
      });

      this.onInput();
    },
    onChangeProperties(property) {
      if (property.value) {
        if (!this.selectedProperties.some((p) => p.key === property.key)) {
          this.selectedProperties.push(property);
        }
      } else {
        this.selectedProperties = this.selectedProperties.filter((p) => p.key !== property.key);
      }
    },
    onChange(key, value) {
      this.$emit('change', key, value);
    },
    onInput() {
      const selectedPropertyKeys = this.properties.reduce((acc, { applyToAllKey, key, value }) => {

        if (value) {
          acc.push(applyToAllKey || key);
        }

        return acc;
      }, []);

      this.$emit('input', selectedPropertyKeys);
    },
    apply() {
      this.selectedProperties.forEach(property => {
        this.onChange(property.key, property.value);
      })
    }
  }
}
</script>
