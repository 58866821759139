var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: { title: "Layouts in catalog", "hide-footer": "" },
          model: {
            value: _vm.open,
            callback: function ($$v) {
              _vm.open = $$v
            },
            expression: "open",
          },
        },
        [
          _c(
            "b-tab",
            { attrs: { title: "Catalogs", active: "" } },
            [
              _c(
                "div",
                { staticClass: "d-flex align-items-center mb-3" },
                [
                  _c("span", { staticClass: "mr-1" }, [_vm._v("Sort by:")]),
                  _vm._v(" "),
                  _c("b-form-select", {
                    staticClass: "w-50 cursor-pointer",
                    attrs: { options: _vm.sortOptions },
                    on: { change: _vm.onSortChange },
                    model: {
                      value: _vm.sortOption,
                      callback: function ($$v) {
                        _vm.sortOption = $$v
                      },
                      expression: "sortOption",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-list-group",
                [
                  _vm.layouts.length === 0
                    ? _c(
                        "p",
                        { staticClass: "d-flex justify-content-center" },
                        [_vm._v("\n          No items\n        ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.layouts, function (layout) {
                    return _c(
                      "b-list-group-item",
                      {
                        key: layout.id,
                        staticClass:
                          "d-flex justify-content-between catalog-item",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "text",
                            attrs: {
                              title: "#" + layout.id + " " + layout.name,
                            },
                          },
                          [
                            _vm._v(
                              "\n            #" +
                                _vm._s(layout.id) +
                                " " +
                                _vm._s(layout.name) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "catalog-item__buttons d-flex align-self-center",
                          },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.noninteractive.left",
                                    modifiers: {
                                      noninteractive: true,
                                      left: true,
                                    },
                                  },
                                ],
                                staticClass: "btn-sm",
                                attrs: {
                                  id: "remove-layout",
                                  variant: "outline-danger",
                                  title: "Remove layout from catalog",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.deleteLayout(layout)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "bi bi-trash" })]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.noninteractive.left",
                                    modifiers: {
                                      noninteractive: true,
                                      left: true,
                                    },
                                  },
                                ],
                                staticClass: "btn-sm ml-1",
                                attrs: {
                                  variant: "outline-info",
                                  title: "Open layout in new tab",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.openInNewWindow(layout)
                                  },
                                },
                              },
                              [
                                _c("b-icon", {
                                  attrs: { icon: "arrow-right-circle" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex justify-content-center mt-2" },
                [
                  _c("b-pagination", {
                    attrs: {
                      "total-rows": _vm.layoutsCount,
                      "per-page": _vm.layoutsPerPage,
                    },
                    on: { change: _vm.getPage },
                    model: {
                      value: _vm.layoutsCurrentPage,
                      callback: function ($$v) {
                        _vm.layoutsCurrentPage = $$v
                      },
                      expression: "layoutsCurrentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }