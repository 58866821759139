<template>
  <div>
    <p class="mb-2">
      Group:
    </p>
    <b-form-group
      label="Background"
      label-cols="4"
    >
      <ColorPicker
        :value="bgColor"
        :themes="themes"
        copy
        @input="onChange('bgColor', $event)"
      />
    </b-form-group>

    <b-form-group
      label="Stroke"
      label-cols="4"
    >
      <div class="d-flex">
        <ColorPicker
          :value="strokeColor"
          :themes="themes"
          copy
          @input="onChange('strokeColor', $event)"
        />
        <b-button
          v-b-tooltip.hover
          variant="primary"
          size="sm"
          title="Use background color"
          @click="onChange('strokeColor', target.fill)"
        >
          <b-icon icon="back" />
        </b-button>
      </div>
    </b-form-group>

    <b-form-group
      label="Stroke width"
      :disabled="target.locked"
    >
      <b-input-group>
        <b-input
          :value="strokeWidth"
          :min="0"
          :max="maxStrokeWidth"
          type="range"
          @input="onChange('thickness', $event)"
        />
        <template #append>
          <b-input
            :value="strokeWidth"
            :min="0"
            :max="maxStrokeWidth"
            type="number"
            @input="onChange('thickness', $event)"
          />
        </template>
      </b-input-group>
    </b-form-group>

    <PositionAndSize
      :h="(target.height * target.scaleY)"
      :w="(target.width * target.scaleX)"
      :x="target.left"
      :y="target.top"
      @input="onChange"
    />
    <b-btn
      variant="primary"
      @click="onChange(isCustomGroup ? 'ungroup' : 'group', null)"
    >
      {{ isCustomGroup ? 'Ungroup' : 'Group' }}
    </b-btn>

    <b-btn
      v-b-tooltip.hover="'Center to template size'"
      variant="primary"
      class="center-btn"
      @click="onChange('center', $event)"
    >
      <img
        src="/img/icons/center-image.svg"
        alt="center-icon"
      >
    </b-btn>

    <b-btn
      v-b-tooltip.hover="'Center horizontally'"
      variant="primary"
      class="center-btn"
      @click="onChange('centerHorizontally',$event)"
    >
      <img
        src="/img/icons/align-center.png"
        alt="align-center-icon"
      >
    </b-btn>

    <template v-if="isTextObjects">
      <b-form-row class="mt-2">
        <b-col cols="12">
          <b-form-group label="Alignment">
            <b-form-select
              :value="textAlign"
              :options="alignments"
              @change="onChange('textAlignment', $event)"
            />
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group label="Size">
            <b-form-input
              :value="fontSize"
              type="number"
              @input="onChange('fontSize', $event)"
              @dblclick="selectValueOnDblclick"
            />
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group label="Opacity">
            <b-form-input
              :value="opacity"
              type="number"
              min="0"
              step="0.01"
              max="1"
              @input="onChange('opacity', $event)"
              @dblclick="selectValueOnDblclick"
            />
          </b-form-group>
        </b-col>
      </b-form-row>

      <div
        class="d-flex my-2"
        style="grid-gap: 1rem;"
      >
        <ColorPicker
          :value="fontColor"
          :themes="themes"
          position="top-start"
          copy
          @input="onChange('fontColor', $event)"
        />

        <DropdownCounter
          :value="charSpacing"
          title="Text Spacing"
          :step="10"
          @input="onChange('textSpacing', $event)"
        >
          <i class="glyphicon glyphicon-text-width" />
        </DropdownCounter>

        <LineHeightDropdown
          :value="lineHeight"
          @input="onChange('textLeading', $event)"
        >
          <i class="cil-line-spacing" />
        </LineHeightDropdown>
      </div>

      <ApplyToAllTextDropdown
        :options="options"
        :target="target"
      />
    </template>

    <ApplyToAllImageDropdown
      v-if="isProductImageObjects"
      class="mt-3"
      :target="target"
    />
  </div>
</template>

<script>
import PositionAndSize from "./components/PositionAndSize.vue";
import ColorPicker from './components/ColorPicker.vue'
import { editorControlEventBroker, EVENTS } from '@frontend/group/modules/editor-control-popup/event-broker'
import ApplyToAllTextDropdown
  from '@frontend/components/modules/layouts-page/EditorControl/components/ApplyToAllTextDropdown.vue';
import ApplyToAllImageDropdown
  from '@frontend/components/modules/layouts-page/EditorControl/components/ApplyToAllImageDropdown.vue';
import DropdownCounter from '@frontend/components/modules/layouts-page/EditorControl/components/DropdownCounter.vue'
import LineHeightDropdown
  from '@frontend/components/modules/layouts-page/EditorControl/components/LineHeightDropdown.vue'
import { TEXT_ALIGNMENTS } from '@frontend/constants/alignment'
import mixinInput from '@frontend/components/input-mixins'
export default {
  components: {
    LineHeightDropdown,
    DropdownCounter,
    ApplyToAllImageDropdown,
    ApplyToAllTextDropdown,
    PositionAndSize,
    ColorPicker,
  },

  mixins: [
    mixinInput,
  ],

  props: {
    target: {
      type: Object,
      default: () => {
      },
    },
    options: {
      type: Object,
      default: () => {
      },
    },
    themes: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    maxStrokeWidth: 100,
    isCustomGroup: false,
    alignments: TEXT_ALIGNMENTS,
  }),

  computed:{
    isTextObjects() {
      return this.options.isTextObjects
    },

    isProductImageObjects() {
      return this.options.isProductImageObjects
    },

    bgColor() {
      const fills = this.target._objects.map(obj => obj.fill);
      return fills.every(color => color === fills[0]) ? fills[0] : null;
    },

    strokeColor() {
      const strokes = this.target._objects.map(obj => obj.stroke);
      return strokes.every(color => color === strokes[0]) ? strokes[0] : null;
    },

    strokeWidth() {
      const widths = this.target._objects.map(obj => obj.strokeWidth);
      return widths.every(width => width === widths[0]) ? widths[0] : null;
    },

    textAlign () {
      return this.getUniformValue('textAlign', 'left')
    },
    fontSize () {
      return this.getUniformValue('fontSize', 0)
    },
    opacity () {
      return this.getUniformValue('opacity', 1)
    },
    fontColor () {
      return this.getUniformValue('fontColor', '#000000')
    },
    charSpacing () {
      return this.getUniformValue('charSpacing', 0)
    },
    lineHeight () {
      return this.getUniformValue('lineHeight', 1)
    },
  },

  mounted() {
    this.isCustomGroup = this.target.type !== 'activeSelection';
  },

  methods: {
    onChange: _.debounce(function (action, value) {
      editorControlEventBroker.fire(EVENTS.CUSTOM_GROUP_CHANGED, {
        action,
        value,
      })
      
      this.isCustomGroup = true;
    }, 15),
    applyToAll([action]) {
      this.onChange(action, action === 'bgColor' ? this.target.fill : target.stroke);
    },
    getUniformValue (property, defaultValue = null) {
      const objects = this.target?._objects || []
      return objects.length && objects.every(obj => obj[property] === objects[0][property])
          ? objects[0][property]
          : defaultValue
    },
  }
}
</script>

<style lang="scss" scoped>
.center-btn {
  display: inline-flex;

  img {
    width: 1.5rem;
    filter: brightness(9);
  }
}
</style>
