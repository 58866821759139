import Base from "@/includes/parse_psd_data/psd-types/base";

export class Layer extends Base {

  data

  type

  fieldType

  path = []

  left = 0

  top = 0

  width = 0

  height = 0

  isClippingMask = false

  clippingMask = null

  visible

  uuid;

  name = ''

  field = undefined;

  buildFromData(field) {
    super.buildFromData(field);
    delete field.parent;
    this.data = field;
    this.type = field.type;
    this.fieldType = field.fieldType;
    this.visible = field.visible;
    this.uuid = field.uuid;
    this.name = field.name;

    this.path = field.path || this.path;
    this.left = field.left || this.left;
    this.top = field.top || this.top;
    this.width = field.width || this.width;
    this.height = field.height || this.height;

    this.isClippingMask = field.isClippingMask;
    this.clippingMask = field.clippingMask;

  }

  getType() {
    return this.type;
  }
}