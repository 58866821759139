<template>
  <div>
    <div class="modal-body">
      <div
        class="modal-body--alert alert alert-danger d-none"
        role="alert"
      />
      <div class="row h-100 w-100">
        <div
          class="modal-body--preview col-6"
        >
          <img
            v-if="!previewTemplateThumbnail"
            class="modal-body--preview__img"
            :class="{'d-none': isImageLoading}"
            :src="entireImageThumbnail | share"
            alt="preview"
            @load="imageLoadedHandler"
          >
          <img
            v-else
            class="modal-body--preview__img"
            :src="previewTemplateThumbnail"
            alt="preview"
          >
          <div
            class="spinner-border modal-body--preview__spinner"
            :class="{'d-none': !isImageLoading}"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <div
            v-if="previewTemplateThumbnail"
            class="modal-body--preview__img-close"
            @click="setPreviewTemplateThumbnail({ previewTemplateThumbnail: '' })"
          >
            <svg
              width="20px"
              height="20px"
            >
              <use href="/img/icons/sprite.svg#file-circle-close" />
            </svg>
          </div>
        </div>
        <LayersTree
          class="modal-body--layers-wrapper col-6"
          :templates="templates"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <button
          type="button"
          class="btn btn-secondary"
          @click="selectAllHandler"
        >
          Select All
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          @click="unselectAllHandler"
        >
          Unselect All
        </button>
      </div>
      <div
        class="col-8 d-flex justify-content-end align-items-start"
        style="gap: 0.25rem"
      >
        <b-button
          class="mr-1"
          :disabled="isSavingNodesChanges"
          @click="cancel"
        >
          Close
        </b-button>
        <b-button
          v-if="isNodesChangesShouldBeSaved"
          class="d-flex align-items-center"
          :disabled="isSavingNodesChanges"
          @click="saveNodesChanges"
        >
          <span>
            {{ isSavingNodesChanges ? 'Saving changes...' : 'Save changes' }}
            <i
              v-if="isSavingNodesChanges"
              class="spinner-border spinner-border-sm m-0"
            />
          </span>
        </b-button>
        <div>
          <button
            v-if="isTemplateFeatures && isImportIntoTemplate"
            type="button"
            class="btn btn-primary"
            :disabled="isUpdatingPreviewTemplate"
            @click="updatePreviewTemplate"
          >
            <span
              class="spinner-border spinner-border-sm"
              :class="{'d-none': !isUpdatingPreviewTemplate}"
              role="status"
              aria-hidden="true"
            />
            Preview
          </button>
          <button
            v-if="isTemplateFeatures"
            type="button"
            class="btn btn-primary"
            :disabled="!isAddToTemplateButtonAvailable"
            @click="addToTemplate"
          >
            <span
              class="spinner-border spinner-border-sm"
              :class="{'d-none': isAddToTemplateButtonAvailable}"
              role="status"
              aria-hidden="true"
            />
            Add to template
          </button>
          <button
            v-if="availableDefaultFlow"
            type="button"
            class="btn btn-primary"
            :disabled="isExtractingAssets || isSavingNodesChanges"
            @click="proceedByFlow"
          >
            <span
              class="spinner-border spinner-border-sm"
              :class="{'d-none': !isExtractingAssets}"
              role="status"
              aria-hidden="true"
            />
            Extract assets
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="isTemplateFeatures"
      class="d-flex mt-1 align-items-center justify-content-end"
    >
      <b-button
        variant="primary"
        :disabled="isProductRecognitionProcessing"
        @click="requestProductRecognition"
      >
        Recognize product images
      </b-button>
    </div>

    <div class="row mt-2">
      <div class="col-6">
        <div>
          <div class="row">
            <div class="col-12">
              <div v-if="isUseProductImagesFromPSDAvailable">
                <b-form-checkbox
                  id="isUseProductImagesFromPSD"
                  :checked="isUseProductImagesFromPSD"
                  @change="setIsUseProductImagesFromPSD({ value: !isUseProductImagesFromPSD })"
                >
                  Use product images from PSD
                </b-form-checkbox>
              </div>
            </div>

            <div
              v-if="isTemplateFeatures"
              class="col-12"
            >
              <b-form-checkbox
                id="replaceExistingProduct"
                :checked="isReplaceExistingProduct"
                @change="setReplaceExistingProduct({ isReplaceExistingProduct: !isReplaceExistingProduct })"
              >
                Replace existing product images
              </b-form-checkbox>
            </div>

            <div
              v-if="isSavePsdAvailable"
              class="col-12"
            >
              <b-form-checkbox
                id="savePsd"
                :checked="isSavePsd"
                @change="setSavePsd({ value: !isSavePsd })"
              >
                Save PSD
              </b-form-checkbox>
            </div>
            <div
              v-if="showSaveImagesToTheme"
              class="col-12"
            >
              <b-form-checkbox
                id="createIndividualAssets"
                :checked="isCreateIndividualAssets"
                @change="setCreateIndividualAssets({ isCreateIndividualAssets: !isCreateIndividualAssets })"
              >
                Create individual assets
              </b-form-checkbox>
            </div>
            <div class="col-12">
              <div v-if="isTemplateFeatures">
                <b-form-checkbox
                  id="isTreatMultipleSpacesLineBreak"
                  :checked="isTreatMultipleSpacesAsSingle"
                  @change="setTreatMultipleSpacesAsSingle({ isTreatMultipleSpacesAsSingle: !isTreatMultipleSpacesAsSingle })"
                >
                  Treat multiple spaces as single space
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 d-flex justify-content-end align-items-start">
        <div class="col-10">
          <div>
            <b-form-checkbox
              v-if="isImportIntoTemplate && isTemplateFeatures"
              id="autoMapToFields"
              :checked="isAutoMapToFields"
              @change="setAutoMapFields()"
            >
              Auto map to fields
            </b-form-checkbox>
            <b-form-checkbox
              v-if="isTemplateFeatures && isImportIntoTemplate"
              v-b-tooltip="{ customClass: 'custom-tooltip' }"
              v-b-tooltip.hover.left
              title="Checking this option will remove any default text from the destination templates"
              :checked="isRemovePlaceholderText"
              @change="setRemovePlaceholderText({ isRemovePlaceholderText: !isRemovePlaceholderText })"
            >
              Remove placeholder text
            </b-form-checkbox>
            <b-form-checkbox
              v-if="isTemplateFeatures && isImportIntoTemplate"
              :checked="isUseSourceTextSettings"
              @change="setUseSourceTextSettings({ isUseSourceTextSettings: !isUseSourceTextSettings })"
            >
              Use source text settings
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import {MUTATIONS} from "../../../store/psd-layers";
import {EVENTS, psdLayersEventBroker} from "../../../group/modules/psd-layers/event-broker";
import {ApplyStrategy} from "@frontend/services/api/psd-layers";
import LayersTree from "@frontend/components/modules/psd-layers/LayersTree.vue";

const { mapGetters, mapActions, mapMutations } = createNamespacedHelpers('psdLayers')

const DEFAULT_FLOW = [
  ApplyStrategy.FLOWS.SAVE_TO_FILE_BROWSER,
  ApplyStrategy.FLOWS.CREATE_TEMPLATE_FROM_ADMIN
]

const SAVE_PSD_NOT_AVAILABLE = [
  ApplyStrategy.FLOWS.SAVE_TO_FILE_BROWSER,
  ApplyStrategy.FLOWS.CREATE_TEMPLATE_FROM_ADMIN
]

export default {
  name: "LayerSelector",
  components: {LayersTree},
  filters: {
    share(url) {
      const prefix = `${location.origin}/share?file=`;

      if (url.includes(prefix)) {
        return url;
      }

      return `${prefix}${url}`;
    }
  },
  props: {
    showSaveImagesToTheme: {
      type: Boolean,
      default: () => false
    },
  },
  data: () => ({
    isImageLoading: true,
  }),
  computed: {
    ...mapGetters([
      'entireImageThumbnail',
      'previewTemplateThumbnail',
      'templates',
      'selectedTemplates',
      'flow',
      'isGenerating',
      'isExtractingAssets',
      'isSavePsd',
      'isCreateIndividualAssets',
      'isUpdatingPreviewTemplate',
      'isTreatMultipleSpacesAsSingle',
      'selectedTemplates',
      'isImportIntoTemplate',
      'isReplaceExistingProduct',
      'isAutoMapToFields',
      'isRemovePlaceholderText',
      'isTemplateFeatures',
      'isUseSourceTextSettings',
      'isSavingNodesChanges',
      'nodesChanges',
      'isProductRecognitionProcessing',
      'isUseProductImagesFromPSD',
      'files'
    ]),
    availableDefaultFlow() {
      return DEFAULT_FLOW.includes(this.flow)
    },
    isSavePsdAvailable() {
      return !SAVE_PSD_NOT_AVAILABLE.includes(this.flow)
    },
    isNodesChangesShouldBeSaved() {
      return this.flow === ApplyStrategy.FLOWS.SAVE_TO_FILE_BROWSER && this.nodesChanges.length > 0;
    },
    isUseProductImagesFromPSDAvailable() {
      return this.isTemplateFeatures;
    },
    isAddToTemplateButtonAvailable() {
      const isSomeFileNotReady = this.files.some(file => (file.isLoading || file.isQueued || file.isError));

      return !isSomeFileNotReady && !this.isGenerating;
    }
  },
  watch: {
    entireImageThumbnail() {
      this.isImageLoading = true;
    }
  },
  methods: {
    ...mapActions([
      'cancel',
      'addToTemplate',
      'updatePreviewTemplate',
      'proceedByFlow',
      'requestProductRecognition',
      'saveNodesChanges'
    ]),
    ...mapMutations({
      toggleAll: MUTATIONS.TOGGLE_ALL,
      setSelectedTemplate: MUTATIONS.SET_SELECTED_TEMPLATES,
      setSavePsd: MUTATIONS.SET_SAVE_PSD,
      setCreateIndividualAssets: MUTATIONS.SET_CREATE_INDIVIDUAL_ASSETS,
      setTreatMultipleSpacesAsSingle: MUTATIONS.SET_TREAT_MULTIPLE_SPACES_AS_SINGLE,
      setPreviewTemplateThumbnail: MUTATIONS.SET_PREVIEW_TEMPLATE_THUMBNAIL,
      setReplaceExistingProduct: MUTATIONS.SET_IS_REPLACE_EXISTING_PRODUCT,
      setRemovePlaceholderText: MUTATIONS.SET_REMOVE_PLACEHOLDER_TEXT,
      setUseSourceTextSettings: MUTATIONS.SET_USE_SOURCE_TEXT_SETTINGS,
      setIsUseProductImagesFromPSD: MUTATIONS.SET_IS_USE_PRODUCT_IMAGES_FROM_PSD
    }),
    imageLoadedHandler() {
      this.isImageLoading = false;
    },
    selectAllHandler() {
      this.toggleAll({ selected: true })
    },
    unselectAllHandler() {
      this.toggleAll( { selected: false })
    },
    back() {
      this.setSelectedTemplate({});
    },
  }
}
</script>

<style scoped>
.modal-body--view-type-selector {
  max-width: 110px;
}

.custom-tooltip {
  max-width: 200px;
}
</style>
