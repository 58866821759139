var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: { title: "Columns" },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button", "data-dismiss": "modal" },
                      on: { click: _vm.close },
                    },
                    [_vm._v("\n        Close\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary update-data",
                      attrs: { type: "submit" },
                      on: { click: _vm.updateData },
                    },
                    [_vm._v("\n        Update\n      ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.open,
            callback: function ($$v) {
              _vm.open = $$v
            },
            expression: "open",
          },
        },
        [
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "div",
              { staticClass: "columns-list sortable-list position-relative" },
              _vm._l(_vm.checkboxOptions, function (option, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "form-group d-flex item" },
                  [
                    _c(
                      "div",
                      { staticClass: "custom-control custom-checkbox" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedOptions,
                              expression: "selectedOptions",
                            },
                          ],
                          staticClass: "custom-control-input new_project_input",
                          attrs: { id: option.id, type: "checkbox" },
                          domProps: {
                            value: option.value,
                            checked: Array.isArray(_vm.selectedOptions)
                              ? _vm._i(_vm.selectedOptions, option.value) > -1
                              : _vm.selectedOptions,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.selectedOptions,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = option.value,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.selectedOptions = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.selectedOptions = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.selectedOptions = $$c
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "custom-control-label",
                            attrs: { for: option.id },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(option.label) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }