<template>
  <div>
    <b-form-row>
      <b-col>
        <b-form-group :label="title">
          <b-form-select
            v-model="isEnabled"
            :options="availabilityOptions"
            class="form-control"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <div v-if="isEnabled">
      <b-card bg-variant="light">
        <b-form-row>
          <b-col>
            <b-form-group label="Link to PDF">
              <b-form-input
                v-model="pdf"
                class="form-control"
                placeholder="Enter the link to the PDF"
              />
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExportAddPagesConfig",
  props: {
    title: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      required: true,
      default: () => ({
        isEnabled: false,
        pdf: null,
      })
    }
  },
  data() {
    return {
      isEnabled: false,
      pdf: null,
      availabilityOptions: [
        { text: 'Enabled', value: true },
        { text: 'Disabled', value: false },
      ],
      resultData: {
        isEnabled: false,
        pdf: null,
      },
    };
  },
  watch: {
    config: {
      handler(newVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(this.resultData)) {
          Object.assign(this, newVal);
          this.updateResultData();
        }
      },
      deep: true,
      immediate: true,
    },
    isEnabled: function() {
      this.updateResultData();
    },
    pdf: function() {
      this.updateResultData();
    },
    resultData: function(newResultData) {
      this.$emit('data-updated', newResultData);
    },
  },
  methods: {
    updateResultData() {
      this.resultData = {
        isEnabled: this.isEnabled,
        pdf: this.pdf,
      };
    }
  }
}
</script>
