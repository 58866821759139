<template>
  <div>
    <b-button-group
      v-if="showNextTemplateButton || selectedInstanceIndex"
      size="sm"
      class="my-2"
    >
      <b-button
        v-if="showNextTemplateButton"
        variant="outline-primary"
        @click="moveInstanceSelection(1)"
      >
        <b-icon icon="arrow-down" />
        Next template
      </b-button>
      <b-button
        v-if="selectedInstanceIndex"
        variant="outline-primary"
        @click="moveInstanceSelection(-1)"
      >
        Previous template
        <b-icon icon="arrow-up" />
      </b-button>
    </b-button-group>

    <b-form-group
      v-for="(field, index) in textFields"
      :key="`${field.element_id}_${index}`"
      :label-for="`field_${field.id}`"
    >
      <FormGroupCharLimit
        :label="field.name"
        :value="selectedInstance.data[field.element_id]"
        :max="10000"
      >
        <b-input
          :id="`field_${field.id}`"
          :value="selectedInstance.data[field.element_id]"
          @focus="setActiveObject(field)"
          @input="updateField(field, $event)"
          @click.stop
        />
      </FormGroupCharLimit>
    </b-form-group>
    <b-form-group
      v-for="(field, index) in multilineTextFields"
      :key="`${field.element_id}_${index}`"
      :label-for="`field_${field.id}`"
    >
      <FormGroupCharLimit
        :label="field.name"
        :value="selectedInstance.data[field.element_id]"
        :max="10000"
      >
        <b-textarea
          :id="`field_${field.id}`"
          :value="getValidatedValue(selectedInstance.data[field.element_id])"
          max-rows="8"
          @focus="setActiveObject(field)"
          @input="updateField(field, $event)"
          @click.stop
        />
      </FormGroupCharLimit>
      <div
        v-if="shouldShowThisField(field)"
        class="d-flex align-items-baseline"
      >
        <b-button
          class="mt-1 btn-sm w-50"
          variant="primary"
          @click="redraw(field)"
        >
          Align to <strong>{{ JSON.parse(field.options).Option1 }}</strong>
        </b-button>

        <b-checkbox
          v-show="false"
          :checked="getAutoPositionValue(field)"
          class="ml-1 w-40"
          @change="onChangeAutoPositionValue(field, $event)"
        >
          Auto position
        </b-checkbox>
      </div>
    </b-form-group>
    <b-form-group
      v-for="(field) in additionalTextFields"
      :key="`${field.element_id}`"
      :label-for="`add_field_${field.element_id}`"
    >
      <FormGroupCharLimit
        :label="field.name"
        :value="field.text"
        :max="10000"
      >
        <b-input
          :id="`add_field_${field.element_id}`"
          :value="field.text"
          @focus="setActiveObject(field)"
          @input="updateField(field, $event)"
          @click.stop
        />
      </FormGroupCharLimit>
    </b-form-group>
    <b-form-group
      v-for="(field) in additionalMultilineTextFields"
      :key="`${field.element_id}`"
      :label-for="`add_field_${field.element_id}`"
    >
      <FormGroupCharLimit
        :label="field.name"
        :value="field.text"
        :max="10000"
      >
        <b-textarea
          :id="`add_field_${field.element_id}`"
          :value="field.text"
          max-rows="8"
          @focus="setActiveObject(field)"
          @input="updateField(field, $event)"
          @click.stop
        />
      </FormGroupCharLimit>
    </b-form-group>
    <b-form-group class="translation-form-group">
      <div class="d-flex flex-column">
        <b-form-select
          v-model="translateTargetLanguage"
          class="flex-grow-1 mr-2"
          :options="languages"
        />
        <div class="button-wrapper mt-3">
          <b-dropdown
            id="translate-options-dropdown"
            text="Translate"
            variant="primary"
          >
            <b-list-group flush>
              <b-list-group-item>
                <b-overlay
                  :show="loadingTranslate"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                  class="d-inline-block"
                >
                  <b-button
                    class="flex-shrink-0"
                    @click="translate"
                  >
                    Translate
                  </b-button>
                </b-overlay>
              </b-list-group-item>
              <b-list-group-item>
                <b-overlay
                  :show="loadingCopyAndTranslate"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                  class="d-inline-block"
                >
                  <b-button
                    class="flex-shrink-0"
                    @click="copyAndTranslate"
                  >
                    Copy Template & Translate
                  </b-button>
                </b-overlay>
              </b-list-group-item>
              <b-list-group-item>
                <b-overlay
                  :show="loadingCopyFieldsAndTranslate"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                  class="d-inline-block"
                >
                  <b-button
                    class="flex-shrink-0"
                    @click="copyFieldsAndTranslate"
                  >
                    Copy Fields & Translate
                  </b-button>
                </b-overlay>
              </b-list-group-item>
            </b-list-group>
          </b-dropdown>
        </div>
      </div>
    </b-form-group>

    <b-form-group label="Global Update">
      Apply to:
      <SelectTemplateDropdown
        class="d-inline"
        :local-storage-key="'selected-templates-global-update'"
        :clear-local-storage="true"
      />
      <b-button 
        class="exclude-for-b-overlay-wrap"
        size="sm"
        variant="primary"
        @click="confirmApplyToAllGlobal"
      >
        Apply
      </b-button>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { editorControlEventBroker, EVENTS } from '@frontend/group/modules/editor-control-popup/event-broker'
import { setSelectedInstance } from '@frontend/group/modules/copy-paste-object'
import { HANDLER_TYPES } from "@frontend/group/modules/translate-template";
import SelectTemplateDropdown
  from "@frontend/components/modules/layouts-page/EditorControl/components/SelectTemplateDropdown.vue";
import {GLOBAL_UPDATE_LOCAL_STORAGE_KEY, isGlobalUpdateOn} from "@frontend/constants/global-update";
import FormGroupCharLimit from "@frontend/components/common/atoms/FormGroupCharLimit.vue";

export default {
  name: 'FieldsModule',
  components: {FormGroupCharLimit, SelectTemplateDropdown},

  data: () => ({
    translateTargetLanguage: 'Choose a language...',
    languages: [
      'Choose a language...',
      'Arabic',
      'Bengali',
      'Cantonese',
      'Dutch',
      'English',
      'French-CA',
      'French-FR',
      'German',
      'Hindi',
      'Italian',
      'Japanese',
      'Korean',
      'Polish',
      'Portuguese',
      'Russian',
      'Simplified Chinese',
      'Spanish',
      'Swedish',
      'Thai',
      'Traditional Chinese',
      'Vietnamese'
    ],
    loadingCopyFieldsAndTranslate: false,
    loadingCopyAndTranslate: false,
    loadingTranslate: false,
    isApplyToAllGlobalUpdate: false
  }),

  computed: {
    ...mapGetters('layout', [
      'selectedInstance',
      'selectedInstanceIndex',
      'selectedInstanceFieldsWithType',
      'selectedInstanceAdditionalFields',
      'currentPagePreviews',
    ]),
    textFields () {
      return this.selectedInstanceFieldsWithType('Text')
    },
    multilineTextFields () {
      return this.selectedInstanceFieldsWithType('Multi Line Text')
    },
    additionalTextFields () {
      return this.selectedInstanceAdditionalFields.filter(field => field.type === 'Text');
    },
    additionalMultilineTextFields () {
      return this.selectedInstanceAdditionalFields.filter(field => field.type === 'Multi Line Text');
    },
    showNextTemplateButton () {
      return !!this.currentPagePreviews.at(this.selectedInstanceIndex + 1)
    },
  },
  
  mounted() {
    this.isApplyToAllGlobalUpdate = isGlobalUpdateOn();
    this.changeGlobalUpdateIndicator()
    if (localStorage.getItem(GLOBAL_UPDATE_LOCAL_STORAGE_KEY) === null) {
      localStorage.setItem(GLOBAL_UPDATE_LOCAL_STORAGE_KEY, false);
    }
    editorControlEventBroker.on(EVENTS.TOGGLE_LOADING, this.toggleLoading.bind(this));
    editorControlEventBroker.on(EVENTS.GLOBAL_UPDATE, () => {
      this.isApplyToAllGlobalUpdate = !this.isApplyToAllGlobalUpdate;
      this.onChangeApplyToAllGlobal();
    });
  },

  methods: {
    onChangeApplyToAllGlobal() {
      localStorage.setItem(GLOBAL_UPDATE_LOCAL_STORAGE_KEY, this.isApplyToAllGlobalUpdate);
      this.changeGlobalUpdateIndicator()
      if (this.isApplyToAllGlobalUpdate && this.selectedInstance) {
        const GLOBAL_UPDATE = 'globalUpdate'
        editorControlEventBroker.fire(EVENTS.IMAGE_CHANGED, { action:GLOBAL_UPDATE, instance: this.selectedInstance})
      }
    },
    changeGlobalUpdateIndicator() {
      const indicator = $('div#indicator-global-update');
      const globalUpdateIndicator = $('div#indicator-global-update .slider');
      if (isGlobalUpdateOn()) {
        globalUpdateIndicator.addClass('active');
      } else {
        globalUpdateIndicator.removeClass('active');
      }
      indicator.removeClass('d-none');
    },
    updateField (field, value) {
      editorControlEventBroker.fire(EVENTS.TEXT_CHANGED, {
        value,
        action: 'inputText',
      })
    },
    setActiveObject (field) {
      const target = _.find(this.selectedInstance.canvas.getObjects(), ['element_id', field.element_id])
      editorControlEventBroker.fire(EVENTS.SET_ACTIVE_OBJECT, { target })
    },
    shouldShowThisField(field){
      const options = $.parseJSON(field.options);
      return (options.Option1 !== null && options.Option1 !== "");
    },
    redraw(field) {
      this.setActiveObject(field);
      editorControlEventBroker.fire(EVENTS.TEXT_CHANGED, {
        value: '',
        action: 'redrawText',
      })
    },
    moveInstanceSelection(step) {
      const newSelectedInstance = this.currentPagePreviews[this.selectedInstanceIndex + step]
      newSelectedInstance.canvas.wrapperEl.scrollIntoView({ behavior: 'smooth', block: 'center' })
      setSelectedInstance(newSelectedInstance.id)
    },
    translate() {
      this.emitTranslateEvent(HANDLER_TYPES.TRANSLATE);
    },
    copyAndTranslate() {
      this.emitTranslateEvent(HANDLER_TYPES.COPY_AND_TRANSLATE);
    },
    copyFieldsAndTranslate() {
      this.emitTranslateEvent(HANDLER_TYPES.COPY_FIELDS_AND_TRANSLATE);
    },
    emitTranslateEvent(handler) {
      if (this.translateTargetLanguage === 'Choose a language...') {
        return;
      }
      
      editorControlEventBroker.fire(EVENTS.TRANSLATE, {
        textFields: this.textFields,
        multilineTextFields: this.multilineTextFields,
        additionalTextFields: this.additionalTextFields,
        additionalMultilineTextFields: this.additionalMultilineTextFields,
        instance: this.selectedInstance,
        lang: this.translateTargetLanguage,
        handler: handler
      });
    },
    toggleLoading({ loadingType }) {
      this[loadingType] = !this[loadingType];
    },
    getValidatedValue(value) {
      return typeof value === 'boolean' ? '' : (value || '');
    },
    async confirmApplyToAllGlobal () {
      const confirmed = await this.$bvModal.msgBoxConfirm('Remember that performing this action will apply the changes to all templates', {
        title: 'Are you sure you want to perform this action?',
        okTitle: 'Yes',
        cancelTitle: 'No',
        autoFocusButton: 'ok',
        centered: true,
        contentClass: 'text-center',
      })
      if (confirmed) {
        localStorage.setItem(GLOBAL_UPDATE_LOCAL_STORAGE_KEY, true);
        editorControlEventBroker.fire(
          EVENTS.IMAGE_CHANGED, 
          { 
            action:'globalUpdate',
            instance: this.selectedInstance,
            callback: () => localStorage.setItem(GLOBAL_UPDATE_LOCAL_STORAGE_KEY, false)
          });
      }
    },
    getAutoPositionValue(field) {
      if (this.selectedInstance.data.hasOwnProperty(`${field.element_id}_auto_position`)) {
        const value = this.selectedInstance.data[`${field.element_id}_auto_position`];
        return value === 'true' || value === true;
      }
      
      return false
    },
    onChangeAutoPositionValue(field, value) {
      this.setActiveObject(field);

      editorControlEventBroker.fire(EVENTS.TEXT_CHANGED, {
        value: value,
        action: 'autoPosition',
      });
    },
  }
}
</script>

<style scoped lang="scss">
.translation-form-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    ::v-deep {
        ul.dropdown-menu {
            width: 100%;
        }
    }
}
  .b-overlay-wrap, button:not(.exclude-for-b-overlay-wrap) {
    width: 100%;
  }
  
  .button-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  button {
    white-space: nowrap;
  }
</style>
