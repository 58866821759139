const {Context} = require("./frontend/contexts/context");
import _ from 'lodash'
import Swal from 'sweetalert2'
import Popper from 'popper.js'
import jQuery from 'jquery'
import 'bootstrap'
import toastr from 'toastr'
import axios from 'axios'
import Pusher from 'pusher-js'
import Echo from 'laravel-echo';

window._ = _
window.Swal = Swal.mixin({
  showConfirmButton: true,
  showCancelButton: true,
  focusConfirm: true,
  customClass: {
    popup: 'pb-0',
    actions: 'modal-footer justify-content-end w-100 m-0',
    confirmButton: 'btn btn-primary order-2',
    cancelButton: 'btn btn-outline-primary order-1',
  },
  buttonsStyling: false,
})

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
  window.Popper = Popper
  window.$ = window.jQuery = jQuery
  require('datatables');
} catch (e) {}

window.toastr = toastr

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = axios

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

let token = document.head.querySelector('meta[name="csrf-token"]')?.content;
if (token) {
  window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token;
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.MIX_PUSHER_APP_KEY,
  cluster: process.env.MIX_PUSHER_APP_CLUSTER,
  forceTLS: true
});

window.Pusher = Pusher
window.$context = new Context;
