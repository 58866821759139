import {recursiveWalker} from "@/includes/parse_psd_data";
import {PSD_LAYERS_DATA_TYPES} from "@/includes/parse_psd_data/constants";
import _ from "lodash";

export const extractLayersFromTree = tree => {
    const layers = []

    recursiveWalker(tree, node => {
        if (!PSD_LAYERS_DATA_TYPES.includes(node.type) || !node.visible) {
            return;
        }

        layers.push(node)
    })

    return layers
}

export const filterLayersByTemplate = (layers, template) => layers.filter(layer => template.fields.find(field => field.uuid === layer.uuid))

export const multipleTemplateHandler = (callback, postHandler) => async state => {
    const files = Array.isArray(state) ? state : [state];

    const promises = [];

    for (const file of files) {
        for (const template of file.templates) {
            promises.push(async () => {
                const data = {
                    ...file,
                    template,
                    layers: filterLayersByTemplate(file.layers, { fields: extractLayersFromTree(template.children) }),
                };

                return callback(data);
            });
        }
    }

    const chunks = _.chunk(promises, 2);

    for (const chunk of chunks) {
        await Promise.all(chunk.map(fn => fn()));
    }

    postHandler()
}

export const getOptionsFromLibraryModal = () => {
    let options = {}

    try {
        options = JSON.parse($('#selectBkImgModal').data('options'))
    } catch (error) {
        console.error('No options in file browser modal provided')
    }

    return options
}
