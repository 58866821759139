<template>
  <b-tabs
    content-class="mt-3"
    small
    fill
  >
    <b-tab title="Images">
      <b-btn-group vertical>
        <b-btn
          variant="outline-primary"
          @click="addProductImages"
        >
          <b-icon icon="file-earmark-image" />
          Add product images...
        </b-btn>
        <b-btn
          variant="outline-primary"
          @click="addProductDimensions"
        >
          <b-icon icon="aspect-ratio" />
          Add product dimensions...
        </b-btn>
        <b-btn
          variant="outline-primary"
          @click="addAdditionalField('Background Theme Image')"
        >
          <b-icon icon="card-image" />
          Add image...
        </b-btn>
      </b-btn-group>
    </b-tab>
    <b-tab title="Add Fields">
      <b-btn-group vertical>
        <b-btn
          variant="outline-primary"
          @click="addAdditionalField('Text')"
        >
          <b-icon icon="cursor-text" />
          Text
        </b-btn>
        <b-btn
          variant="outline-primary"
          @click="addAdditionalField('Multi Line Text')"
        >
          <b-icon icon="input-cursor-text" />
          Multi Line Text
        </b-btn>
        <b-btn
          variant="outline-primary"
          @click="addAdditionalField('Rectangle')"
        >
          <b-icon icon="square" />
          Rectangle
        </b-btn>
        <b-btn
          variant="outline-primary"
          @click="addAdditionalField('Circle')"
        >
          <b-icon icon="circle" />
          Circle
        </b-btn>
        <b-btn
          variant="outline-primary"
          @click="addAdditionalField('Safe Zone')"
        >
          <b-icon icon="x-square" />
          Safe Zone
        </b-btn>
      </b-btn-group>
    </b-tab>
  </b-tabs>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ContentModule',

  computed: {
    ...mapGetters('layout', [
      'selectedInstance',
      'selectedInstanceIndex',
      'selectedInstanceFieldsWithType',
      'currentPagePreviews',
    ]),
  },

  methods: {
    addProductImages () {
      this.selectedInstance.canvas.fire('add:product-images', {
        instanceId: this.selectedInstance.id,
        isNeedAddProductDimensionsField: false,
      })
    },
    addProductDimensions () {
      this.selectedInstance.canvas.fire('add:product-dimensions', {
        instanceId: this.selectedInstance.id,
        isNeedAddProductDimensionsField: false,
      })
    },
    addAdditionalField (type) {
      this.selectedInstance.canvas.fire('add:additional-field', {
        instanceId: this.selectedInstance.id,
        type,
      })
    },
  }
}
</script>

<style scoped lang="scss">
.btn-group-vertical {
  width: 100%;

  .btn {
    display: flex;
    align-items: center;

    .b-icon {
      margin-right: .5rem;
    }
  }
}
</style>
