var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-badge",
    {
      staticClass: "a-psd-preview-label",
      style: {
        left: _vm.left + "px",
        top: _vm.top + "px",
        right: _vm.right + "px",
        bottom: _vm.bottom + "px",
      },
      attrs: { pill: "", variant: _vm.variant },
    },
    [_vm._v("\n  " + _vm._s(_vm.title) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }