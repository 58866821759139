<template>
  <div class="c-folder-layer__item d-flex align-items-center mt-2">
    <div class="c-folder-layer__item--preview mr-2">
      <img
        class="layer--item__img"
        :src="src"
        :alt="node.name"
        :style="getStyle"
      >
    </div>
    <div
      v-b-tooltip.hover.top.viewport.noninteractive="{ title: node.data.name, disabled: isNotTruncated(node.data.name) }"
      class="layer--item__text"
    >
      <EditableTextElement
        v-if="isLayerEditable"
        :default-value="node.name"
        :disabled="isSavingNodesChanges"
        :render-value="truncateFieldName"
        @change="changeLayerName"
      />
      <span v-else> {{ truncateFieldName(node.name) }} </span>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex';
import EditableTextElement from '@frontend/components/common/atoms/EditableTextElement.vue';
import {ApplyStrategy} from '@frontend/services/api/psd-layers';
import layerMixin from './mixin';

const { mapGetters, mapActions } = createNamespacedHelpers('psdLayers')


export default {
  name: "ImageLayer",
  components: {
    EditableTextElement,
  },
  mixins: [layerMixin],
  props: {
    node: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'flow',
      'isSavingNodesChanges'
    ]),
    src() {
      return location.origin + '/share?file=' + (this.node.src) + '&lightweight&scale=128'
    },
    getStyle() {
      const layer = this.node;
      const transform = `${layer.isFlipH ? 'scaleX(-1)' : ''} ${layer.isFlipV ? 'scaleY(-1)' : ''}` 

      return {
        ...(
          layer.isFlipV || layer.isFlipH ?
            {
              '-webkit-transform': transform,
              transform
            } : {})
      };
    },
    isLayerEditable() {
      return this.flow === ApplyStrategy.FLOWS.SAVE_TO_FILE_BROWSER;
    }
  },
  methods: {
    ...mapActions([
      'setNodeChanges',
    ]),
    changeLayerName(newName) {
      const nodeId = this.node.uuid;

      this.setNodeChanges({
        nodeId: nodeId,
        changes: [
          {key: 'data.name', value: newName},
          {key: 'name', value: newName}
        ]
      });
    }
  }
}
</script>


<style scoped lang="scss">

</style>
