import LayoutFolderService from '../services/api/layout-folder'

export default {
  namespaced: true,

  state: () => ({
    selectedInstance: null,
    currentPagePreviews: [],
    folders: [],
    keepAspectRatio: false,
    target: null,
    layers: [],
    folder: null
  }),

  getters: {
    selectedInstance: state => state.selectedInstance,
    selectedInstanceIndex: state => _.findIndex(state.currentPagePreviews, ['id', state.selectedInstance.id]),
    selectedInstanceFieldsWithType: state => type => _.filter(state.selectedInstance.template.fields, ['type', type]),
    selectedInstanceAdditionalFields: state => {
      const allTextElements = state.selectedInstance.canvas.getObjects('textbox');

      const result = [];
      
      for (const element of allTextElements) {
        if (element.element_id.startsWith('add_multi_line_text') || element.element_id.startsWith('add_text')) {
          result.push({
            element_id: element.element_id,
            name: element.name,
            text: element.text,
            type: element.element_id.startsWith('add_multi_line_text') ? 'Multi Line Text' : 'Text',
          });
        }
      }
      
      return result.sort((a, b) => {
        const aNameReversed = a.name.split('').reverse().join('');
        const bNameReversed = b.name.split('').reverse().join('');
        return aNameReversed.localeCompare(bNameReversed);
      });
    },
    currentPagePreviews: state => state.currentPagePreviews,
    folders: state => state.folders,
    keepAspectRatio: state => state.keepAspectRatio,
    target: state => state.target,
    layers: state => state.layers,
    folder: state => state.folder,
    data: state => state.data,
  },

  mutations: {
    SET_SELECTED_INSTANCE (state, payload) {
      state.selectedInstance = payload
    },
    SET_CURRENT_PAGE_PREVIEWS (state, payload) {
      state.currentPagePreviews = payload
    },
    SET_FOLDERS (state, payload) {
      state.folders = payload
    },
    SET_KEEP_ASPECT_RATIO (state, payload) {
      state.keepAspectRatio = payload
    },
    SET_TARGET (state, payload) {
      state.target = payload
    },
    SET_LAYERS (state, payload) {
      state.layers = payload
    },
    ADD_FOLDER (state, payload) {
      state.folders.push(payload)
    },
    SET_FOLDER (state, payload) {
      state.folder = payload;
    },
    DELETE_LAYER (state, element_id) {
      const index = state.layers.findIndex(item => item.element_id === element_id)
      if (index) {
        state.layers.splice(index, 1)
      }
    }
  },

  actions: {
    async fetchFolders ({ commit }, params = {}) {
      const { data } = await LayoutFolderService.getFolders(params)
      commit('SET_FOLDERS', data.items)
    },
    async createFolder ({ commit }, payload) {
      const { data } = await LayoutFolderService.createFolder(payload.parent_id, payload.name)
      commit('ADD_FOLDER', data)
      return data
    },
    async checkFolderName ({ commit }, payload){
      const { data } = await LayoutFolderService.checkFolderName(payload)
      return data
    },
    async getFolder ({ commit }, payload) {
      const { data } = await LayoutFolderService.getFolder(payload);
      commit('SET_FOLDER', data);
    }
  }
}
