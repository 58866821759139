var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.title } },
                [
                  _c("b-form-select", {
                    staticClass: "form-control",
                    attrs: { options: _vm.availabilityOptions },
                    model: {
                      value: _vm.isEnabled,
                      callback: function ($$v) {
                        _vm.isEnabled = $$v
                      },
                      expression: "isEnabled",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isEnabled
        ? _c(
            "div",
            [
              _c(
                "b-card",
                { attrs: { "bg-variant": "light" } },
                [
                  _c(
                    "b-form-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Link to PDF" } },
                            [
                              _c("b-form-input", {
                                staticClass: "form-control",
                                attrs: {
                                  placeholder: "Enter the link to the PDF",
                                },
                                model: {
                                  value: _vm.pdf,
                                  callback: function ($$v) {
                                    _vm.pdf = $$v
                                  },
                                  expression: "pdf",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }