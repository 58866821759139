var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "simple-table-builder",
            title: "Table Builder",
            centered: "",
            size: "xl",
            "ok-title": "Save",
          },
          on: { ok: _vm.onSave },
        },
        [
          _vm.table && _vm.table.rows.length
            ? _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c(
                    "b-button-group",
                    [
                      _c("b-button", { on: { click: _vm.addRow } }, [
                        _vm._v("\n          Add Row\n        "),
                      ]),
                      _vm._v(" "),
                      _c("b-button", { on: { click: _vm.addColumn } }, [
                        _vm._v("\n          Add Column\n        "),
                      ]),
                      _vm._v(" "),
                      _c("b-button", { on: { click: _vm.removeRow } }, [
                        _vm._v("\n          Remove Row\n        "),
                      ]),
                      _vm._v(" "),
                      _c("b-button", { on: { click: _vm.removeColumn } }, [
                        _vm._v("\n          Remove Column\n        "),
                      ]),
                      _vm._v(" "),
                      _c(
                        "DropdownCounter",
                        {
                          attrs: {
                            value: _vm.getStyleValue("font-size", 0),
                            min: 1,
                            step: 1,
                          },
                          on: {
                            input: (value) =>
                              _vm.onChangeStyleOfTable(
                                "font-size",
                                value,
                                "px"
                              ),
                          },
                        },
                        [_c("i", { staticClass: "cil-text-size" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "DropdownCounter",
                        {
                          attrs: {
                            value: _vm.getStyleValue("border-width", 0),
                            min: 0,
                            step: 1,
                          },
                          on: {
                            input: (value) =>
                              _vm.onChangeStyleOfTable(
                                "border-width",
                                value,
                                "px",
                                true
                              ),
                          },
                        },
                        [_c("i", { staticClass: "cil-border-style" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        [
                          _c("ColorPicker", {
                            attrs: {
                              value: _vm.getStyleValue("color", "#000000"),
                            },
                            on: {
                              input: (value) =>
                                _vm.onChangeStyleOfTable("color", value),
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-items-baseline p-2 w-10 t-input",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "mr-1",
                              attrs: { for: "cell-value" },
                            },
                            [_vm._v("\n            W:\n          ")]
                          ),
                          _vm._v(" "),
                          _c("b-input", {
                            staticClass: "form-control",
                            attrs: {
                              id: "cell-value",
                              value: _vm.tableWidth,
                              type: "number",
                            },
                            on: {
                              input: (value) => _vm.changeTableWidth(value),
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-items-baseline p-2 w-10 t-input",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "mr-1",
                              attrs: { for: "h-cell-value" },
                            },
                            [_vm._v("\n            H:\n          ")]
                          ),
                          _vm._v(" "),
                          _c("b-input", {
                            staticClass: "form-control",
                            attrs: {
                              id: "cell-value",
                              value: _vm.tableHeight,
                              type: "number",
                              disabled: _vm.autoHeight,
                            },
                            on: {
                              input: (value) => _vm.changeTableHeight(value),
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center p-2" },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: { switch: "" },
                              on: { change: _vm.handleAutoHeightChange },
                              model: {
                                value: _vm.autoHeight,
                                callback: function ($$v) {
                                  _vm.autoHeight = $$v
                                },
                                expression: "autoHeight",
                              },
                            },
                            [
                              _vm._v(
                                "\n            Use auto height\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.selectedCells.length
                    ? _c(
                        "b-form-row",
                        { staticClass: "mt-2" },
                        [
                          _c("b-col", { attrs: { cols: "2" } }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "table-text-area",
                                    attrs: {
                                      label: "Text",
                                      description:
                                        "Press shift + enter to apply changes",
                                    },
                                  },
                                  [
                                    _c("b-form-textarea", {
                                      staticClass: "form-control",
                                      attrs: {
                                        id: "cell-value",
                                        value: _vm.selectedCells[0].value,
                                        type: "text",
                                        rows: "3",
                                      },
                                      on: {
                                        input: (value) =>
                                          (_vm.selectedCells[0].value = value),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            {
                              staticClass: "font-controls",
                              attrs: { cols: "8" },
                            },
                            [
                              _c(
                                "b-form-row",
                                [
                                  _c("b-col", { attrs: { cols: "2" } }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "mb-0 mb-1",
                                        attrs: { for: "font-family" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                Font Family\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "select",
                                      {
                                        staticClass:
                                          "form-control form-control-sm",
                                        attrs: { id: "font-family" },
                                        domProps: { value: _vm.fontFamily },
                                        on: { input: _vm.setFontFamily },
                                      },
                                      _vm._l(
                                        _vm.fontsList,
                                        function (currentFont, index) {
                                          return _c(
                                            "option",
                                            {
                                              key:
                                                (currentFont.index ||
                                                  currentFont.created_at) +
                                                index,
                                              style: {
                                                "font-family":
                                                  currentFont.index,
                                              },
                                              domProps: {
                                                value:
                                                  currentFont.index ||
                                                  currentFont,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    currentFont.name ||
                                                      currentFont.title
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("b-col", { attrs: { cols: "2" } }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "mb-0 mb-1",
                                        attrs: { for: "font-family" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                Aligned\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "select",
                                      {
                                        staticClass:
                                          "form-control form-control-sm",
                                        attrs: { id: "font-family" },
                                        domProps: { value: _vm.align },
                                        on: { input: _vm.setFontAligned },
                                      },
                                      _vm._l(
                                        _vm.textAlignedList,
                                        function (item, index) {
                                          return _c(
                                            "option",
                                            {
                                              key: item + index,
                                              domProps: { value: item },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(item) +
                                                  "\n                "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("b-col", { attrs: { cols: "2" } }, [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "mb-1",
                                            attrs: { for: "cell-value" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  Font Size\n                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("b-input", {
                                          staticClass: "form-control",
                                          attrs: {
                                            id: "cell-value",
                                            type: "number",
                                            size: "sm",
                                          },
                                          on: {
                                            input: (value) =>
                                              _vm.handleChangeCellStyleRule(
                                                "font-size",
                                                value,
                                                "px"
                                              ),
                                          },
                                          model: {
                                            value: _vm.selectedCellTextSize,
                                            callback: function ($$v) {
                                              _vm.selectedCellTextSize = $$v
                                            },
                                            expression: "selectedCellTextSize",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("b-col", { attrs: { cols: "2" } }, [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "mb-1",
                                            attrs: { for: "cell-value" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  Fixed Width\n                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("b-input", {
                                          staticClass: "form-control",
                                          attrs: {
                                            id: "cell-value",
                                            type: "number",
                                            size: "sm",
                                          },
                                          on: {
                                            input: (value) =>
                                              _vm.handleChangeColumnWidth(
                                                "width",
                                                value
                                              ),
                                          },
                                          model: {
                                            value: _vm.selectedCellWidth,
                                            callback: function ($$v) {
                                              _vm.selectedCellWidth = $$v
                                            },
                                            expression: "selectedCellWidth",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("b-col", { attrs: { cols: "2" } }, [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "mb-1",
                                            attrs: { for: "cell-value" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  Min Width\n                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("b-input", {
                                          staticClass: "form-control",
                                          attrs: {
                                            id: "cell-value",
                                            type: "number",
                                            size: "sm",
                                          },
                                          on: {
                                            input: (value) =>
                                              _vm.handleChangeColumnWidth(
                                                "min-width",
                                                value
                                              ),
                                          },
                                          model: {
                                            value: _vm.selectedCellMinWidth,
                                            callback: function ($$v) {
                                              _vm.selectedCellMinWidth = $$v
                                            },
                                            expression: "selectedCellMinWidth",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("b-col", { attrs: { cols: "2" } }, [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "mb-1",
                                            attrs: { for: "cell-height" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  Height\n                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("b-input", {
                                          staticClass: "form-control",
                                          attrs: {
                                            id: "cell-height",
                                            type: "number",
                                            disabled: !_vm.autoHeight,
                                            size: "sm",
                                          },
                                          on: {
                                            input: (value) =>
                                              _vm.changeRowStyles(
                                                "height",
                                                value,
                                                "px"
                                              ),
                                          },
                                          model: {
                                            value: _vm.selectedCellMinHeight,
                                            callback: function ($$v) {
                                              _vm.selectedCellMinHeight = $$v
                                            },
                                            expression: "selectedCellMinHeight",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-row",
                                { staticClass: "align-items-end mt-2" },
                                [
                                  _c("b-col", { attrs: { cols: "0" } }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "mb-0",
                                        attrs: { for: "cell-value" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                Color\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "cpw" },
                                      [
                                        _c("ColorPicker", {
                                          on: {
                                            input: (value) =>
                                              _vm.handleChangeCellStyleRule(
                                                "color",
                                                value,
                                                ""
                                              ),
                                          },
                                          model: {
                                            value: _vm.selectedCellTextColor,
                                            callback: function ($$v) {
                                              _vm.selectedCellTextColor = $$v
                                            },
                                            expression: "selectedCellTextColor",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("b-col", { attrs: { cols: "0" } }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "mb-0",
                                        attrs: { for: "cell-value" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                Cell\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "cpw" },
                                      [
                                        _c("ColorPicker", {
                                          on: {
                                            input: (value) =>
                                              _vm.handleChangeCellStyleRule(
                                                "background-color",
                                                value,
                                                ""
                                              ),
                                          },
                                          model: {
                                            value: _vm.selectedCellBgColor,
                                            callback: function ($$v) {
                                              _vm.selectedCellBgColor = $$v
                                            },
                                            expression: "selectedCellBgColor",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("b-col", { attrs: { cols: "0" } }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "mb-0",
                                        attrs: { for: "cell-value" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                Border\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "cpw" },
                                      [
                                        _c("ColorPicker", {
                                          on: {
                                            input: (value) =>
                                              _vm.changeCellBorders(
                                                "border-color",
                                                value,
                                                ""
                                              ),
                                          },
                                          model: {
                                            value: _vm.selectedCellBorderColor,
                                            callback: function ($$v) {
                                              _vm.selectedCellBorderColor = $$v
                                            },
                                            expression:
                                              "selectedCellBorderColor",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "2" } },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "mb-1",
                                          attrs: { for: "cell-value" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                Border width\n              "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-input", {
                                        staticClass: "form-control",
                                        attrs: {
                                          id: "cell-value",
                                          type: "number",
                                          min: "1",
                                          size: "sm",
                                        },
                                        on: {
                                          input: (value) =>
                                            _vm.changeCellBorders(
                                              "border-width",
                                              value,
                                              "px"
                                            ),
                                        },
                                        model: {
                                          value: _vm.selectedCellBorderWidth,
                                          callback: function ($$v) {
                                            _vm.selectedCellBorderWidth = $$v
                                          },
                                          expression: "selectedCellBorderWidth",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("b-col", { attrs: { cols: "2" } }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "mb-0 mb-1",
                                        attrs: { for: "border-style" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                Style\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "select",
                                      {
                                        staticClass:
                                          "form-control form-control-sm",
                                        attrs: { id: "border-style" },
                                        domProps: { value: _vm.borderStyle },
                                        on: { input: _vm.setBorderStyle },
                                      },
                                      _vm._l(
                                        _vm.borderStyleOptions,
                                        function (item, index) {
                                          return _c(
                                            "option",
                                            {
                                              key: item + index,
                                              domProps: { value: item },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(item) +
                                                  "\n                "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "3" } },
                                    [
                                      _c("b-form-radio-group", {
                                        staticClass: "apply-to-buttons",
                                        attrs: {
                                          options: _vm.applyToBordersOptions,
                                          "button-variant": "outline-secondary",
                                          size: "sm",
                                          buttons: "",
                                        },
                                        model: {
                                          value: _vm.applyToBorders,
                                          callback: function ($$v) {
                                            _vm.applyToBorders = $$v
                                          },
                                          expression: "applyToBorders",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-row",
                                { staticClass: "align-items-center mt-2 px-1" },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "mb-0 mt-1 mr-2" },
                                        [_vm._v("Apply to:")]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-radio-group", {
                                        staticClass: "apply-to-buttons",
                                        attrs: {
                                          options: _vm.applyToOptions,
                                          "button-variant": "outline-secondary",
                                          size: "sm",
                                          buttons: "",
                                        },
                                        model: {
                                          value: _vm.applyToCells,
                                          callback: function ($$v) {
                                            _vm.applyToCells = $$v
                                          },
                                          expression: "applyToCells",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ml-2",
                                      staticStyle: { "font-size": "14px" },
                                    },
                                    [
                                      _vm._v(
                                        "\n              Sum column widths: " +
                                          _vm._s(_vm.setColumnWidthsSum) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            {
                              staticStyle: { padding: "5px" },
                              attrs: { cols: "2" },
                            },
                            [
                              _c(
                                "b-form-row",
                                [
                                  _c("b-col", { attrs: { cols: "12" } }, [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "mb-0 mt-1",
                                            attrs: { for: "cell-value" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  Colspan\n                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("b-input", {
                                          staticClass: "form-control",
                                          attrs: {
                                            id: "cell-value",
                                            value: _vm.selectedCells[0].colspan,
                                            type: "number",
                                            disabled:
                                              _vm.selectedCells.length > 1,
                                            size: "sm",
                                          },
                                          on: {
                                            input: (value) =>
                                              (_vm.selectedCells[0].colspan =
                                                value),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "btn btn-secondary field-btn mt-0 w-100 mt-2",
                                          on: { click: _vm.onCancel },
                                        },
                                        [
                                          _vm._v(
                                            "\n                Cancel\n              "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "btn btn-success field-btn mt-0 w-100 mt-2",
                                          on: { click: _vm.onApply },
                                        },
                                        [
                                          _vm._v(
                                            "\n                Apply\n              "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.table && _vm.table.rows.length
            ? _c(
                "div",
                {
                  ref: "tableContainer",
                  staticClass: "table-builder-container",
                },
                [
                  _c(
                    "table",
                    {
                      ref: "table",
                      staticClass: "no-select",
                      style: _vm.getStyleOfTable,
                    },
                    [
                      _c("thead", [
                        _c(
                          "tr",
                          { style: _vm.table.headerRowStyles.getStyle() },
                          _vm._l(_vm.table.headers, function (column) {
                            return _c(
                              "th",
                              {
                                key: column.id,
                                class: {
                                  "selected-cell": _vm.selectedCells.some(
                                    (item) => item.id === column.id
                                  ),
                                },
                                style: column.stringStyles,
                                attrs: { colspan: column.colspan },
                                on: {
                                  click: (event) =>
                                    _vm.onSelectCell(event, column),
                                },
                              },
                              [_c("span", [_vm._v(_vm._s(column.value))])]
                            )
                          }),
                          0
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.table.rows, function (row) {
                          return _c(
                            "tr",
                            { key: row.id, style: row.stringStyles },
                            _vm._l(row.cells, function (cell) {
                              return _c(
                                "td",
                                {
                                  key: cell.id,
                                  class: {
                                    "selected-cell": _vm.selectedCells.some(
                                      (item) => item.id === cell.id
                                    ),
                                  },
                                  style: cell.stringStyles,
                                  attrs: { colspan: cell.colspan },
                                  on: {
                                    click: (event) =>
                                      _vm.onSelectCell(event, cell),
                                  },
                                },
                                [_c("span", [_vm._v(_vm._s(cell.value))])]
                              )
                            }),
                            0
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ]
              )
            : _c(
                "div",
                { staticClass: "d-flex justify-content-center" },
                [
                  _c("b-dropdown", { attrs: { text: "Create Table" } }, [
                    _c(
                      "div",
                      { staticClass: "p-2" },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("label", [_vm._v("Columns")]),
                                _vm._v(" "),
                                _c("b-form-input", {
                                  attrs: { type: "number", size: "sm" },
                                  model: {
                                    value: _vm.createTable.columns,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.createTable,
                                        "columns",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "createTable.columns",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "mx-2" }),
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              [
                                _c("label", [_vm._v("Rows")]),
                                _vm._v(" "),
                                _c("b-form-input", {
                                  attrs: { type: "number", size: "sm" },
                                  model: {
                                    value: _vm.createTable.rows,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.createTable,
                                        "rows",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "createTable.rows",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            staticClass: "w-100",
                            attrs: { variant: "primary", size: "sm" },
                            on: { click: _vm.onCreateTable },
                          },
                          [_vm._v("\n            Create\n          ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }