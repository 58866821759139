import { fabric } from "fabric";
import Observer from "../../frontend/services/helpers/Observer";
import { parseJsonData } from "../../frontend/services/helpers";
import { parseStyleToText } from "./parse-style-to-text";

const clipboard = window.navigator.clipboard;
const observer = new Observer();
let scope = {};

export async function copy(canvas) {
    if (!(canvas instanceof fabric.Canvas)) return;

    const activeObject = canvas.getActiveObject();

    if (!activeObject || !activeObject?.hasControls) return;

    let objectData = activeObject.toObject();

    objectData = {
        ...objectData,
        name: activeObject?.templateField?.name || activeObject?.name || null,
        fieldType: activeObject?.templateField?.type || activeObject?.fieldType || null,
        fontColor: activeObject?.fontColor || '#000000',
        fontSize: (activeObject?.fontSize * activeObject?.scaleX) || 100,
        fileName: activeObject?.fileName || null,
        id: activeObject.id,
        order: activeObject.order,
    }

    if (activeObject.type === 'textbox') {
        objectData.text = parseStyleToText(activeObject?.textLines, activeObject?.styles);
    }
    
    await clipboard.writeText(
        JSON.stringify(objectData)
    );
}

async function paste(nativeEvent) {
    const files = await Promise.all([...nativeEvent.clipboardData.files]);
    const data = await clipboard.readText();
    const parseData = parseJsonData(data, null);
    let eventData = {
        text: data,
        object: parseData,
        dataFromClipboard: data,
        files
    };

    observer.dispatch('paste', eventData);
}


function init() {
    document.addEventListener('copy', async function (e) {
        switch (e.target?.tagName) {
            case 'INPUT':
            case 'TEXTAREA':
                return
        }
        copy(scope.canvas);
    });

    document.addEventListener('paste', async function (e) {
        paste(e)
    });
}

function setScope(data) {
    scope = { ...scope, ...data };
}

export default {
    init,
    setScope,
    on: observer.on.bind(observer)
}