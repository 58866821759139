var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: { size: "xl", centered: "" },
      on: { hidden: _vm.handleModalClose },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function () {
            return [_vm._v("\n    Folders\n  ")]
          },
          proxy: true,
        },
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "ButtonPrimary",
                {
                  attrs: {
                    variant: "primary",
                    disabled: _vm.isConfirmDisabled,
                  },
                  on: { click: _vm.confirm },
                },
                [_vm._v("\n      Accept\n    ")]
              ),
              _vm._v(" "),
              _c(
                "ButtonOutline",
                {
                  attrs: { variant: "outline-primary" },
                  on: {
                    click: function ($event) {
                      _vm.isModalOpened = false
                    },
                  },
                },
                [_vm._v("\n      Cancel\n    ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isModalOpened,
        callback: function ($$v) {
          _vm.isModalOpened = $$v
        },
        expression: "isModalOpened",
      },
    },
    [
      _vm._v(" "),
      _c("div", { staticClass: "d-flex actions pl-2" }, [
        _c(
          "div",
          {
            staticClass: "action-btn",
            class: { active: _vm.type === "Move" },
            on: {
              click: function ($event) {
                return _vm.setType("Move")
              },
            },
          },
          [_c("span", [_vm._v("Add to folder")])]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "action-btn",
            class: { active: _vm.type === "Link" },
            on: {
              click: function ($event) {
                return _vm.setType("Link")
              },
            },
          },
          [_c("span", [_vm._v("Link to folder")])]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "action-btn",
            class: { active: _vm.type === "Copy" },
            on: {
              click: function ($event) {
                return _vm.setType("Copy")
              },
            },
          },
          [_c("span", [_vm._v("Copy to folder")])]
        ),
      ]),
      _vm._v(" "),
      _c("layout-folder-browser"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }