export const APPLY_TO_TEXT = {
  SELECTED: 'Selected',
  ALL: 'All',
  HEADER: 'Header',
  BODY: 'Body',
  COLUMN: 'Current column',
  ALTERNATING_ROWS: 'Alternating rows',
};

export const APPLY_TO_VALUES = {
  SELECTED: 'selected',
  ALL: 'all',
  HEADER: 'header',
  BODY: 'body',
  COLUMN: 'column',
  ALTERNATING_ROWS: 'alternating-rows'
};

export const BORDER_STYLE_OPTIONS = [
  'none',
  'solid',
  'dashed'
]

export const APPLY_TO_BORDERS_LABEL = {
  ALL: 'All',
  OUTER: 'Outer',
  INNER: 'Inner',
  LEFT: 'Left',
  RIGHT: 'Right',
  TOP: 'Top',
  BOTTOM: 'Bottom',
}

export const APPLY_TO_BORDERS_VALUES = {
  ALL: 'all',
  OUTER: 'outer',
  INNER: 'inner',
  LEFT: 'left',
  RIGHT: 'right',
  TOP: 'top',
  BOTTOM: 'bottom',
}

export const APPLY_TO_BORDERS_HANDLERS = {
  [APPLY_TO_BORDERS_VALUES.ALL]: 'setAllBorders',
  [APPLY_TO_BORDERS_VALUES.OUTER]: 'setOuterBorders',
  [APPLY_TO_BORDERS_VALUES.INNER]: 'setInnerBorders',
  [APPLY_TO_BORDERS_VALUES.LEFT]: 'setOneBorder',
  [APPLY_TO_BORDERS_VALUES.RIGHT]: 'setOneBorder',
  [APPLY_TO_BORDERS_VALUES.TOP]: 'setOneBorder',
  [APPLY_TO_BORDERS_VALUES.BOTTOM]: 'setOneBorder',
}

export const ALLOWED_STYLES_TO_APPLY_TO_ALL = [
  'font-size',
  'color',
  'font-family',
  'text-align',
  'font-family',
  'background-color',
  'border-color',
  'width',
  'min-width',
];

export const BORDER_STYLE_RULES = {
  BORDER_COLOR: 'border-color',
  BORDER_STYLE: 'border-style',
  BORDER_WIDTH: 'border-width',
}

export const BORDERS = {
  LEFT: 'left',
  RIGHT: 'right',
  TOP: 'top',
  BOTTOM: 'bottom',
}

const BORDER_COLOR_BORDERS = {
  [BORDERS.LEFT]: 'border-left-color',
  [BORDERS.RIGHT]: 'border-right-color',
  [BORDERS.TOP]: 'border-top-color',
  [BORDERS.BOTTOM]: 'border-bottom-color',
}

const BORDER_STYLE_BORDERS = {
  [BORDERS.LEFT]: 'border-left-style',
  [BORDERS.RIGHT]: 'border-right-style',
  [BORDERS.TOP]: 'border-top-style',
  [BORDERS.BOTTOM]: 'border-bottom-style',
}

const BORDER_WIDTH_BORDERS = {
  [BORDERS.LEFT]: 'border-left-width',
  [BORDERS.RIGHT]: 'border-right-width',
  [BORDERS.TOP]: 'border-top-width',
  [BORDERS.BOTTOM]: 'border-bottom-width',
}

export const BORDER_STYLES = {
  [BORDER_STYLE_RULES.BORDER_COLOR]: BORDER_COLOR_BORDERS,
  [BORDER_STYLE_RULES.BORDER_STYLE]: BORDER_STYLE_BORDERS,
  [BORDER_STYLE_RULES.BORDER_WIDTH]: BORDER_WIDTH_BORDERS
}

export const CELL_STYLE_KEYS = [
  'background-color',
  'color',
  'font-size',
  'text-align',
  'font-family',
  'width',
  'min-width',
];

export const ROW_STYLE_KEYS = ['height'];

export const CELL_BORDER_STYLE_KEYS = [
  'border-color',
  'border-width',
  'border-style',
];
