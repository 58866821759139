<template>
  <b-modal
    ref="productImageRemoveBGModal"
    title="Remove Background Options"
    centered
    @hide="closeHandler"
  >
    <template #modal-footer>
      <b-button
        variant="outline-primary"
        @click="replaceExistingImage"
      >
        Replace Existing
      </b-button>

      <b-button
        variant="outline-primary"
        @click="copyAndReplaceImage"
      >
        Copy and Replace
      </b-button>

      <b-button
        variant="outline-secondary"
        @click="closeHandler"
      >
        Close
      </b-button>
    </template>

    <div class="text-center">
      <p>How would you like to handle the background removal?</p>
    </div>

    <div class="mt-2 mb-2">
      <b-form-checkbox
        v-model="isCropToImage"
        :value="true"
        :unchecked-value="false"
      >
        Crop to image
      </b-form-checkbox>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ProductImageRemoveBGModal",
  data() {
    return {
      isCropToImage: false,
    };
  },
  methods: {
    show() {
      this.$refs.productImageRemoveBGModal.show();
    },
    closeHandler() {
      this.$refs.productImageRemoveBGModal.hide();
    },
    async replaceExistingImage() {
      const confirm = await Swal.fire({
        title: "Are you sure?",
        text: "This will take effect immediately. You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, replace it!",
      });

      if (!confirm.isConfirmed) {
        return;
      }

      this.$emit("replaceExistingImage", this.isCropToImage);
      this.closeHandler();
    },
    copyAndReplaceImage() {
      this.$emit("copyAndReplaceImage", this.isCropToImage);
      this.closeHandler();
    },
  },
};
</script>
