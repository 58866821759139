<template>
  <div :class="rootClass">
    <div
      v-if="thumbnailPath"
      class="thumbnail-container content"
    >
      <div
        v-if="isLoading"
        class="spinner-container"
      >
        <b-spinner
          variant="secondary"
          type="grow"
          label="Spinning"
        />
      </div>
      <a
        v-show="!isLoading"
        v-b-tooltip.hover.viewport.noninteractive="'Download'"
        class="download-link"
        :href="item.full_url"
        download
        @click.stop
      >
        <b-icon icon="download" />
      </a>
      <img
        v-show="!isLoading"
        :src="thumbnailPath"
        alt="product-thumbnail"
        onError="this.src='/images/image.svg'"
        @load="onLoad"
      >
    </div>
    <div
      v-if="!thumbnailPath"
      class="d-flex justify-content-center align-items-center w-100 h-100 content"
      v-html="drawFileSvg(item)"
    />
  </div>
</template>

<script>
import {drawFileSvg} from '@frontend/utils';
import {TYPE} from '@frontend/constants/file-image-types';

export default {
  name: "ItemView",
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      isLoading: true
    }
  },
  computed: {
    rootClass() {
      return this.item.type === TYPE.FOLDER ? 'folder' : 'image';
    },
    thumbnailPath() {
      return this.item.thumbnail_path || this.item.url || this.item.path;
    }
  },
  methods: {
    drawFileSvg: drawFileSvg,
    onLoad() {
      this.isLoading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.thumbnail-container {
  display: flex;

  img {
    flex-grow: 1;
    object-fit: contain;
  }
}

.c-grid-view-item__preview {
  .download-link {
    display: none;
    position: absolute;
    top: .25rem;
    left: .5rem;

    :hover > & {
      display: block;
    }
  }
}

.c-file-browser {
  .content {
    img, svg {
      max-width: 175px;
      max-height: 80px;
    }

    svg {
      width: 30px;
    }
  }

  .image {
    width: 100%;

    svg {
      width: auto;
    }
  }

  .spinner-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .folder {
    .content {
      width: 100% !important;
      margin-right: 5px;

      ::v-deep img, ::v-deep svg {
        height: 30px;
      }
    }
  }
}
</style>
