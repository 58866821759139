<template>
  <div>
    <b-modal
      v-model="open"
      title="Columns"
    >
      <div class="modal-body">
        <div class="columns-list sortable-list position-relative">
          <div
            v-for="(option, index) in checkboxOptions"
            :key="index"
            class="form-group d-flex item"
          >
            <div class="custom-control custom-checkbox">
              <input
                :id="option.id"
                v-model="selectedOptions"
                type="checkbox"
                :value="option.value"
                class="custom-control-input new_project_input"
              >
              <label
                class="custom-control-label"
                :for="option.id"
              >
                {{ option.label }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          @click="close"
        >
          Close
        </button>
        <button
          type="submit"
          class="btn btn-primary update-data"
          @click="updateData"
        >
          Update
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { catalogManagementEventBroker } from "@frontend/group/modules/catalog-management/event-broker";
import axios from 'axios';

export default {
  name: 'CatalogsColumnsModal',
  data: () => ({
    open: false,
    selectedOptions: [],
    checkboxOptions: [
      { id: '1', label: 'ID', value: 'id' },
      { id: '2', label: 'Name', value: 'name' },
      { id: '3', label: 'Created On', value: 'created_on' },
      { id: '4', label: 'Created By', value: 'created_by' },
      { id: '5', label: 'Last Updated', value: 'last_updated' },
      { id: '6', label: 'Updated By', value: 'updated_by' },
      { id: '7', label: 'Actions', value: 'actions' },
    ]
  }),
  mounted() {
    axios.get('/catalogs/columns').then((response) => {
      this.selectedOptions = response.data.columns;
    });

    catalogManagementEventBroker.on('catalogsColumnsModal', this.init.bind(this));
  },
  methods: {
    init() {
      this.open = true;
    },
    close() {
      this.open = false;
    },
    updateData() {
        axios.post('/catalogs/columns', { selectedColumns: this.selectedOptions })
          .then(response => {
            this.open = false;
            window.location.reload();
          })
          .catch(error => {
            toastr.error(error.message);
          });
    }
  }
}
</script>

<style scoped lang="scss">
</style>
