var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ExportAddPagesConfig", {
        attrs: { title: "Prepend PDF", config: _vm.prepend },
        on: {
          "data-updated": function ($event) {
            _vm.prepend = $event
          },
        },
      }),
      _vm._v(" "),
      _c("ExportAddPagesConfig", {
        attrs: { title: "Append PDF", config: _vm.append },
        on: {
          "data-updated": function ($event) {
            _vm.append = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }