<template>
  <Multiselect
    v-model="fontVal"
    placeholder="Font"
    track-by="value"
    label="name"
    :options="fonts"
    :allow-empty="false"
  >
    <template #option="props">
      <span :style="{ fontFamily: props.option.value }">{{ props.option.name }}</span>
    </template>
    <template #singleLabel="props">
      <span :style="{ fontFamily: props.option.value }">{{ props.option.name }}</span>
    </template>
  </Multiselect>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  components: {Multiselect},
  props: {
    value: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      fonts: []
    }
  },
  computed: {
    fontVal: {
      get () {
        return _.find(this.fonts, ['value', this.value])
      },
      set (selectedOption) {
        this.$emit('input', selectedOption.value);
      },
    },
  },
  mounted() {
    this.fonts = this.getFonts();
  },
  methods: {
    getFonts () {
      const fonts = []

      const fontsList = fontFamilyManager.getFontList() || {};

      for (const key in fontsList) {
        const font = fontsList[key]
        const selectedFontFamily = this.value

        if (Array.isArray(font)) {
          fonts.push({
            name: key,
            value: font.includes(selectedFontFamily) ? selectedFontFamily : font[0],
          })
        } else {
          fonts.push({
            name: font,
            value: key,
          })
        }
      }

      return fonts
    },
  }
}
</script>
