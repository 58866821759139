var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        { attrs: { label: "Fill", "label-cols": "4" } },
        [
          _c("ColorPicker", {
            attrs: { value: _vm.target.fill, themes: _vm.themes, copy: "" },
            on: {
              input: function ($event) {
                return _vm.onChange("bgColor", $event)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("b-form-group", { attrs: { label: "Border", "label-cols": "4" } }, [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("ColorPicker", {
              attrs: { value: _vm.target.stroke, themes: _vm.themes, copy: "" },
              on: {
                input: function ($event) {
                  return _vm.onChange("strokeColor", $event)
                },
              },
            }),
            _vm._v(" "),
            _c(
              "b-button",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true },
                  },
                ],
                attrs: {
                  variant: "primary",
                  size: "sm",
                  title: "Use background color",
                },
                on: {
                  click: function ($event) {
                    return _vm.onChange("strokeColor", _vm.target.fill)
                  },
                },
              },
              [_c("b-icon", { attrs: { icon: "back" } })],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Border width", disabled: _vm.target.locked } },
        [
          _c(
            "b-input-group",
            {
              scopedSlots: _vm._u([
                {
                  key: "append",
                  fn: function () {
                    return [
                      _c("b-input", {
                        attrs: {
                          value: _vm.target.strokeWidth,
                          min: 0,
                          max: _vm.maxStrokeWidth,
                          type: "number",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.onChange("thickness", $event)
                          },
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("b-input", {
                attrs: {
                  value: _vm.target.strokeWidth,
                  min: 0,
                  max: _vm.maxStrokeWidth,
                  type: "range",
                },
                on: {
                  input: function ($event) {
                    return _vm.onChange("thickness", $event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.target.fieldType !== _vm.FIELD_TYPE_SAFE_ZONE
        ? _c(
            "b-form-group",
            { attrs: { label: "Rounding" } },
            [
              _c(
                "b-input-group",
                {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "append",
                        fn: function () {
                          return [
                            _c("b-input", {
                              attrs: {
                                value: _vm.target.rx,
                                min: 0,
                                max: _vm.maxRounding,
                                type: "number",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.onChange("radius", $event)
                                },
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    3262683064
                  ),
                },
                [
                  _c("b-input", {
                    attrs: {
                      value: _vm.target.rx,
                      min: 0,
                      max: _vm.maxRounding,
                      type: "range",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.onChange("radius", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Opacity" } },
        [
          _c(
            "b-input-group",
            {
              scopedSlots: _vm._u([
                {
                  key: "append",
                  fn: function () {
                    return [
                      _c("b-input", {
                        attrs: {
                          value: _vm.target.opacity,
                          min: 0,
                          max: _vm.maxOpacity,
                          step: "0.01",
                          type: "number",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.onChange("opacity", $event)
                          },
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("b-input", {
                attrs: {
                  value: _vm.target.opacity,
                  min: 0,
                  max: _vm.maxOpacity,
                  step: "0.01",
                  type: "range",
                },
                on: {
                  input: function ($event) {
                    return _vm.onChange("opacity", $event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Fill opacity" } },
        [
          _c(
            "b-input-group",
            {
              scopedSlots: _vm._u([
                {
                  key: "append",
                  fn: function () {
                    return [
                      _c("b-input", {
                        attrs: { min: 0, max: 1, step: "0.01", type: "number" },
                        on: { input: _vm.onChangeFillOpacity },
                        model: {
                          value: _vm.fillOpacity,
                          callback: function ($$v) {
                            _vm.fillOpacity = $$v
                          },
                          expression: "fillOpacity",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("b-input", {
                attrs: { min: 0, max: 1, step: "0.01", type: "range" },
                on: { input: _vm.onChangeFillOpacity },
                model: {
                  value: _vm.fillOpacity,
                  callback: function ($$v) {
                    _vm.fillOpacity = $$v
                  },
                  expression: "fillOpacity",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Border opacity" } },
        [
          _c(
            "b-input-group",
            {
              scopedSlots: _vm._u([
                {
                  key: "append",
                  fn: function () {
                    return [
                      _c("b-input", {
                        attrs: {
                          min: 0,
                          max: _vm.maxOpacity,
                          step: "0.01",
                          type: "number",
                        },
                        on: { input: _vm.onChangeStrokeOpacity },
                        model: {
                          value: _vm.strokeOpacity,
                          callback: function ($$v) {
                            _vm.strokeOpacity = $$v
                          },
                          expression: "strokeOpacity",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("b-input", {
                attrs: {
                  min: 0,
                  max: _vm.maxOpacity,
                  step: "0.01",
                  type: "range",
                },
                on: { input: _vm.onChangeStrokeOpacity },
                model: {
                  value: _vm.strokeOpacity,
                  callback: function ($$v) {
                    _vm.strokeOpacity = $$v
                  },
                  expression: "strokeOpacity",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.target.fieldType === _vm.FIELD_TYPE_SAFE_ZONE
        ? [
            _c("PositionAndSize", {
              attrs: {
                w: _vm.target.width * _vm.target.scaleX,
                h: _vm.target.height * _vm.target.scaleY,
                x: _vm.target.left,
                y: _vm.target.top,
              },
              on: { input: _vm.onChange },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Apply to all" } },
        [
          _c("b-form-checkbox-group", {
            attrs: { options: _vm.applyToAllOptions },
            on: { change: _vm.applyToAll },
            model: {
              value: _vm.selectedPropertyKeys,
              callback: function ($$v) {
                _vm.selectedPropertyKeys = $$v
              },
              expression: "selectedPropertyKeys",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.isNonDeletable
        ? _c(
            "b-button",
            {
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.onChange("delete")
                },
              },
            },
            [
              _c("b-icon", { attrs: { icon: "trash" } }),
              _vm._v("\n    Delete\n  "),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("LockToggle"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }