import {FIELD_TYPE_PRODUCT_IMAGE} from "@frontend/constants/type-fields-of-template";
import {
  productImageNameFormatter
} from "@frontend/store/psd-layers/features/field-name-formatter/formatters/product-image";

/**
 * @type {{ [key: string]: FieldNameFormatter }}
 */
const LAYER_NAME_FORMATTERS = {
  [FIELD_TYPE_PRODUCT_IMAGE]: productImageNameFormatter,
}

/**
 * @param {{ fieldName: string, fieldType: string }} params
 * @returns {string}
 */
export const getFormattedFieldName = ({ fieldName, fieldType }) => {
  const formatter = LAYER_NAME_FORMATTERS[fieldType];

  if (!formatter) {
    return fieldName;
  }

  return formatter({
    originalName: fieldName,
  });
}
