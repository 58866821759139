var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container-fluid mt-2" },
    [
      _vm._l(_vm.units, function (adUnit) {
        return _c(
          "div",
          { key: adUnit, staticClass: "row d-flex align-items-center m-3" },
          [
            _c("span", { staticClass: "label m-0" }, [
              _c("p", { staticClass: "badge badge-success m-0" }, [
                _vm._v(_vm._s(_vm.adUnitTypes[adUnit])),
              ]),
            ]),
            _vm._v(" "),
            adUnit !== "skylineApp"
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-sm mx-2",
                    on: {
                      click: function ($event) {
                        return _vm.selectImage(adUnit)
                      },
                    },
                  },
                  [
                    _c("span", [
                      _vm.selectedImages[adUnit] &&
                      _vm.selectedImages[adUnit].selected
                        ? _c("i", { staticClass: "fas fa-check mr-2" })
                        : _vm._e(),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.selectedImages[adUnit] &&
                              _vm.selectedImages[adUnit].selected
                              ? "Image Selected"
                              : "Select Image"
                          ) +
                          "\n      "
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-info btn-sm",
                on: {
                  click: function ($event) {
                    return _vm.selectLogo(adUnit)
                  },
                },
              },
              [
                _vm.selectedLogos[adUnit] && _vm.selectedLogos[adUnit].selected
                  ? _c("i", { staticClass: "fas fa-check mr-2" })
                  : _vm._e(),
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.selectedLogos[adUnit] &&
                        _vm.selectedLogos[adUnit].selected
                        ? "Logo Selected"
                        : "Select Logo"
                    ) +
                    "\n    "
                ),
              ]
            ),
            _vm._v(" "),
            adUnit === _vm.units[0] && _vm.checkGalleryDesktopAssets()
              ? _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-center mt-2",
                  },
                  [
                    _c("span", [
                      _vm._v(
                        "If you don't select others, these files will be used for all Ad Units."
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
          ]
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("AssetModal", {
            ref: "assetModal",
            attrs: { "ad-unit": _vm.selectedAdUnit },
            on: {
              "close-modal": _vm.closeModal,
              "asset-selected": _vm.handleAssetSelection,
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }