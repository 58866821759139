<template>
  <div>
    <FormGroupCharLimit
      label="Text"
      :value="text"
      :max="maxChars"
    >
      <template #default="{ state }">
        <b-textarea
          v-if="isMultiLineField"
          :value="text"
          :state="state"
          max-rows="8"
          @input="onChange('inputText', $event)"
        />
        <b-input
          v-else
          :value="text"
          :state="state"
          @input="onChange('inputText', $event)"
        />
        <div
          v-if="isMultiLineField && options.hasSmartObject"
          class="d-flex align-items-baseline"
        >
          <b-button
            class="mt-1 btn-sm w-60"
            variant="primary"
            @click="redraw()"
          >
            Align to <strong>{{ target.templateField.options.option1 }}</strong>
          </b-button>

          <b-checkbox
            v-show="false"
            class="ml-1 w-40"
            :checked="getAutoPositionValue(target)"
            @change="onChangeAutoPositionValue($event)"
          >
            Auto position
          </b-checkbox>
        </div>
      </template>
    </FormGroupCharLimit>

    <PositionAndSize
      :x="target.left"
      :y="target.top"
      :w="config.width"
      :h="config.height"
      @input="onChange"
    />

    <b-form-group
      v-if="options.fontSelector"
      label="Font"
    >
      <Multiselect
        v-model="fontVal"
        placeholder="Font"
        track-by="value"
        label="name"
        :options="getFonts"
        :allow-empty="false"
      >
        <template #option="props">
          <span :style="{ fontFamily: props.option.value }">{{ props.option.name }}</span>
        </template>
        <template #singleLabel="props">
          <span :style="{ fontFamily: props.option.value }">{{ props.option.name }}</span>
        </template>
      </Multiselect>
    </b-form-group>

    <b-form-group label="Alignment">
      <b-form-select
        :value="target.textAlign"
        :options="alignments"
        @change="onChange('textAlignment', $event)"
      />
    </b-form-group>

    <b-form-row>
      <b-col>
        <b-form-group
          label="Size"
          :disabled="target.locked"
        >
          <b-form-input
            :value="target.fontSize"
            type="number"
            @input="onChange('fontSize', $event)"
            @dblclick="selectValueOnDblclick"
          />
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Opacity">
          <b-form-input
            :value="target.opacity"
            type="number"
            min="0"
            step="0.01"
            max="1"
            @input="onChange('opacity', $event)"
            @dblclick="selectValueOnDblclick"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group
          label="Related field"
        >
          <b-form-select
            :value="target.relatedField?.field"
            :options="relatedTemplateFields"
            @change="onChange('changeRelatedField', $event)"
          />
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Margin">
          <b-form-input
            :value="target.relatedField?.margin"
            type="number"
            min="0"
            step="1"
            @input="onChange('changeMargin', $event)"
            @dblclick="selectValueOnDblclick"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <div
      class="d-flex my-2"
      style="grid-gap: 1rem;"
    >
      <ColorPicker
        v-if="options.colorSelector"
        :value="target.fontColor"
        :themes="themes"
        position="top-start"
        copy
        @input="onChange('fontColor', $event)"
      />

      <DropdownCounter
        :value="target.charSpacing"
        title="Text Spacing"
        :step="10"
        @input="onChange('textSpacing', $event)"
      >
        <i class="glyphicon glyphicon-text-width" />
      </DropdownCounter>

      <div
        v-if="fontFamilyWeights.length"
        class="dropdown"
      >
        <button
          aria-expanded="false"
          aria-haspopup="true"
          class="dropdown-toggle d-flex align-items-center h-100"
          data-toggle="dropdown"
          title="Tracking"
          type="button"
        >
          <i class="glyphicon glyphicon glyphicon-bold" />
        </button>
        <div class="dropdown-menu menu-wide p-0 w-100">
          <button
            v-for="(font, index) in fontFamilyWeights"
            :key="index"
            :style="{ 'font-family': font.index }"
            class="dropdown-item p-2"
            type="button"
            @click="onChangeTextWeight(font.index)"
          >
            {{ TEXT_WEIGHT[font.weight] }}
          </button>
        </div>
      </div>
      <LineHeightDropdown
        :value="target.lineHeight"
        @input="onChange('textLeading', $event)"
      >
        <i class="cil-line-spacing" />
      </LineHeightDropdown>
    </div>
    
    <ApplyToAllTextDropdown
      :options="options"
      :target="target"
    />

    <ChatGPTForm
      :value="target.text"
      class="flex-grow-1 d-flex mt-2"
      @change="onChange"
    />

    <b-button
      variant="danger"
      @click="onChange('delete')"
    >
      <b-icon icon="trash" />
      Delete
    </b-button>

    <LockToggle />
  </div>
</template>

<script>
import ColorPicker from './components/ColorPicker.vue'
import {
  FIELD_TYPE_MULTI_LINE_TEXT,
} from '@frontend/constants/type-fields-of-template'
import { TEXT_WEIGHT } from '@frontend/constants/text-weight'
import DropdownCounter from './components/DropdownCounter.vue'
import { parseStyleToText } from '@/common/preview/parse-style-to-text'
import { editorControlEventBroker, EVENTS } from '@frontend/group/modules/editor-control-popup/event-broker'
import LineHeightDropdown from './components/LineHeightDropdown.vue'
import ChatGPTForm from './components/ChatGPTForm.vue'
import Multiselect from 'vue-multiselect'
import PositionAndSize from './components/PositionAndSize.vue'
import LockToggle from '@frontend/components/modules/layouts-page/EditorControl/components/LockToggle.vue'
import mixinInput from '@frontend/components/input-mixins'
import FormGroupCharLimit from '@frontend/components/common/atoms/FormGroupCharLimit.vue'
import ApplyToAllTextDropdown
  from '@frontend/components/modules/layouts-page/EditorControl/components/ApplyToAllTextDropdown.vue';
import { TEXT_ALIGNMENTS } from '@frontend/constants/alignment'
import { mapGetters } from 'vuex';
import { RELATED_FIELD_TYPES } from "@frontend/constants/related-fields";

export default {
  components: {
    ApplyToAllTextDropdown,
    FormGroupCharLimit,
    LockToggle,
    PositionAndSize,
    Multiselect,
    ColorPicker,
    DropdownCounter,
    LineHeightDropdown,
    ChatGPTForm,
  },
  mixins: [mixinInput],
  props: {
    target: {
      type: fabric.Object,
      require: true,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {},
    },
    themes: {
      type: Array,
      default: () => [],
    },
    fieldType: {
      type: String,
      require: true,
      default: '',
    },
  },
  data () {
    return {
      config: {width: 0, height: 0, fontSize: 0},
      TEXT_WEIGHT,
      chatGPTInput: '',
      isPinProperties: false,
      isApplyToAllUpdate: true,
      isApplyToAllAdd: false,
      fontFamily: [],
      alignments: TEXT_ALIGNMENTS,
      fontFamilyWeights: [],
      selectedFontFamily: '',
    }
  },
  computed: {
    ...mapGetters('layout', [
      'selectedInstance',
    ]),
    text () {
      return parseStyleToText(this.target.textLines, this.target.styles)
    },
    fontVal: {
      get () {
        return _.find(this.getFonts, ['value', this.target.fontFamily])
      },
      set (o) {
        this.setFontFamily(o.value)
        this.onChange('fontFamily', o.value)
      },
    },
    isMultiLineField () {
      return [FIELD_TYPE_MULTI_LINE_TEXT].includes(this.fieldType)
    },
    getFonts () {
      const fonts = []

      for (const key in this.options.fonts) {
        const font = this.options.fonts[key]
        const selectedFontFamily = this.target.fontFamily

        if (Array.isArray(font)) {
          fonts.push({
            name: key,
            value: font.includes(selectedFontFamily) ? selectedFontFamily : font[0],
          })
        } else {
          fonts.push({
            name: font,
            value: key,
          })
        }
      }

      return fonts
    },
    maxChars() {
      return this.target.templateField?.options?.maxChars || 10000; 
    },
    relatedTemplateFields() {
      const fields = this.selectedInstance.template.fields
        .filter(field => RELATED_FIELD_TYPES.includes(field.type) && field.name !== this.target.templateField?.name)
        .map(field => field.name);
      
      return [
        { value: '', text: 'Select related field' },
        ...fields
      ];
    }
  },
  mounted() {
    this.config = {
      width: this.target.width,
      height: this.target.height,
      fontSize: this.target.fontSize,
    };

    this.autoPosition = !!this.target?.autoPosition
  },
  methods: {
    onChangeTextWeight (fontFamily) {
      this.onChange('fontFamily', fontFamily)
    },
    setFontFamily (fontFamily) {
      const fontFamilies = fontFamilyManager.getFontsFamily(fontFamily)
      if (fontFamilies) {
        this.fontFamilyWeights = fontFamilies.fonts
      } else {
        this.fontFamilyWeights = []
      }
    },
    onChange (action, value) {
      editorControlEventBroker.fire(EVENTS.TEXT_CHANGED, {
        action,
        value,
      })
    },
    redraw() {
      this.onChange('redrawText', '')
    },
    update({width, height, fontSize}) {
      this.config = {width, height, fontSize};
    },
    getAutoPositionValue(target) {
      if (this.selectedInstance.data.hasOwnProperty(`${target.element_id}_auto_position`)) {
        const value = this.selectedInstance.data[`${target.element_id}_auto_position`];
        return value === 'true' || value === true;
      }

      return false;
    },
    onChangeAutoPositionValue(value) {
      this.onChange('autoPosition', value);
    }
  }
}
</script>

<style scoped lang="scss">

</style>
