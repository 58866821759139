import FileBrowser from "../../services/api/file-browser";
import {SOURCE} from "../../constants/file-image-types";
import {TABS} from "../../components/modules/file-browser/constants";

import router from '../../router/index'
import {SETTINGS_MODAL_FIELDS_NAME} from "../../components/modules/file-browser/modals/settings-modal/fields";
import {
    resolveDeleteItemMethod,
    resolveDownloadMethod,
    resolveMultipleDeleteMethod,
    resolveSource
} from "../../components/modules/file-browser/method-resolvers";
import {downloadFileLink} from "../../components/modules/file-browser/helpers";
import _ from "lodash";
import Requests from "../../services/api/file-browser";
import { prepareProductFileForm, uploadFile, uploadFolderFile } from "../../services/file-uploader";
import {addProcessingProp} from "../../components/modules/file-browser/data-translators";
import {MODALS} from "../../components/modules/file-browser/constants";
import {createModal} from "../../components/modules/file-browser/modals/action-creater";
import {listMapperFactory} from "../../components/modules/file-browser/list-mappers";
import fetchListDTO, {
    fetchListDataBasedActionCreator,
    fetchListRouterBasedActionCreator
} from "../../components/modules/file-browser/dto/fetch-list-dto";
import {EVENTS as PSD_LAYERS_EVENT, psdLayersEventBroker} from "@frontend/group/modules/psd-layers/event-broker";

const CHUNK_SIZE = 5 * 1024 * 1024;

const initState = () => ({
    isListLoading: false,
    items: [],
    progressingItems: [],
    selected: [],
    pagination: {
        pageSize: null,
        pageSizeInvalidFeedback: null,
        pageSizeValidationState: null,
        totalCount: 0,
        lastPage: 1,
    },
    searchQuery: '',
    modal: {
        type: '',
        payload: null,
    },
    compressSettings: {
        isFetching: false,
        isSaving: false,
        data: {
            [SETTINGS_MODAL_FIELDS_NAME.image_compress_max_size_webp]: '',
            [SETTINGS_MODAL_FIELDS_NAME.image_compress_quality]: '',
            [SETTINGS_MODAL_FIELDS_NAME.quality_compress_video]: '',
            [SETTINGS_MODAL_FIELDS_NAME.when_convert_delete_original_file]: 'off'
        },
    },
    isDownloading: false,
    isDuplicates: false,
    isThumbnailGenerating: false,
    stockTags: ['test', 'item', 'icon', 'new'],
    isStockTagsLoading: false,
    breadcrumbs: [],
    isSvgLoading: false,
    showFavorites: false,
    showOnlyFolders: false
})

export const MUTATIONS = {
    SET_ITEMS: 'SET_ITEMS',
    UPDATE_PROGRESS_PERCENTAGE: 'UPDATE_PROGRESS_PERCENTAGE',
    SET_LIST_LOADING: 'SET_LIST_LOADING',
    SET_TOTAL_COUNT: 'SET_TOTAL_COUNT',
    SET_LAST_PAGE: 'SET_LAST_PAGE',
    TOGGLE_SELECTED_ITEMS: 'TOGGLE_SELECTED_ITEMS',
    CLEAR_SELECTION: 'CLEAR_SELECTION',
    SET_MODAL: 'SET_MODAL',
    COMPRESS_SETTINGS: {
        SET_FETCHING: 'COMPRESS_SETTINGS_SET_FETCHING',
        SET_SAVING: 'COMPRESS_SETTINGS_SET_SAVING',
        SET_DATA: 'COMPRESS_SETTINGS_SET_DATA',
        SET_DATA_KEY: 'COMPRESS_SETTINGS_SET_DATA_KEY'
    },
    SET_DOWNLOADING: 'SET_DOWNLOADING',
    SET_IS_DUPLICATES: 'SET_IS_DUPLICATES',
    SET_THUMBNAIL_GENERATING: 'SET_THUMBNAIL_GENERATING',
    UPDATE_THUMBNAIL_BY_ID: 'UPDATE_THUMBNAIL_BY_ID',
    FINISH_PROGRESSING: 'FINISH_PROGRESSING',
    UPDATE_ITEM_NAME: 'UPDATE_ITEM_NAME',
    SET_STOCK_TAGS: 'SET_STOCK_TAGS',
    SET_STOCK_TAGS_LOADING: 'SET_STOCK_TAGS_LOADING',
    SET_BREADCRUMBS: 'SET_BREADCRUMBS',
    SET_IS_SVG_LOADING: 'SET_IS_SVG_LOADING',
    SET_SHOW_ONLY_FOLDERS: 'SET_SHOW_ONLY_FOLDERS',
    SET_PSD_AS_PARSED: 'SET_PSD_AS_PARSED'
}

export const ACTIONS = {
  UPLOAD_PRODUCT_FILES: 'uploadProductFiles',
  CHECK_PRODUCT_FILES: 'checkProductFiles',
  HIDE_MODAL: 'hideModal'
}

export default {
    namespaced: true,
    state: initState(),
    getters: {
        isListLoading: state => state.isListLoading,
        items: state => state.items,
        selected: state => state.selected,
        firstSelectedItem: state => state.selected.length && state.items.find(item => item.id === state.selected[0]),
        selectedItems: state => state.selected.map(selectedId => state.items.find(item => item.id === selectedId)),
        selectedFolders: state => state.items.filter(item => item.type === 'folder' && state.selected.includes(item.id)).map(item => item.id),
        selectedFiles: state => state.items.filter(item => item.type !== 'folder' && state.selected.includes(item.id)).map(item => item.id),
        totalCount: state => state.pagination.totalCount,
        pageSize: state => state.pagination.pageSize,
        pageSizeInvalidFeedback: state => state.pagination.pageSizeInvalidFeedback,
        pageSizeValidationState: state => state.pagination.pageSizeValidationState,
        isSelected: state => item => state.selected.includes(item.id),
        selectedCount: state => state.selected.length,
        isAnySelected: state => !!state.selected.length,
        modal: state => state.modal,
        compressSettingsData: state => state.compressSettings.data,
        isCompressSettingsFetching: state => state.compressSettings.isFetching,
        isCompressSettingsSaving: state => state.compressSettings.isSaving,
        isDownloading: state => state.isDownloading,
        isDuplicates: state => state.isDuplicates,
        isThumbnailGenerating: state => state.isThumbnailGenerating,
        stockTags: state => state.stockTags,
        isStockTagsLoading: state => state.isStockTagsLoading,
        breadcrumbs: state => state.breadcrumbs.map(item => ({...item, text: item.name})),
        isSvgLoading: state => state.isSvgLoading,
        isOneSelected: state => state.selected.map(
          selectedId => state.items.find(item => item.id === selectedId)
        ).length === 1 ,
        showFavorites: state => state.showFavorites,
    },
    mutations: {
        [MUTATIONS.SET_LIST_LOADING](state, payload) {
            state.isListLoading = payload;
        },
        [MUTATIONS.SET_ITEMS](state, payload) {
            state.items = payload.map(addProcessingProp)
        },
        [MUTATIONS.SET_TOTAL_COUNT](state, payload) {
            state.pagination.totalCount = payload
        },
        [MUTATIONS.SET_LAST_PAGE](state, payload) {
            state.pagination.lastPage = payload
        },
        [MUTATIONS.UPDATE_PROGRESS_PERCENTAGE](state, payload) {
            const item = state.items.find(({ id }) => payload.id === id);
            if (!item) {
                return;
            }
            item.percent = payload.percent;
        },
        [MUTATIONS.FINISH_PROGRESSING](state, payload) {
            const item = state.items.find(({ id }) => payload.id === id);
            if (!item) {
                return;
            }
            item.is_processing = 0;
            if (item.thumbnail_path) {
                const url = new URL(item.thumbnail_path)
                url.searchParams.set('file', payload.thumbnail)
                item.thumbnail_path = url.toString()
            }

        },
        [MUTATIONS.TOGGLE_SELECTED_ITEMS](state, id) {
            if (state.selected.includes(id)) {
                state.selected.splice(state.selected.indexOf(id), 1)
            } else {
                state.selected.push(id)
            }
        },
        [MUTATIONS.CLEAR_SELECTION](state) {
            state.selected = [];
        },
        SET_SELECTION (state, payload) {
            state.selected = payload
        },
        [MUTATIONS.SET_MODAL](state, payload) {
            state.modal = payload;
        },
        [MUTATIONS.COMPRESS_SETTINGS.SET_FETCHING](state, payload) {
            state.compressSettings.isFetching = payload;
        },
        [MUTATIONS.COMPRESS_SETTINGS.SET_SAVING](state, payload) {
            state.compressSettings.isSaving = payload;
        },
        [MUTATIONS.COMPRESS_SETTINGS.SET_DATA](state, payload) {
            state.compressSettings.data = payload;
        },
        [MUTATIONS.COMPRESS_SETTINGS.SET_DATA_KEY](state, { key, value }) {
            state.compressSettings.data[key] = value;
        },
        [MUTATIONS.SET_DOWNLOADING](state, payload) {
            state.isDownloading = payload;
        },
        [MUTATIONS.SET_IS_DUPLICATES](state, payload) {
          state.isDuplicates = payload;
        },
        [MUTATIONS.SET_THUMBNAIL_GENERATING](state, payload) {
            state.isThumbnailGenerating = payload;
        },
        [MUTATIONS.UPDATE_THUMBNAIL_BY_ID](state, { id, thumbnail }) {
            const itemIndex = state.items.findIndex(item => item.id === id);
            if (itemIndex < 0) {
                return;
            }
            state.items.splice(itemIndex, 1, { ...state.items[itemIndex], url: thumbnail })
        },
        [MUTATIONS.UPDATE_ITEM_NAME](state, { id, name }) {
            const item = state.items.find(item => item.id === id);

            if (!item) {
                return;
            }

            item.name = name;
        },
        UPDATE_ITEMS (state, updatedItems) {
            updatedItems.forEach(updatedItem => {
                const index = state.items.findIndex(item => item.id === updatedItem.id)
                if (index < 0) {
                    return
                }
                state.items.splice(index, 1, updatedItem)
            })
        },
        [MUTATIONS.SET_STOCK_TAGS](state, payload) {
            state.stockTags = payload;
        },
        [MUTATIONS.SET_STOCK_TAGS_LOADING](state, is) {
            state.isStockTagsLoading = is;
        },
        [MUTATIONS.SET_BREADCRUMBS](state, payload) {
            state.breadcrumbs = payload;
        },
        [MUTATIONS.SET_IS_SVG_LOADING](state, payload) {
            state.isSvgLoading = payload;
        },
        SET_SHOW_FAVORITES (state, payload) {
            state.showFavorites = payload
        },
        [MUTATIONS.SET_SHOW_ONLY_FOLDERS] (state, payload) {
            state.showOnlyFolders = payload
        },
        [MUTATIONS.SET_PSD_AS_PARSED](state, payload) {
            const index = state.items.findIndex(item => item.id === payload.fileId);
            
            if (index) {
              state.items[index].is_psd_parsed = true;
            }
        },
        SET_PAGE_SIZE (state, payload) {
            state.pagination.pageSize = payload
        },
        SET_PAGE_SIZE_VALIDATION (state, payload) {
            state.pagination.pageSizeInvalidFeedback = payload.pageSizeInvalidFeedback
            state.pagination.pageSizeValidationState = payload.pageSizeValidationState
        },
    },
    actions: {
        async fetchList({ commit, state }, dto = fetchListRouterBasedActionCreator(router.currentRoute)) {
            commit(MUTATIONS.SET_LIST_LOADING, true)
            const source = resolveSource(dto)
            const folderId = router.currentRoute.params.pathMatch?.split('/')?.pop() || 0;
            let productFolderId = dto.params.pathMatch?.split('/')?.pop() || null;

            const tab = dto.params.tab;
            const { data } = await FileBrowser.listBySource(source, {
                pageSize: state.pagination.pageSize,
                folder_id: tab === TABS.PRODUCTS ? productFolderId : folderId,
                favorites: state.showFavorites || null,
                showOnlyFolders: state.showOnlyFolders || null,
            })
            const dataMapper = listMapperFactory(dto);
            commit(MUTATIONS.SET_ITEMS, dataMapper(data.items))
            commit(MUTATIONS.SET_TOTAL_COUNT, data.totalCount)
            commit(MUTATIONS.SET_LAST_PAGE, data.last_page)
            commit(MUTATIONS.CLEAR_SELECTION)
            commit(MUTATIONS.SET_LIST_LOADING, false)
        },
        async navigateToUploadedFile({ state, dispatch }) {
            const currentPage = Number(router.currentRoute.query.page);
  
            if (state.pagination.totalCount % state.pagination.pageSize === 0 && state.pagination.lastPage !== 0) {
                await router.push({ query: { ...router.currentRoute.query, page: state.pagination.lastPage + 1 } });
              
            } else if (currentPage !== state.pagination.lastPage && state.pagination.lastPage !== 0) {
                await router.push({ query: { ...router.currentRoute.query, page: state.pagination.lastPage } });
              
            } else {
                await dispatch('fetchList', fetchListDataBasedActionCreator(
                    router.currentRoute.params.tab,
                    router.currentRoute.params.pathMatch,
                    router.currentRoute.query.page,
                    router.currentRoute.query.search,
                    router.currentRoute.query.type,
                ));
            }
        },
        async addFolder({ dispatch, state }, parent_id) {
            await FileBrowser.addFolder(parent_id, '/file/create_folder?tab='+router.currentRoute.params.tab)
            
            // for products all the folders are shown on page 1, so we want to stay there.
            let targetPage = 1

            if (router.currentRoute.params.tab !== TABS.PRODUCTS)
                targetPage = Math.ceil((state.pagination.totalCount + 1) / state.pagination.pageSize)
            
            if (targetPage !== Number(router.currentRoute.query.page)) {
                router.push({ query: { ...router.currentRoute.query, page: targetPage } })
            } else {
                dispatch('fetchList')
            }
        },
        async fetchCompressSettings({ commit }) {
            commit(MUTATIONS.COMPRESS_SETTINGS.SET_FETCHING, true)
            const { data } = await FileBrowser.getSettingsValue();
            commit(MUTATIONS.COMPRESS_SETTINGS.SET_DATA, data)
            commit(MUTATIONS.COMPRESS_SETTINGS.SET_FETCHING, false)
        },
        async saveCompressSettings({ state, commit }) {
            commit(MUTATIONS.COMPRESS_SETTINGS.SET_SAVING, true)
            await FileBrowser.saveSettingsValue(state.compressSettings.data)
            commit(MUTATIONS.COMPRESS_SETTINGS.SET_SAVING, false)
        },
        async deleteSelectedItem({ state, getters, dispatch }) {
            const method = resolveMultipleDeleteMethod(router.currentRoute)

            if (router.currentRoute.params.tab == TABS.PRODUCTS){
                await method(getters.selectedFiles,getters.selectedFolders)
            }else{
                await method(state.selected)
            }
            
            dispatch('fetchList')
        },
        async deleteById({ commit, dispatch }, item) {
            try {

                const method = resolveDeleteItemMethod(router.currentRoute, item)

                let path = item.path;

                if (path && path.includes('file=')) {
                    path = path.split('file=').pop();
                }
                if (router.currentRoute.params.tab == TABS.PRODUCTS){
                    await method(item.id);
                }else{
                    await method(item.id, path);
                }

                dispatch('fetchList')
            } finally {
                commit(MUTATIONS.SET_MODAL, createModal())
            }
        },
        async editFile({ commit }, { id, name, parent_id }) {
            try {
                if (router.currentRoute.params.tab == TABS.PRODUCTS){
                    await Requests.updateFileFolderFile({ id, name, parent_id });
                }else{
                    await Requests.updateFolderFile({ id, name, parent_id })
                }                    
                commit(MUTATIONS.UPDATE_ITEM_NAME, { id, name })
            } catch (error) {
                toastr.error('Something went wrong on updating name')
            } finally {
                commit(MUTATIONS.SET_MODAL, createModal());
            }
        },

        async dropProductFile({ commit }, { id, parent_id, type }) {
            try {
                await Requests.dropFileFolder({ id, parent_id, type });
            } catch (error) {
                console.log (error);
                toastr.error('Something went wrong on updating file')
            } 
        },        
        async download({ commit, state }) {
            commit(MUTATIONS.SET_DOWNLOADING, true);
            try {
                const method = resolveDownloadMethod(router.currentRoute, state.items.filter(item => state.selected.includes(item.id)))
                // TODO implement sharedLink
                const {data} = await method(state.selected)

                if (_.isEmpty(data.url)) {
                    throw new Error('Downloading error')
                }

                downloadFileLink(data.url, data.filename || data.fileName)
            } catch (error) {
                if (error.response.status === 404) {
                    toastr.error(error.response.data.message);
                } else {
                    toastr.error("Download files failed. Try again later.");
                }
            } finally {
                commit(MUTATIONS.SET_DOWNLOADING, false)
            }
        },
        async updateThumbnails({ commit, state }){
            commit(MUTATIONS.SET_THUMBNAIL_GENERATING, true);
            try {
                const { data } = await FileBrowser.updateThumbnails(state.selected)
                if (data.thumbnails) {
                    for (const id in data.thumbnails) {
                        commit(MUTATIONS.UPDATE_THUMBNAIL_BY_ID, { id: Number(id), thumbnail: `${data.thumbnails[id]}&${new Date().getTime()}` })
                    }
                }
            } catch (error) {
                toastr.error('Thumbnail generation failed. Try again later.')
            } finally {
                commit(MUTATIONS.SET_THUMBNAIL_GENERATING, false);
            }
        },
        async parsePSD({ commit, dispatch }, state) {
            commit(MUTATIONS.SET_LIST_LOADING, true)
            let success = false;

            try {
                await Requests.addPSDAssetFolder(state)
                success = true;
                toastr.success(`Upload successfully completed`);
                dispatch('fetchList');
            } catch (error) {
                toastr.error(`Upload failed`);
            } finally {
                psdLayersEventBroker.fire(PSD_LAYERS_EVENT.SAVE_PSD_ASSETS_TO_FOLDER_END, success);
                commit(MUTATIONS.SET_LIST_LOADING, false)
            }
        },

        async uploadFiles({ commit, getters, state, dispatch }, { files, replace, folderId }) {
            const resolvedFolderId = folderId || router.currentRoute.params.pathMatch?.split('/').pop();

            if (!resolvedFolderId) return;

            // TODO: should be third argument isReplace
            const data = (await uploadFolderFile(files, resolvedFolderId, replace)).map(item => ({
                ...item,
                file: files[Number(item.data.key)],
            }));

            await dispatch('navigateToUploadedFile');

            const exists = data.filter(({ isExist, uploadId }) => isExist && !uploadId);

            if (exists.length) {
                commit(MUTATIONS.SET_MODAL, createModal(MODALS.FILE_EXISTS, {
                    files: exists
                }));
            }

            Requests.sendSharedAction({
                id: resolvedFolderId,
                files: files.length,
                action: 'upload',
            });

            data
                .filter(({ isExist, uploadId }) => !isExist || uploadId)
                .forEach(item => dispatch('uploadFile', item));
        },

        async uploadFile({ commit, dispatch }, payload) {
            const size = payload.data.size;
            const { file, ...data } = payload;
            const chunks = Math.ceil(size / CHUNK_SIZE);
            let chunk = 1;

            while (chunk <= chunks) {
                const offset = (chunk - 1) * CHUNK_SIZE;
                const signed_res = await Requests.signPartUpload({ ...data, partNumber: chunk });
                const chunk_file = file.slice(offset, offset + CHUNK_SIZE);
                await uploadFile(signed_res.data.url, chunk_file, percent => {
                    commit(MUTATIONS.UPDATE_PROGRESS_PERCENTAGE, {
                        percent: 100 / chunks * chunk - (1 - percent) * 100 / chunks,
                        id: payload.id
                    });
                });
                chunk++;
            }

            let res;
            let response;

            try {
                res = await Requests.getMultipartUploadedParts(data);
                response = await Requests.completeMultipartUpload({
                    ...data,
                    parts: res.data.parts,
                });
            } catch (e) {
                if (e.response?.data?.message) {
                    toastr.error(e.response.data.message);
                } else {
                    toastr.error(`Uups. Something went wrong!`);
                }
                dispatch('fetchList');
                return;
            }

            commit(MUTATIONS.FINISH_PROGRESSING, { id: payload.id, thumbnail: response.data.thumbnail });

            if (response.data.user_file.psd_data) {
                commit(MUTATIONS.SET_PSD_AS_PARSED, { fileId: response.data.user_file.id });
            }

            toastr.success(`Upload successfully completed`);

            if (response.data.isAutoPSDParsingProcess) {
                toastr.info(`Parsing PSD is in progress, please check your email for the current status`);
            }
        },
        async compress({ commit, getters, dispatch }) {
            if (getters.selectedItems.length === 1) {
                commit(MUTATIONS.SET_MODAL, createModal(MODALS.COMPRESS_LOADER))
                try {
                    await Requests.compressFolderFile(getters.selectedItems[0].id)
                    dispatch('fetchList')
                } catch (error) {
                    toastr.error('Something went wrong while compressing.')
                } finally {
                    commit(MUTATIONS.SET_MODAL, createModal());
                    commit(MUTATIONS.CLEAR_SELECTION);
                }
            } else {
                try {
                    await Requests.compressFolderFileUsingBackgroundJob(getters.selectedItems.map(({ id }) => id))
                    toastr.success(
                        "You will get an email when files are done compressing!"
                    );
                } catch (error) {
                    toastr.error('Something went wrong while compressing.')
                } finally {
                    commit(MUTATIONS.CLEAR_SELECTION);
                }
            }
        },
        async fetchStockTags({ commit }) {
            try {
                commit(MUTATIONS.SET_STOCK_TAGS_LOADING, true)
                const { data } = await Requests.fetchStockTags();
                commit(MUTATIONS.SET_STOCK_TAGS, data);
            } catch (error) {
                toastr.error('Something went wrong while fetching tags.')
            } finally {
                commit(MUTATIONS.SET_STOCK_TAGS_LOADING, false)
            }
        },
        async fetchBreadcrumbs({ commit }){
            if (!router.currentRoute.params.pathMatch) {
                return commit(MUTATIONS.SET_BREADCRUMBS, [])
            }
            try {
                const { data } = await Requests.getBreadcrumbs(router.currentRoute.params.pathMatch.split('/'), router.currentRoute.params.tab);
                commit(MUTATIONS.SET_BREADCRUMBS, data)
            } catch (error) {
                console.log(error)
            }
        },
        clearSelection ({ commit }) {
            commit(MUTATIONS.CLEAR_SELECTION)
        },
        selectAll ({ commit, state }) {
            commit('SET_SELECTION', _.map(_.reject(state.items, ['type', 'folder']), 'id'))
        },
        setIsSvgLoading({ commit }, payload) {
            commit(MUTATIONS.SET_IS_SVG_LOADING, payload);
        },
        
        async duplicateFiles({ commit, dispatch, state }) {
            try {
                commit(MUTATIONS.SET_LIST_LOADING, true);
                
                const { data } = await Requests.duplicateFiles(state.selected);
                
                dispatch('fetchList');
                
                toastr.success(`${data.length > 1 ? 'Files were' : 'File was'} copied successfully!`);
            } catch (error) {
                toastr.error('Something went wrong while duplicating files.');
            } finally {
                commit(MUTATIONS.SET_LIST_LOADING, false);
            }
        },
      
        async uploadProductFiles({ state, dispatch, commit }, payload) {
            try {
                commit(MUTATIONS.SET_LIST_LOADING, true);
                
                const formData = prepareProductFileForm(payload);
    
                const { data } = await FileBrowser.storeProductFiles(formData);
                
                await dispatch('navigateToUploadedFile');
                
                toastr.success(`${data.filenames.length > 1 ? 'Files were' : 'File was'} uploaded successfully!`);
            } catch (error) {
                commit(MUTATIONS.SET_LIST_LOADING, false);
                toastr.error('Something went wrong while uploading files.');
            }
        },

       async hideModal({ state, dispatch, commit }, payload) {
            commit(MUTATIONS.SET_LIST_LOADING, false);
            await dispatch('navigateToUploadedFile');
       },

        async checkProductFiles({ state, dispatch, commit }, payload) {
            try {
                commit(MUTATIONS.SET_LIST_LOADING, true);
      
                const formData = prepareProductFileForm(payload);

                const { data } = await FileBrowser.checkProductFilesExist(formData);
                
                commit(MUTATIONS.SET_IS_DUPLICATES, data.isDuplicates);
            } catch (error) {
                commit(MUTATIONS.SET_LIST_LOADING, false);
                toastr.error('Something went wrong while uploading files.');
            }
        },

        async toggleFavorites ({ commit, state, dispatch }, { action, ids, verbose }) {
            try {
                const { data: { message } } = await FileBrowser[action](ids, verbose)
                toastr.success(message)
                const updatedItems = state.items
                  .filter(item => ids.includes(item.id))
                  .map(item => ({
                      ...item,
                      is_favorite: !item.is_favorite,
                  }))
                commit('UPDATE_ITEMS', updatedItems)

                if (state.showFavorites) {
                    state.items = _.filter(state.items, ['is_favorite', true])
                    commit('SET_ITEMS', state.items)
                }
            } catch (error) {
                toastr.error(error.message)
            } finally {
                dispatch('clearSelection')
            }
        },

        setShowOnlyFolders({ commit }, payload) {
            commit(MUTATIONS.SET_SHOW_ONLY_FOLDERS, payload)
        },

        async fetchPageSize ({ commit }) {
            const { data } = await FileBrowser.itemsPerPage()
            commit('SET_PAGE_SIZE', data)
        },

        async updatePageSize ({ commit, dispatch }, payload) {
            let pageSizeInvalidFeedback = null
            let pageSizeValidationState = null
            try {
                const { data } = await FileBrowser.itemsPerPage(payload)
                commit('SET_PAGE_SIZE', data)
                dispatch('fetchList')
            } catch (error) {
                pageSizeInvalidFeedback = Object.values(error.response.data.errors).flat().at(0)
                pageSizeValidationState = false
            } finally {
                commit('SET_PAGE_SIZE_VALIDATION', { pageSizeInvalidFeedback, pageSizeValidationState })
            }
        },
    }
}
