<template>
  <div
    class="c-grid-view-item"
    :title="getTitle(item)"
    @dragstart="$emit('dragstart', $event)"
    @drop="handleDrop"
    @dragover="handleDragEnter"
    @dragleave="handleDragLeave"
  >
    <div
      v-if="isTooltipVisible"
      class="tooltip"
    >
      {{ tooltip }}
    </div>
    <div
      class="c-grid-view-item__preview"
      :class="{ active: isSelected }"
      @click="$emit('click', item)"
      @dblclick="$emit('dblclick', item)"
    >
      <div
        class="c-grid-view-item__preview--item"
        :class="{ active: isSelected || isDragOver }"
      >
        <PSDPreviewBadge
          v-if="isPsdParsed"
          left="5"
          top="5"
          variant="success"
          title="PSD parsed"
        />
        <b-icon
          v-if="item.is_favorite"
          icon="bookmark-fill"
          class="favorite-icon"
        />
        <ItemView :item="item" />
        <div
          v-if="item.is_processing"
          class="c-grid-view-item__preview--progress w-100"
        >
          <b-progress
            class="a-progressing__bar"
            :value="item.percent"
            variant="success"
            striped
            animated
          />
        </div>
        <div class="c-grid-view-item__preview--overlay">
          <ItemOverlay
            v-if="isAvailableOverlay && item.name !== '...'"
            :item="item"
          />
        </div>
        <div 
          v-if="item.type === 'folder'"
          class="c-grid-view-item__folder_name"
        >
          {{ truncatedName }}
        </div>
      </div>
    </div>
    <div 
      v-if="item.type !== 'folder'"
      class="c-grid-view-item__name"
    >
      {{ truncateMiddle }}
    </div>
  </div>
</template>

<script>
import ItemView from "./ItemView.vue";
import ItemOverlay from "./item-overlays/ItemOverlay.vue";
import { FILE_TYPE, TYPE } from "@frontend/constants/file-image-types";
import PSDPreviewBadge from "@frontend/components/common/atoms/PSDPreviewBadge.vue";

const MAX_STRING_LENGTH = 20;

export default {
  name: "GridItem",
  components: {PSDPreviewBadge, ItemOverlay, ItemView},
  props: {
    item: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isAvailableOverlay: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isDragOver: false,
      tooltip: '',
      isTooltipVisible: false
    };
  },
  computed: {
    truncatedName() {
      if (this.item.name.length > MAX_STRING_LENGTH) {
        return this.item.name.substring(0, MAX_STRING_LENGTH) + '...';
      } else {
        return this.item.name;
      }
    },
    truncateMiddle() {
      const maxSize = 40;
      const extension = this.item.original_path
        ? '.' + this.item.original_path.split('.').pop()
        : '';
      
      if (!this.item.name || this.item.name.length <= maxSize) {
        return this.item.name + extension;
      }
      const startLength = Math.ceil(maxSize / 2) - 1;
      const endLength = Math.floor(maxSize / 2) - 1;
      return this.item.name.slice(0, startLength) + '…' + this.item.name.slice(-endLength) + extension;
    },
    isPsdParsed() {
      return this.item.type === FILE_TYPE.PSD && this.item.is_psd_parsed;
    }
  },
  methods:{
    handleDragEnter(event) {
        if (this.item.type === TYPE.FOLDER){
          if (this.item.is_root) {
            this.isDragOver = false;
            this.isTooltipVisible = false;
          } else {
            this.isDragOver = true;
            this.tooltip = 'Move to ' + this.item.destination_name;
            this.isTooltipVisible = this.item.destination_name ? true : false;
          }
        }
    },
    handleDrop($event) {
      this.$emit('drop', $event);
      this.isTooltipVisible = false;
    },
    handleDragLeave(event) {
        if (this.item.type === TYPE.FOLDER){
          this.isDragOver = false;
          this.tooltip = '';
          this.isTooltipVisible = false;
        }
    },
    getTitle(item) {
      if (item?.tooltip) {
        return item.tooltip
      }

      if (item.name === '...') {
        return 'Go back';
      }else{
        if (this.item.name.length > MAX_STRING_LENGTH) {
           return item.name;
        }

      }
      return '';
    }
  }
}
</script>

<style scoped lang="scss">
  .c-grid-view-item {
    width: 180px;
    height: fit-content;

    &__preview {
      float: inherit;
      border: 1px solid #e1e1e1;
      background: white;
      box-sizing: border-box;
      box-shadow: 0 4px 4px #f8f8f8;
      border-radius: 10px;
      position: relative;
      transition: background-color ease-in-out 0.4s;

      &--item {
        border: 4px solid transparent;
        height: 144px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          border: 4px solid #95d9cc;
        }

        &.active {
          border: 4px solid #209c86;
        }
      }

      &.active, &:hover {
        border: 1px solid transparent;
      }

      &--overlay {
        position: absolute;
        top: 0;
        right: 0;
        display: none;
      }

      &:hover &--overlay {
        display: block;
      }

      &--progress {
        position: absolute;
        left: 0;
        top: calc(50% - 8px);
      }
    }

    &__name {
      transition: ease-in-out;
      font-size: 14px;
      color: #464646;
      text-align: center;

      display: -webkit-box;
      -webkit-line-clamp: 2; /* Limit to 2 lines */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word; /* Allows words to break for better fit */
      max-width: 100%;
    }

    .tooltip {
      position: absolute;
      background-color: #333;
      color: #fff;
      padding: 5px 10px;
      border-radius: 5px;
      opacity: 1;
      transition: opacity 0.3s ease;
    }
  }
  .folder-items .c-grid-view-item {
    &__preview {
      background: #EEEEEE;
      &--item {
        height: 52px;
        justify-content: left;
      }
    }
  }

  .folder-items .c-grid-view-item__folder_name {
    font-size: 0.9em;
    width: 67%;
  }

  .favorite-icon {
    position: absolute;
    font-size: 0.6rem;
    top: 22px;
    left: 16px;
  }
</style>
