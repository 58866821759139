var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-folder-layer__item d-flex align-items-center mt-2" },
    [
      _c("div", { staticClass: "c-folder-layer__item--preview mr-2" }, [
        _c(
          "div",
          { staticClass: "a-text-layer" },
          [
            _c("b-icon", {
              staticClass: "d-block",
              attrs: { icon: "textarea-t" },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover.top.viewport.noninteractive",
              value: {
                title: _vm.node.text,
                disabled: _vm.isNotTruncated(_vm.node.text),
              },
              expression:
                "{ title: node.text, disabled: isNotTruncated(node.text) }",
              modifiers: {
                hover: true,
                top: true,
                viewport: true,
                noninteractive: true,
              },
            },
          ],
          staticClass: "layer--item__text",
        },
        [
          _vm.isLayerEditable
            ? _c("EditableTextElement", {
                attrs: {
                  "default-value": _vm.node.name,
                  disabled: _vm.isSavingNodesChanges,
                  "render-value": _vm.truncateFieldName,
                },
                on: { change: _vm.changeLayerName },
              })
            : _c("span", [
                _vm._v(
                  " " + _vm._s(_vm.truncateFieldName(_vm.node.name)) + " "
                ),
              ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }