var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dropdown-product-images form-row mb-1" }, [
    _c("div", { staticClass: "col-3 d-flex align-items-center" }, [
      _vm._v("\n    Product\n  "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-9" }, [
      _c("div", { staticClass: "input-group" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.productIds,
              expression: "productIds",
            },
          ],
          staticClass: "form-control product-ids-input",
          attrs: { type: "text", disabled: _vm.disabled, readonly: "" },
          domProps: { value: _vm.productIds },
          on: {
            focus: _vm.onFocusProductIds,
            input: function ($event) {
              if ($event.target.composing) return
              _vm.productIds = $event.target.value
            },
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "input-group-append" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline px-2",
              attrs: { disabled: _vm.disabled },
              on: {
                click: function ($event) {
                  return _vm.onFocusProductIds(!_vm.isShowDropdown)
                },
              },
            },
            [
              _c("i", {
                class: {
                  "cil-chevron-bottom": !_vm.isShowDropdown,
                  "cil-chevron-top": _vm.isShowDropdown,
                },
              }),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShowDropdown,
            expression: "isShowDropdown",
          },
        ],
        staticClass: "products-container",
      },
      [
        _c(
          "div",
          { staticClass: "file-browser-wrapper" },
          [_c("FileBrowser")],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "buttons-wrapper" },
          [
            _c("span", { staticClass: "mr-auto" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.selectedCount) + " Selected\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "b-button",
              {
                on: {
                  click: function ($event) {
                    _vm.isShowDropdown = false
                  },
                },
              },
              [_vm._v("\n        Close\n      ")]
            ),
            _vm._v(" "),
            _vm.showReplaceAll
              ? _c(
                  "b-button",
                  {
                    attrs: { variant: "primary" },
                    on: { click: _vm.handleReplaceAll },
                  },
                  [_vm._v("\n        Replace All\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "b-button",
              {
                attrs: { variant: "success" },
                on: { click: _vm.handleImagesSave },
              },
              [_vm._v("\n        Save\n      ")]
            ),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12 my-2" },
      [
        _c("ProductImageUploads", {
          on: { replaceImages: _vm.replaceProductIds },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }