const DEFAULT_PRODUCT_IMAGE_NAME = 'Product Image';

/**
 * @type FieldNameFormatter
 */
export const productImageNameFormatter = ({ originalName }) => {
  const cleanedName = originalName.replace(/ +/g, ' ');

  const match = cleanedName.match(/Product(?: Image)?(?: ?\d+)?/i);

  return match
    ? match[0].replace(/Product(?: Image)?(?: ?(\d+))?/i, (_, number) => `${DEFAULT_PRODUCT_IMAGE_NAME}${number ? ` ${number}` : ''}`)
    : DEFAULT_PRODUCT_IMAGE_NAME;
};