<template>
  <b-modal
    ref="modal"
    v-model="isModalOpened"
    size="xl"
    centered
    @hidden="handleModalClose"
  >
    <template #modal-title>
      Folders
    </template>

    <div class="d-flex actions pl-2">
      <div 
        class="action-btn"
        :class="{ active: type === 'Move' }"
        @click="setType('Move')"
      >
        <span>Add to folder</span>
      </div>
      <div
        class="action-btn"
        :class="{ active: type === 'Link' }"
        @click="setType('Link')"
      >
        <span>Link to folder</span>
      </div>
      <div
        class="action-btn"
        :class="{ active: type === 'Copy' }"
        @click="setType('Copy')"
      >
        <span>Copy to folder</span>
      </div>
    </div>

    <layout-folder-browser />

    <template #modal-footer>
      <ButtonPrimary
        variant="primary"
        :disabled="isConfirmDisabled"
        @click="confirm"
      >
        Accept
      </ButtonPrimary>
      <ButtonOutline
        variant="outline-primary"
        @click="isModalOpened=false"
      >
        Cancel
      </ButtonOutline>
    </template>
  </b-modal>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import ButtonOutline from "@frontend/components/common/atoms/ButtonOutline.vue";
import ButtonPrimary from "@frontend/components/common/atoms/ButtonPrimary.vue";
import {bus} from "@frontend/app";

const { mapActions, mapGetters } = createNamespacedHelpers('layoutFolderBrowser')
export default {
  name: "LayoutFolderBrowserModal",
  components: {ButtonOutline, ButtonPrimary},
  data() {
    return {
      isModalOpened: true,
      type: 'Move',
    }
  },
  computed: {
    ...mapGetters(['isFileBrowserModalOpened', 'selectedFolders', 'selectedCount']),
    isConfirmDisabled() {
      let isDisabled = true;
      
      if (this.selectedFolders.length > 0 && this.selectedFolders.length === this.selectedCount && this.type) {
        isDisabled = false;
      }
      
      return isDisabled;
    }
  },
  watch: {
    isFileBrowserModalOpened: {
      immediate: true,
      handler(newValue) {
        this.isModalOpened = newValue;
      },
    },
    isModalOpened(newValue) {
      this.setIsFileBrowserModalOpened(newValue);
    }
  },
  methods: {
    ...mapActions(['setIsFileBrowserModalOpened', 'disableMultiselect']),
    confirm() {
      bus.$emit('folderActionConfirmed', this.type);
      this.setIsFileBrowserModalOpened(false);
    },
    handleModalClose() {
      this.disableMultiselect();
    },
    setType(newType) {
      this.type = newType;
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .modal-xl {
    max-width: 80%;
  }
  .c-main-view__container {
    min-height: 500px !important;
  }
}
.actions {
  display: flex;
  gap: 10px;

  .action-btn {
    padding: 5px 10px;
    cursor: pointer;
    position: relative;
    transition: color 0.3s ease;

    &.active {
      color: #209c86;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 4px;
      background-color: #209c86;
      transition: width 0.3s ease;
    }

    &:hover::after, &.active::after {
      width: 100%;
    }

    &:hover {
      color: #209c86;
    }
  }
}
</style>
