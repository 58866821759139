<template>
  <b-badge
    class="a-psd-preview-label"
    pill
    :variant="variant"
    :style="{
      left: left + 'px',
      top: top + 'px',
      right: right + 'px',
      bottom: bottom + 'px',
    }"
  >
    {{ title }}
  </b-badge>
</template>

<script>
export default {
  name: "PSDPreviewBadge",
  props: {
    left: {
      type: [Number, String],
      default: () => 'unset'
    },
    top: {
      type: [Number, String],
      default: () => 'unset'
    },
    bottom: {
      type: [Number, String],
      default: () => 'unset'
    },
    right: {
      type: [Number, String],
      default: () => 'unset'
    },
    title: {
      type: [String],
      default: ''
    },
    variant: {
      type: String,
      default: 'success'
    }
  },
}
</script>



<style scoped lang="scss">
.a-psd-preview-label {
  position: absolute;
}
</style>