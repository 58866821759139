var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "productImageRemoveBGModal",
      attrs: { title: "Remove Background Options", centered: "" },
      on: { hide: _vm.closeHandler },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-primary" },
                  on: { click: _vm.replaceExistingImage },
                },
                [_vm._v("\n      Replace Existing\n    ")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-primary" },
                  on: { click: _vm.copyAndReplaceImage },
                },
                [_vm._v("\n      Copy and Replace\n    ")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-secondary" },
                  on: { click: _vm.closeHandler },
                },
                [_vm._v("\n      Close\n    ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("div", { staticClass: "text-center" }, [
        _c("p", [
          _vm._v("How would you like to handle the background removal?"),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-2 mb-2" },
        [
          _c(
            "b-form-checkbox",
            {
              attrs: { value: true, "unchecked-value": false },
              model: {
                value: _vm.isCropToImage,
                callback: function ($$v) {
                  _vm.isCropToImage = $$v
                },
                expression: "isCropToImage",
              },
            },
            [_vm._v("\n      Crop to image\n    ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }