import { ShowPageScopeCollector } from '@frontend/group/modules/scope-collectors/show-page-collector';
import { BACKGROUND_PROPERTIES, resizeBackgroundHandler } from './resize-background-handler';
import { replaceSizeIntoTitleOfTemplate } from '../helpers';
import Template from '../../../../../banner_template/Template';
import { resizeLikeGridHandler } from "@frontend/group/modules/copy-and-resize/handlers/resize-like-grid-handler";
import { getObjectsForOrder } from '@frontend/group/modules/copy-and-resize';
import { FIELD_NAME_IMAGE_AREA } from '@frontend/constants/name-fields-of-template';

const getShowPageScope = () => ShowPageScopeCollector.get([
  'updateLayoutChanges',
  'layoutChanges'
]);

export const TYPE_DIMENSION = {
  HIGH: 'high',
  PROPORTIONAL: 'proportional',
  BROAD: 'broad'
}

const updateDataForTemplate = ({ id, dimension, template }) => {
  const { updateLayoutChanges } = getShowPageScope();
  const { width, height, template: templateDestination } = dimension;
  const data = {
    template_name: templateDestination?.name || replaceSizeIntoTitleOfTemplate(template.name, width, height),
    template_width: width,
    template_height: height
  }

  for (const key in data) {
    updateLayoutChanges(id, key, data[key]);
  }
} 

export const resizeTemplateHandler = (data) => {
    const {
      dimension,
      preview,
      state: {
        objects = getObjectsForOrder(preview.canvas.getObjects()),
        backgroundProperties = { 
          size: BACKGROUND_PROPERTIES.SIZE.COVER,
          position: BACKGROUND_PROPERTIES.POSITION.LEFT_AND_TOP,
          selectedField: objects.find(object => object.name === FIELD_NAME_IMAGE_AREA)?.id ?? null
        },
        isUseDestinationTemplateFields = false
      },
      isCopyObject = false
    } = data;
    const { updateLayoutChanges } = getShowPageScope();
    const { id, data: templateSettings, canvas, template } = preview;
    const allObjects = canvas.getObjects();

    updateDataForTemplate({ id, dimension, template });

    const freshObjects = getObjectsForOrder(preview.canvas.getObjects())

    const _objects = objects
      .filter(object => object.id !== backgroundProperties.selectedField)
      .map(object => freshObjects.find(fresh => fresh.id === object.target.id)?.target)

    resizeLikeGridHandler({
      isUseDestinationTemplateFields,
      templateSettings,
      mainTemplate: new Template(template),
      isCopyObject,
      dimension,
      objects: _objects,
      canvas,
    });
    
    resizeBackgroundHandler({
      isCopyObject,
      dimension,
      object: allObjects.find(object => object.id === backgroundProperties.selectedField),
      backgroundProperties
    });

    canvas.requestRenderAll();

    updateLayoutChanges(preview.id, 'group_fields', '');
}
