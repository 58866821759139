import { fabric } from "fabric";
import { CUSTOM_TEXT_BOX_CLASS_NAME, CustomTextBox } from './nodes/custom-text-box';
import rotateControl from './fabric-rotate-control'
import { initOvalShadowBehind, initOvalShadowInFront} from "@frontend/oval-shadow";
import {IMAGE_PREFIX_ID} from "@frontend/constants/templates-field-prefixes";
import {BEHIND_DISPLAY_MODE, IN_FRONT_DISPLAY_MODE} from "@frontend/constants/oval-shadows";
import {createCanvasResizeControl, createResizeControl} from "@frontend/canvas-resize/create-canvas-resize-controler";

// This function breaks the text editor. Text editor can not edit text for the second time.
// rotateControl()

fabric.Object.prototype.locked = false
Object.defineProperty(fabric.Object.prototype, 'locked', {
  get: function () {
    return this._locked || false
  },
  set: function (value) {
    this._locked = value
    this.lockMovementX = value
    this.lockMovementY = value
    this.lockScalingX = value
    this.lockScalingY = value
    this.lockRotation = value
    this.lockUniScaling = value
    this.editable = !value
  },
})

fabric.Object.prototype.drawCacheOnCanvas = function(ctx) {
  ctx.scale(1 / this.zoomX, 1 / this.zoomY);
  if (this._cacheCanvas.width > 0 && this._cacheCanvas.height > 0) {
    ctx.drawImage(this._cacheCanvas, -this.cacheTranslationX, -this.cacheTranslationY);
  }
}

fabric.Text.prototype.getLineSpacing = function (lineIndex) {
  let lineSpacing = this.lineHeight;
  const prevLineIndex = lineIndex - 1;

  if (prevLineIndex >= 0) {
    lineSpacing = this.getValueOfPropertyAt(prevLineIndex, 0, 'lineSpacing') ?? this.lineHeight;
  }
  return lineSpacing
}


function getJustifyInterCharSpacing(_this, lineIndex, overflowSpecialChars) {
  const line = _this._textLines[lineIndex];
  let totalTextWidth = 0;
  let overflowedCharWidth = 0;

  const overflowableChars = [`,`, `.`, `"`, `'`, '`'];
  for (var i = 0; i < line.length; i++) {
    if (overflowSpecialChars && i === (line.length - 1) && overflowableChars.includes(line[i])) {
      overflowedCharWidth = _this.__charBounds[lineIndex][i].width;
    }
    totalTextWidth += _this.__charBounds[lineIndex][i].width;
  }

  let totalWidth = _this.width - totalTextWidth + overflowedCharWidth;
  return totalWidth / (line.length - 1);
}

fabric.IText.prototype.renderCursorOrSelection = function() {
  if (!this.isEditing || !this.canvas || !this.canvas.contextTop) {
    return;
  }
  var boundaries = this._getCursorBoundaries(),
      ctx = this.canvas.contextTop;
  this.clearContextTop(true);
  if (this.selectionStart === this.selectionEnd) {
    this.renderCursor(boundaries, ctx);
  }
  else {
    this.renderSelection(boundaries, ctx);
  }
  ctx.restore();
}

fabric.IText.prototype._getCursorBoundariesOffsets = function(position) {
  if (this.cursorOffsetCache && 'top' in this.cursorOffsetCache) {
    return this.cursorOffsetCache;
  }
  var lineLeftOffset,
      lineIndex,
      charIndex,
      topOffset = 0,
      leftOffset = 0,
      boundaries,
      cursorPosition = this.get2DCursorLocation(position);
  charIndex = cursorPosition.charIndex;
  lineIndex = cursorPosition.lineIndex;
  for (var i = 0; i < lineIndex; i++) {
    topOffset += this.getHeightOfLine(i);
  }
  lineLeftOffset = this._getLineLeftOffset(lineIndex);
  var bound = this.__charBounds[lineIndex][charIndex];
  if (bound) {
    if (this.textAlign.indexOf('justify-inter-char') !== -1 || this.textAlign.indexOf('justify-inter-char-overflow') !== -1) {
      const overflowSpecialChars = this.textAlign.indexOf('justify-inter-char-overflow') !== -1
      const charSpacing = getJustifyInterCharSpacing(this, lineIndex, overflowSpecialChars);
      leftOffset = bound.left + ((charIndex - 1) * charSpacing);
    } else {
      leftOffset = bound.left;
    }
  }
  if (this.charSpacing !== 0 && charIndex === this._textLines[lineIndex].length) {
    leftOffset -= this._getWidthOfCharSpacing();
  }
  boundaries = {
    top: topOffset,
    left: lineLeftOffset + (leftOffset > 0 ? leftOffset : 0),
  };
  this.cursorOffsetCache = boundaries;
  return this.cursorOffsetCache;
}


fabric.Text.prototype._render = function(ctx) {
  const isJustifyInterChar = this.textAlign.indexOf('justify-inter-char') !== -1 || this.textAlign.indexOf('justify-inter-char-overflow') !== -1;
  if (isJustifyInterChar) {
    this.charSpacing = 0;
  }

  const path = this.path;
  path && !path.isNotVisible() && path._render(ctx);
  this._setTextStyles(ctx);
  this._renderTextLinesBackground(ctx);
  this._renderTextDecoration(ctx, 'underline');
  this._renderText(ctx);
  this._renderTextDecoration(ctx, 'overline');
  this._renderTextDecoration(ctx, 'linethrough');
}

fabric.Text.prototype._renderChars = function(method, ctx, line, left, top, lineIndex) {
  // set proper line offset
  var lineHeight = this.getHeightOfLine(lineIndex),
      isJustifyInterWord = this.textAlign.indexOf('justify') !== -1,
      isJustifyInterChar = this.textAlign.indexOf('justify-inter-char') !== -1 || this.textAlign.indexOf('justify-inter-char-overflow') !== -1,
      overflowSpecialChars = this.textAlign.indexOf('justify-inter-char-overflow') !== -1,
      actualStyle,
      nextStyle,
      charsToRender = '',
      charBox,
      boxWidth = 0,
      timeToRender,
      shortCut = !isJustifyInterWord && !isJustifyInterChar && this.charSpacing === 0 && this.isEmptyStyles(lineIndex),
      charSpacing = this.charSpacing;

  ctx.save();
  top -= lineHeight * this._fontSizeFraction / this.getLineSpacing(lineIndex);

  if (shortCut) {
    // render all the line in one pass without checking
    this._renderChar(method, ctx, lineIndex, 0, line.join(''), left, top, lineHeight);
    ctx.restore();
    return;
  }

  if (isJustifyInterChar) {
    charSpacing = getJustifyInterCharSpacing(this, lineIndex, overflowSpecialChars);
  }

  for (var i = 0, len = line.length - 1; i <= len; i++) {
    timeToRender = i === len || charSpacing;
    charsToRender += line[i];
    charBox = this.__charBounds[lineIndex][i];

    if (boxWidth === 0) {
      left += charBox.kernedWidth - charBox.width;
      boxWidth += charBox.width;
    } else {
      boxWidth += charBox.kernedWidth;
    }

    if (isJustifyInterWord && !timeToRender) {
      if (this._reSpaceAndTab.test(line[i])) {
        timeToRender = true;
      }
    }

    if (!timeToRender) {
      // if we have charSpacing, we render char by char
      actualStyle = actualStyle || this.getCompleteStyleDeclaration(lineIndex, i);
      nextStyle = this.getCompleteStyleDeclaration(lineIndex, i + 1);
      timeToRender = this._hasStyleChanged(actualStyle, nextStyle);
    }

    if (timeToRender) {
      this._renderChar(method, ctx, lineIndex, i, charsToRender, left, top, lineHeight);
      charsToRender = '';
      actualStyle = nextStyle;
      left += isJustifyInterChar ? boxWidth + charSpacing : boxWidth;
      boxWidth = 0;
    }
  }

  ctx.restore();
}

fabric[CUSTOM_TEXT_BOX_CLASS_NAME] = CustomTextBox;

export const EXTRA_FEATURES = {
  TRANSFORM_IN_GROUP: 'EXTRA_TRANSFORM_IN_GROUP'
}

export const recalculateGroupPoint = (width, height, point, isToCanvas = true) => {
  const hw = width / 2
  const hh = height / 2
  return {
    x: point.x + hw * (isToCanvas ? 1 : -1),
    y: point.y + hh * (isToCanvas ? 1 : -1)
  }
}

fabric.Canvas.prototype._transformObject = function(e) {
  var pointer = this.getPointer(e),
      transform = this._currentTransform;

  transform.reset = false;
  transform.shiftKey = e.shiftKey;
  transform.altKey = e[this.centeredKey];

  const target = this.getActiveObject();

  if (target.group && (target.extraFeatures).includes(EXTRA_FEATURES.TRANSFORM_IN_GROUP) && !['drag'].includes(transform.action)) {
    const groupPoint = recalculateGroupPoint(target.group.width, target.group.height, pointer, false)
    pointer.x = groupPoint.x
    pointer.y = groupPoint.y

    target.group.dirty = true
  }

  this._performTransformAction(e, transform, pointer);
  transform.actionPerformed && this.requestRenderAll();
}

const propProcessor = (obj, callback) => {
  return Object.keys(obj)
      .map(key => ({ key, value: callback(obj[key]) }))
      .reduce((acc, { key, value }) => {
        acc[key] = value
        return acc
      }, {})}

const coordsRecalc = (_oCoords, width, height) => propProcessor(_oCoords, (point) => ({
  ...recalculateGroupPoint(width, height, point),
  corner: point.corner ? propProcessor(point.corner, (cPoint) => recalculateGroupPoint(width, height, cPoint)) : undefined,
  touchCorner: point.touchCorner ? propProcessor(point.touchCorner, (cPoint) => recalculateGroupPoint(width, height, cPoint)) : undefined,
}))

fabric.Object.prototype._findTargetCorner = function (...args) {
  if (this.extraFeatures.includes(EXTRA_FEATURES.TRANSFORM_IN_GROUP)) {
    return this._findTargetCornerWithGroup(...args)
  }

  return this._findTargetCornerOriginal(...args)
}

fabric.Object.prototype._findTargetCornerOriginal = function(pointer, forTouch) {
  // objects in group, anykind, are not self modificable,
  // must not return an hovered corner.
  if (!this.hasControls || this.group || (!this.canvas || this.canvas._activeObject !== this)) {
    return false;
  }

  var ex = pointer.x,
      ey = pointer.y,
      xPoints,
      lines, keys = Object.keys(this.oCoords),
      j = keys.length - 1, i;
  this.__corner = 0;

  // cycle in reverse order so we pick first the one on top
  for (; j >= 0; j--) {
    i = keys[j];
    if (!this.isControlVisible(i)) {
      continue;
    }

    lines = this._getImageLines(forTouch ? this.oCoords[i].touchCorner : this.oCoords[i].corner);
    // // debugging
    //
    // this.canvas.contextTop.fillRect(lines.bottomline.d.x, lines.bottomline.d.y, 2, 2);
    // this.canvas.contextTop.fillRect(lines.bottomline.o.x, lines.bottomline.o.y, 2, 2);
    //
    // this.canvas.contextTop.fillRect(lines.leftline.d.x, lines.leftline.d.y, 2, 2);
    // this.canvas.contextTop.fillRect(lines.leftline.o.x, lines.leftline.o.y, 2, 2);
    //
    // this.canvas.contextTop.fillRect(lines.topline.d.x, lines.topline.d.y, 2, 2);
    // this.canvas.contextTop.fillRect(lines.topline.o.x, lines.topline.o.y, 2, 2);
    //
    // this.canvas.contextTop.fillRect(lines.rightline.d.x, lines.rightline.d.y, 2, 2);
    // this.canvas.contextTop.fillRect(lines.rightline.o.x, lines.rightline.o.y, 2, 2);

    xPoints = this._findCrossPoints({ x: ex, y: ey }, lines);
    if (xPoints !== 0 && xPoints % 2 === 1) {
      this.__corner = i;
      return i;
    }
  }
  return false;
}

fabric.Object.prototype._findTargetCornerWithGroup = function(pointer, forTouch) {
  // objects anykind, are not self modificable,
  // must not return an hovered corner.
  if (!this.hasControls || (!this.canvas || this.canvas._activeObject !== this)) {
    return false;
  }

  const oCoords = coordsRecalc(this.oCoords, this.group.width, this.group.height)

  var ex = pointer.x,
      ey = pointer.y,
      xPoints,
      lines, keys = Object.keys(oCoords),
      j = keys.length - 1, i;
  this.__corner = 0;

  // cycle in reverse order so we pick first the one on top
  for (; j >= 0; j--) {
    i = keys[j];
    if (!this.isControlVisible(i)) {
      continue;
    }

    lines = this._getImageLines(forTouch ? oCoords[i].touchCorner : oCoords[i].corner);

    xPoints = this._findCrossPoints({ x: ex, y: ey }, lines);
    if (xPoints !== 0 && xPoints % 2 === 1) {
      this.__corner = i;
      return i;
    }
  }
  return false;
}
fabric.Object.prototype.extraFeatures = [];
fabric.Image.prototype.ovalShadow = null;
fabric.Image.prototype.updateOvalShadowData = function () {
  if (!this.hasOwnProperty('updateLayoutChanges')) return;
  const preview = previews.find(preview => preview.id === this.canvas.instance_id );
  const key = 'oval_shadows';
  const OVAL_SHADOW_GROUP_ID_KEY = 'p_oval_shadow_group_ids';
  
  if (this.id.startsWith(IMAGE_PREFIX_ID)) {
    const index = this.id.split('_').pop();
    const shadowsByProduct = preview.data['p_'+ key] || [];
    shadowsByProduct[parseInt(index)] = this.ovalShadow;
    
    const productImages = preview.canvas.getObjects()
      .filter(el => el.id.startsWith(IMAGE_PREFIX_ID))
      .sort((a, b) => a.id.localeCompare(b.id));
    
    this.updateLayoutChanges(this.canvas.instance_id, 'p_'+ key, shadowsByProduct);
    this.updateLayoutChanges(this.canvas.instance_id, OVAL_SHADOW_GROUP_ID_KEY, productImages.map(el => el.ovalShadowGroupId));
  }
}
fabric.Image.prototype._render = (function(_render) {
  return function(ctx) {
    if (this.getSrc() && this.ovalShadow && this.ovalShadow.find(shadow => shadow.displayMode === BEHIND_DISPLAY_MODE)) {
      initOvalShadowBehind(this.ovalShadow.filter(shadow => shadow.displayMode === BEHIND_DISPLAY_MODE), this, ctx)
    }
    _render.call(this, ctx);
    if (this.getSrc() && this.ovalShadow && this.ovalShadow.find(shadow => shadow.displayMode === IN_FRONT_DISPLAY_MODE)) {
      initOvalShadowInFront(this.ovalShadow.filter(shadow => shadow.displayMode === IN_FRONT_DISPLAY_MODE), this, ctx)
    }
  };
})(fabric.Image.prototype._render);

fabric.Canvas.prototype.initialize = (function (originalFn) {
  return function (...args) {
    originalFn.call(this, ...args);

    const options = args[1];

    if (options?.resizeControl) {
      createCanvasResizeControl(this);
    }
  }
})(fabric.Canvas.prototype.initialize);

fabric.Canvas.prototype._checkTarget = function(pointer, obj, globalPointer) {
  if (
    obj &&
    obj.visible &&
    obj.evented &&
    obj.containsPoint(pointer)
  ) {
    if ((this.perPixelTargetFind || obj.perPixelTargetFind) && !obj.isEditing) {
      return this.isTargetTransparent(obj, globalPointer.x, globalPointer.y)
    }

    return obj.selectableBordersOnly ? !this.isInsideBorder(obj, pointer) : true;
  }
}

fabric.Canvas.prototype.isInsideBorder = function (target, pointer) {
  if (!target.strokeWidth) {
    return;
  }

  const pointerCoords = target.getLocalPointer(pointer);

  const strokeMargin = target.strokeWidth;
  const scaledWidth = target.getScaledWidth();
  const scaledHeight = target.getScaledHeight();

  return (
    pointerCoords.x > strokeMargin &&
    pointerCoords.x < scaledWidth - strokeMargin &&
    pointerCoords.y > strokeMargin &&
    pointerCoords.y < scaledHeight - strokeMargin
  );
}
