var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Multiselect", {
    attrs: {
      placeholder: "Font",
      "track-by": "value",
      label: "name",
      options: _vm.fonts,
      "allow-empty": false,
    },
    scopedSlots: _vm._u([
      {
        key: "option",
        fn: function (props) {
          return [
            _c("span", { style: { fontFamily: props.option.value } }, [
              _vm._v(_vm._s(props.option.name)),
            ]),
          ]
        },
      },
      {
        key: "singleLabel",
        fn: function (props) {
          return [
            _c("span", { style: { fontFamily: props.option.value } }, [
              _vm._v(_vm._s(props.option.name)),
            ]),
          ]
        },
      },
    ]),
    model: {
      value: _vm.fontVal,
      callback: function ($$v) {
        _vm.fontVal = $$v
      },
      expression: "fontVal",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }