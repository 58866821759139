<template>
  <div>
    <b-form-group label="Field Name">
      <b-form-input
        v-model="inputFields.name"
        placeholder="Enter field name"
        trim
        @input="onChangeValue('name')"
      />
    </b-form-group>

    <b-form-group
      v-show="inputFields.isShowAngle"
      label="Angle"
    >
      <b-form-input
        v-model.number="inputFields.angle"
        type="number"
        placeholder="Enter angle"
        @change="onChangeValue('angle')"
      />
    </b-form-group>

    <b-form-group label="Link">
      <b-form-input
        v-model="inputFields.link"
        placeholder="Enter link"
        trim
        @change="onChangeValue('link')"
      />
    </b-form-group>
  </div>
</template>

<script>
import { editorControlEventBroker, EVENTS as EDITOR_CONTROL_EVENTS } from '../../../../group/modules/editor-control-popup/event-broker';
import { propertiesModuleEventBroker, EVENTS } from '../../../../services/EditorControls/properties-module/event-broker';

export default {
  name: 'PropertiesModule',
  props: {
    target: {
      type: Object,
      require: true,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      inputFields: {
        fieldId: null,
        name: '',
        link: '',
        angle: 0,
        isShowAngle: true,
      }
    };
  },
  watch: {
    options (newVal, oldVal) {
      if (_.isEqual(newVal, oldVal)) {
        return
      }
      this.init(newVal)
    },
    'target.angle'(v) {
      this.inputFields.angle = v
    }
  },
  mounted() {
    if (this.options && Object.keys(this.options).length) {
      this.init(this.options);
    } else {
      if (!propertiesModuleEventBroker.isListen(EVENTS.INIT)) {
        propertiesModuleEventBroker.on(EVENTS.INIT, this.init.bind(this));
      }
    }
  },
  methods: {
    init(dto) {
      for (const key in dto) {
        this.inputFields[key] = dto[key];
      }
    },
    onChangeValue(action) {
      propertiesModuleEventBroker.fire(EVENTS.CHANGED, {
        action, 
        ...this.inputFields
      });

      editorControlEventBroker.fire(EDITOR_CONTROL_EVENTS.PROPERTIES_MODULE_CHANGED, {
        action, 
        ...this.inputFields
      })
    }
  }
}
</script>
