<template>
  <div class="actions-checkbox mt-3">
    <div class="row">
      <div class="col-12 d-flex align-content-center">
        <div class="mr-1 my-auto">
          Apply to:
        </div>
        <SelectTemplateDropdown
          v-if="target.canvas"
          :current-instance-id="target.canvas.instance_id"
          :local-storage-key="LOCAL_STORAGE_KEY_SELECTED_TEMPLATES_FOR_TEXT"
        />

        <SelectPropertiesDropdown
          :is-color-selector="!!options.colorSelector"
          :is-font-selector="!!options.fontSelector"
          :is-alignment-selector="!!options.alignmentSelector"
          @change="onChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SelectTemplateDropdown from './SelectTemplateDropdown.vue';
import SelectPropertiesDropdown from './SelectPropertiesDropdown.vue';
import { editorControlEventBroker, EVENTS } from '@frontend/group/modules/editor-control-popup/event-broker';
import {
  LOCAL_STORAGE_KEY_SELECTED_TEMPLATES_FOR_TEXT,
} from '@frontend/group/modules/editor-control-popup/selected-template-for-apply-to-all'

export default {
  name: 'ApplyToAllTextDropdown',
  
  components: { SelectTemplateDropdown, SelectPropertiesDropdown },

  props: {
    target: {
      type: Object,
      default: () => {
      },
    },
    options: {
      type: Object,
      default: () => {
      },
    },
  },
  
  data() {
    return {
      selectedPropertyKeys: [],
      LOCAL_STORAGE_KEY_SELECTED_TEMPLATES_FOR_TEXT,
    };
  },
  
  methods: {
    onChange(action, value) {
      editorControlEventBroker.fire(EVENTS.TEXT_CHANGED, {
        action,
        value,
        keys: [...this.selectedPropertyKeys],
      });
    }
  }
};
</script>

<style scoped>
</style>
