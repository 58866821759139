import {FILE_TYPE, SOURCE} from "../../../constants/file-image-types";

export const FOLDERS_ROUTE_NAME = 'file.tab.folder'

export const VIEW_TYPE = {
    GRID: 'grid',
    LIST: 'list'
}

export const TABS = {
    PRODUCTS: 'products',
    STOCK_IMAGES: 'stock-images',
    BACKGROUND: 'background',
    FILES: 'files',
    WALMART_ASSETS: 'walmart-assets'
}

export const TAB_SOURCE_MAP = {
    [TABS.FILES]: SOURCE.FOLDER,
    [TABS.PRODUCTS]: SOURCE.PRODUCT,
    [TABS.STOCK_IMAGES]: SOURCE.STOCK,
    [TABS.BACKGROUND]: SOURCE.BACKGROUND,
    [TABS.WALMART_ASSETS]: SOURCE.WALMART_ASSETS
}

export const MODALS = {
    SETTINGS: 'SETTINGS',
    DELETE_SELECTED: 'DELETE_SELECTED',
    SHARE: 'SHARE',
    UPLOAD: 'UPLOAD',
    FILE_EXISTS: 'FILE_EXISTS',
    DELETE_ITEM: 'DELETE_ITEM',
    RENAME: 'RENAME',
    COMPRESS_LOADER: 'COMPRESS_LOADER',
    CONVERT: 'CONVERT',
    RESIZE: 'RESIZE',
    CROP: 'CROP',
    REMOVE_BACKGROUND: 'REMOVE_BACKGROUND',
    PREVIEW_FILE: 'PREVIEW_FILE',
    UPLOAD_TO_TARGET: 'UPLOAD_TO_TARGET',
    TAGS_EDITOR: 'TAGS_EDITOR',
    PREVIEW_IMAGE: 'PREVIEW_IMAGE',
    PDF_FILE: 'PDF_FILE_TYPE',
    SVG_EDITOR: 'SVG_EDITOR',
    FILE_INFO: 'FILE_INFO',
    UPLOAD_PRODUCT_FILES: 'UPLOAD_PRODUCT_FILES',
    FOLDER_SETTINGS: 'FOLDER_SETTINGS',
}

export const IMAGE_FILE_TYPES = ['png', 'jpg', 'webp', 'tiff', 'image', 'jpeg', 'bmp', 'gif'];
export const VIDEO_FILE_TYPES = ['mp4', 'mov', 'avi', 'mpg', 'm4a', 'wmv', 'flv'];
export const PDF_FILE_TYPE = ['pdf'];
export const PSD_FILE_TYPE = ['psd'];
export const AUDIO_FILE_TYPES = [FILE_TYPE.MP3, FILE_TYPE.WAV];
