<template>
  <div class="c-folder-layer__item d-flex align-items-center mt-2">
    <div class="c-folder-layer__item--preview mr-2">
      <div class="a-text-layer">
        <b-icon
          icon="textarea-t"
          class="d-block"
        />
      </div>
    </div>
    <div
      v-b-tooltip.hover.top.viewport.noninteractive="{ title: node.text, disabled: isNotTruncated(node.text) }"
      class="layer--item__text"
    >
      <EditableTextElement
        v-if="isLayerEditable"
        :default-value="node.name"
        :disabled="isSavingNodesChanges"
        :render-value="truncateFieldName"
        @change="changeLayerName"
      />
      <span v-else> {{ truncateFieldName(node.name) }} </span>
    </div>
  </div>
</template>

<script>
import layerMixin from './mixin';
import {createNamespacedHelpers} from "vuex";
import EditableTextElement from "@frontend/components/common/atoms/EditableTextElement.vue";
import {ApplyStrategy} from "@frontend/services/api/psd-layers";

const { mapGetters, mapActions } = createNamespacedHelpers('psdLayers')

export default {
  name: "TextLayer",
  components: {EditableTextElement},
  mixins: [layerMixin],
  props: {
    node: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'flow',
      'isSavingNodesChanges'
    ]),
    isLayerEditable() {
      return this.flow === ApplyStrategy.FLOWS.SAVE_TO_FILE_BROWSER;
    }
  },
  methods: {
    ...mapActions([
      'setNodeChanges',
    ]),
    changeLayerName(newName) {
      const nodeId = this.node.uuid;

      this.setNodeChanges({
        nodeId: nodeId,
        changes: [
          {key: 'data.name', value: newName},
          {key: 'name', value: newName}
        ]
      });
    }
  }
}
</script>

<style scoped lang="scss">
.a-text-layer {
  font-size: 24px;
}
</style>
