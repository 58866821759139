<template>
  <div>
    <div
      id="groups_templates"
      class="form-row align-items-start"
    >
      <div class="col-md-2">
        <label>Template groups</label>
      </div>
      <div class="col-md-10 form-group">
        <div
          class="overflow-auto groups-container"
          style="max-height: 100px"
        />
        <div class="row">
          <div class="col-10">
            <label>Templates</label>
            <select
              multiple="multiple"
              class="form-control select-templates"
            />
          </div>
          <div class="col-2">
            <label>Duration</label>
            <input
              type="number"
              class="form-control duration"
              value="3"
            >
          </div>
          <div class="col-12 d-flex justify-content-end mt-3">
            <button
              type="button"
              class="btn btn-primary text-nowrap ml-2 add-group"
            >
              Add Group
            </button>
          </div>
        </div>
        <div class="d-flex align-items-center" />
      </div>

      <div class="col-md-2">
        <label>Auto-group</label>
      </div>
      <div class="col-md-5 mb-4 form-group">
        <select
          id="auto_group_selected_height"
          v-model="selectedGroupHeight"
          class="form-control mr-2"
        >
          <option value="3300">
            Letter (Portrait) @ 300 DPI (3300px)
          </option>
          <option value="2550">
            Letter (Landscape) @ 300 DPI (2550px)
          </option>
          <option value="3508">
            A4 (Portrait) @ 300 DPI (3508px)
          </option>
          <option value="2480">
            A4 (Landscape) @ 300 DPI (2480px)
          </option>
        </select>
      </div> 
      <div class="col-md-5 form-group">  
        <button
          type="button"
          class="btn btn-primary text-nowrap auto-group-to-height"
        >
          Auto-group templates by max-height
        </button>
      </div>
      <div class="col-md-2">
        <label>Footer height</label>
      </div>
      <div class="col-md-5 mb-4 form-group">
        <input
          id="auto_group_footer_height"
          v-model="footerHeight"
          type="number"
          class="form-control"
        >
      </div>
    </div>
    <div class="form-row align-items-start">
      <div class="col-4">
        <div
          class="form-check"
          style="margin-top: 6px;"
        >
          <input
            id="use_custom_naming"
            v-model="layoutOptions.use_custom_naming"
            type="checkbox"
            class="form-check-input"
            name="use_custom_naming"
            :value="layoutOptions.use_custom_naming"
            @change="onChangeNaming('use_custom_naming')"
          >
          <label
            class="form-check-label"
            for="use_custom_naming"
          >Custom naming</label>
        </div>
      </div>
      <div class="col form-group">
        <input
          v-model="layoutOptions.custom_name"
          class="form-control"
          name="custom_name"
        >
        <div class="d-flex align-items-center">
          <strong>Variables:</strong>
          <div class="custom-tooltip">
            <img
              class="custom-tooltip-icon"
              src="/img/icons/info.png"
              alt="Show variables"
            >
            <div class="custom-tooltip-container">
              <div class="custom-tooltip-content bg-dark text-light">
                <a
                  class="custom-naming-variable"
                  href="#"
                >%Brand%</a>,
                <a
                  class="custom-naming-variable"
                  href="#"
                >%TemplateName%</a>,
                <a
                  class="custom-naming-variable"
                  href="#"
                >%ProjectName%</a>,
                <a
                  class="custom-naming-variable"
                  href="#"
                >%LayoutName%</a>,
                <a
                  class="custom-naming-variable"
                  href="#"
                >%Version%</a>,
                <a
                  class="custom-naming-variable"
                  href="#"
                >%TemplateWidth%</a>,
                <a
                  class="custom-naming-variable"
                  href="#"
                >%TemplateHeight%</a>,
                <a
                  class="custom-naming-variable"
                  href="#"
                >%LayoutTitle%</a>,
                <a
                  class="custom-naming-variable"
                  href="#"
                >%SpaceToUnderscore%</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row align-items-start">
      <div class="col-4">
        <div
          class="form-check"
          style="margin-top: 6px;"
        >
          <input
            id="use_naming_table"
            v-model="layoutOptions.use_naming_table"
            type="checkbox"
            class="form-check-input"
            name="use_naming_table"
            :value="layoutOptions.use_naming_table"
            @change="onChangeNaming('use_naming_table')"
          >
          <label
            class="form-check-label"
            for="use_naming_table"
          >Use Naming Table</label>
        </div>
      </div>

      <input
        type="text"
        name="naming_table"
        hidden
        :value="layoutOptions.naming_table"
      >

      <input
        type="text"
        name="naming_table_tabs"
        hidden
        :value="getNamingTableTabs"
      >

      <NamingTableInput
        v-model="layoutOptions.naming_table"
        class="col form-group"
        :selected-names-from-tabs="selectedNamesFromTabs"
        :naming-design-tabs="namingDesignTabs"
        :has-choose-variables="true"
        @onSelectedNamesFromTabs="onSelectedNamesFromTabs"
      />
    </div>
    <div class="form-row">
      <div class="d-flex align-items-center">
        <div class="form-check mr-2">
          <input
            id="compress_max_file_size"
            v-model="layoutOptions.compress_max_file_size"
            type="checkbox"
            class="form-check-input"
            name="compress_max_file_size"
          >
          <label
            class="form-check-label"
            for="compress_max_file_size"
          >Compress to Max File Size</label>
        </div>
      </div>

      <div
        v-if="layoutOptions.compress_max_file_size"
        class="d-flex align-items-center"
      >
        <input
          id="compress_max_file_size_value"
          v-model="layoutOptions.compress_max_file_size_value"
          type="number"
          class="form-control"
          name="compress_max_file_size_value"
        >
        <label class="ml-2">Kb</label>
      </div>
    </div>
    <div class="form-row mt-2">
      <div class="d-flex align-items-center">
        <div class="form-check mr-2">
          <input
            id="images_full_resolution"
            v-model="layoutOptions.images_full_resolution"
            type="checkbox"
            class="form-check-input"
            name="images_full_resolution"
          >
          <label
            class="form-check-label"
            for="images_full_resolution"
          >Use images in full resolution</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NamingTableInput from './NamingTableInput.vue';
import { deepCopy } from '../../../../utils';
import { parseJsonData } from '../../../../services/helpers';

export default {
  components: {
    NamingTableInput
  },
  props: {
    propLayoutOptions: {
      type: Object,
      default: () => {},
    },
    namingDesignTabs: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      layoutOptions: {
        use_naming_table: false,
        naming_table: '',
        naming_table_tabs: '[]'
      },
      selectedNamesFromTabs: [],
      selectedGroupHeight: "3300", // Default selection
      footerHeight: 0
      };
  },
  computed: {
    getNamingTableTabs() {
      return JSON.stringify(this.selectedNamesFromTabs);
    }
  },
  mounted() {
    this.layoutOptions = {
      ...this.layoutOptions,
      ...deepCopy(this.propLayoutOptions)
    };
    this.selectedNamesFromTabs = parseJsonData(this.layoutOptions.naming_table_tabs, []);
  },
  methods: {
    onSelectedNamesFromTabs(value) {
      this.selectedNamesFromTabs = value;
      this.layoutOptions.naming_table = this.selectedNamesFromTabs.map(name => name.value).join('_')
    },
    onChangeNaming(key) {
      const namingKeys = ['use_naming_table', 'use_custom_naming'];

      for (const namingKey of namingKeys) {
        if (namingKey === key) continue;

        this.layoutOptions[namingKey] = false;
      }
    }
  }
}
</script>
