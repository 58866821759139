<template>
  <div 
    ref="folderTreeSelect"
    class="folder-tree-select" 
  >
    <b-form-input
      v-model="inputVal"
      :placeholder="placeholder"
      @click="handleOpenDropdown"
      @input="handleSearchFolder"
    />

    <b-icon
      icon="triangle-fill"
      class="folder-tree-select__triangle-icon"
      :class="{'folder-tree-select__triangle-icon--opened': isVisible}"
    />
    
    <b-icon
      v-if="selectedFolder"
      icon="x"
      class="folder-tree-select__remove-icon"
      @click="handleRemove"
    />
    
    <div 
      class="folder-tree-select__dropdown"
      :class="{'folder-tree-select__dropdown--opened': isVisible}" 
    >
      <template v-if="!folders">
        <span
          class="spinner-border spinner-border-sm ml-2"
          role="status"
          aria-hidden="true"
        />
      </template>
      <template v-else-if="folders.length">
        <FolderTreeSelectItem
          v-for="folder in folders"
          :key="folder.id"
          :item="folder"
          :selected="selectedFolder"
          @change="handleFolderChange"
        />
      </template>
      <template v-else>
        <div class="px-2">
          No folders found.
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import FolderTreeSelectItem from "@frontend/components/common/atoms/FolderTreeSelect/FolderTreeSelectItem.vue";

const INPUT_SEARCH_PLACEHOLDER = 'Select option'

export default {
  name: 'FolderTreeSelect',
  components: { FolderTreeSelectItem },
  props: {
    items: {
      type: Array,
      required: false,
      default: null
    },
    value: {
      type: Object,
      default: null
    }
  },
  
  data() {
    return {
      inputVal: null,
      selectedFolder: this.value,
      isVisible: false,
      placeholder: INPUT_SEARCH_PLACEHOLDER,
      folders: this.items
    }
  },
  
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  
  methods: {
    handleFolderChange(folder, ignoreEmit = false) {
      this.selectedFolder = folder;
      this.inputVal = folder?.name || null;
      this.isVisible = false;

      if (!ignoreEmit) {
        this.$emit('input', folder);
      }
    },
    handleClickOutside(event) {
      if (this.$refs.folderTreeSelect && !this.$refs.folderTreeSelect.contains(event.target)) {
        this.isVisible = false;
        this.inputVal = this.selectedFolder?.name;
      }
    },
    handleOpenDropdown() {
      if (this.isVisible) return;
      
      this.isVisible = true;
      this.inputVal = '';
      this.folders = this.items;
    },
    handleSearchFolder(value) {
      this.folders = this.items.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
    },
    handleRemove() {
      this.inputVal = '';
      this.selectedFolder = null;
      this.handleFolderChange(null);
    }
  }
}
</script>

<style lang="scss">
  .folder-tree-select {
    position: relative;
    
    input {
      cursor: pointer;
    }
    
    &__dropdown {
      display: none;
      position: absolute;
      width: 100%;
      background: white;
      padding: 5px 0;
      border: 1px solid #ced4daed;
      border-radius: 3px;
      box-sizing: border-box;
      max-height: 300px;
      overflow: auto;
      z-index: 100;
      
      &--opened {
        display: block;
      }
    }

    &__triangle-icon, &__remove-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: #6c6e71ed;
    }

    &__remove-icon {
      font-size: 15px;
      right: 25px;
      cursor: pointer;
      
      &:hover {
        color: #209c86;
      }
    }
    
    &__triangle-icon {
      position: absolute;
      right: 12px;
      font-size: 7px;
      transform: translateY(-50%) rotate(180deg);
      
      &--opened {
        transform: translateY(-50%) rotate(0);
      }
    }
  }
</style>
